import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import MuiPhoneNumber from "material-ui-phone-number";

import i18n from "../../../i18n.jsx";

import CountrySearch from "./CountrySearch.js";
import LoyaltyCard from "./LoyaltyCard.js";

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontWeight900:{
        fontWeight: 900
    },
    fontSize12:{
        fontSize: 12
    },
    fontSize14:{
        fontSize: 14
    },
    fontSize16:{
        fontSize: 16
    },
    upperCase:{
        textTransform: "uppercase",
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textRight:{
        textAlign: "right"
    },
    spacer:{
        padding: "8px 0px"
    },
    formControlRoot1: {
        width: "100%",
        "& .MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .MuiSvgIcon-root" : {
            color: "black"
        },
        "& .MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15,
            zIndex: -1
        }
    },
    formControlRootSmall: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(20px, 10px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)',
            zIndex: 1
        }
    },
    formControlRootRed: {
        width: "100%",
        "& .MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .MuiSvgIcon-root" : {
            color: "black"
        },
        "& .MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15,
            zIndex: -1
        }
    },
    formControlRootSmallRed: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12
            //zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(20px, 10px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)',
            zIndex: 1
        }
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    outlinedInput:{
        padding: "7px 30px 7px 20px;"
    },
    circleBorder:{
        borderRadius: 8
    },
    smallPaddingTop:{
        paddingTop: 8
    },
    errorInfo:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "red",
        width: "100%",
        textAlign: "right",
        fontSize: 10,
        paddingRight: 31
    },
    errorInfoSmall:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "red",
        width: "100%",
        textAlign: "center",
        fontSize: 9
    }
}))


const TravelerMoreInfo = (({ traveler, traveler_index, moreInfo, setMoreInfo, open_more_info, infoIncomplete, tempTravelers, setTempTravelers, tempContactLead, setTempContactLead, size, isFrom }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const user = useSelector(store => store.user.user);
    const data_trip = useSelector((store) => store.trip.data_trip);

    const emailLabel = useRef(null);
    const emailLabelWidth = emailLabel.current ? emailLabel.current.clientWidth : 42
    const identityDocLabel = useRef(null);
    const identityDocLabelWidth = identityDocLabel.current ? identityDocLabel.current.clientWidth : 191
    const identityDocNumLabel = useRef(null);
    const identityDocNumLabelWidth = identityDocNumLabel.current ? identityDocNumLabel.current.clientWidth : 214

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const isDisabled = ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'travelmuz'].includes(quotation_code) ? (data_trip !== null && data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV" && isFrom === "tripList") : false;
    const handleIdentityDoc = (e, traveler) => {
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === traveler.id){
                let newTravelers = tempTravelers.slice();
                traveler.identity_doc = e.target.value;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
        if (traveler.id === tempContactLead.id){
            tempContactLead.identity_doc = e.target.value;
        }
    };

    const handleIdentityDocNum = (e, traveler) => {
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === traveler.id){
                let newTravelers = tempTravelers.slice();
                traveler.identity_doc_number = e.target.value;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
        if (traveler.id === tempContactLead.id){
            tempContactLead.identity_doc_number = e.target.value;
        }
    };

    const handleIdentityDocExpiration = (value, traveler) => {
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === traveler.id){
                let newTravelers = tempTravelers.slice();
                traveler.identity_doc_expiration = value !== null ? value.format("YYYY-MM-DD") : null;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
        if (traveler.id === tempContactLead.id){
            tempContactLead.identity_doc_expiration = value !== null ? value.format("YYYY-MM-DD") : null
        }
    };

    const handlePhoneNumber = ((value, country_data, traveler) => {
        let temp_traveler = Object.assign({}, traveler);
        temp_traveler.temp_phone = value;
        // country_data has the country code
        temp_traveler.phone_country_code = "+" + country_data.dialCode;
        temp_traveler.phone_number = value.replace(`+${country_data.dialCode}`, "")
        if(traveler.id === tempContactLead.id){
            setTempContactLead(temp_traveler);
        }
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === traveler.id){
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let email = tempTravelers[traveler_index].email;
                let newTravelers = tempTravelers.slice();
                temp_traveler.email = email;
                temp_traveler.age_label = age_label;
                temp_traveler.room_label = room_label;
                newTravelers[traveler_index] = temp_traveler;
                setTempTravelers(newTravelers);
            }
        }
    })

    const handleEmail = (e, traveler) => {
        let temp_traveler = Object.assign({}, traveler);
        temp_traveler.email = e.target.value;
        if(traveler.id === tempContactLead.id){
            setTempContactLead(temp_traveler);
        }
        for (traveler_index=0 ; traveler_index<tempTravelers.length ; traveler_index++){
            if(tempTravelers[traveler_index].id === traveler.id){
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let newTravelers = tempTravelers.slice();
                temp_traveler.age_label = age_label;
                temp_traveler.room_label = room_label;
                newTravelers[traveler_index] = temp_traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const createLoyaltyCard = (traveler) => {
        let temp_card_object = { "product_type": 6, "supplier": "", "card_number": "" };
        let temp_loyalty_cards = traveler.loyalty_cards.slice();
        temp_loyalty_cards.push(temp_card_object);

        let newTravelers = tempTravelers.slice();
        traveler.loyalty_cards = temp_loyalty_cards;
        tempContactLead.loyalty_cards = temp_loyalty_cards;
        setTempTravelers(newTravelers);
    }

    if(size === "small"){
        return(
            <Grid>
                <Grid className={classes.textRight}>
                    {
                        !open_more_info && (
                            <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={ () => {
                                let temp_more_info = moreInfo.slice();
                                temp_more_info[traveler_index] = !temp_more_info[traveler_index];
                                setMoreInfo(temp_more_info);
                            } }>
                                { t("global.short_more_info") }
                            </Button>
                        )
                    }
                </Grid>
                {
                    open_more_info && (
                        <Grid>
                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={2}>
                                <Grid item xs={4}>
                                    <MuiPhoneNumber
                                        defaultCountry={traveler.nationality !== null && traveler.nationality !== "" ? traveler.nationality.country_code.toLowerCase() : (user.lang !== null && user.lang !== "en") ? user.lang.split('-')[0] : "gb" }
                                        value={traveler.temp_phone}
                                        className={classes.formControlRootSmall}
                                        onChange={(value, country_data) => {handlePhoneNumber(value, country_data, traveler)}}
                                        variant="outlined"
                                        label={t("global.phone")}
                                        InputProps={{ className: classes.circleBorder }}
                                        disabled={isDisabled}
                                        disableAreaCodes/>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="outlined" className={classes.formControlRootSmall}>
                                        <InputLabel id={`email-label-${traveler.id}`} ref={emailLabel} className={classes.fontSize16}>
                                            {t("global.email")}
                                        </InputLabel>
                                        <OutlinedInput
                                            name="email"
                                            id="email-input"
                                            disabled={isDisabled}
                                            className={classes.circleBorder}
                                            labelWidth={emailLabelWidth}
                                            value={traveler.email}
                                            onChange={((event) => { handleEmail(event, traveler) })}
                                            autoComplete="off"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={2}>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControlRootSmall}>
                                        <InputLabel id={`identity-document-${traveler.id}`} ref={identityDocLabel} className={classes.fontSize16}>
                                            {t("menu.trip_list.identity_doc_short")}
                                        </InputLabel>
                                        <Select
                                            disabled={isDisabled}
                                            labelId={`identity-document-${traveler.id}`}
                                            id={`identity-document-choose-${traveler.id}`}
                                            value={traveler.identity_doc}
                                            className={classes.circleBorder}
                                            onChange={((event) => { handleIdentityDoc(event, traveler) })}
                                            MenuProps={{ disableScrollLock: true }}
                                            input={
                                                <OutlinedInput
                                                    name="identity-document"
                                                    id={`identity-document-${traveler.id}`}
                                                    labelWidth={identityDocLabelWidth}
                                                    autoComplete="off"
                                                />}
                                        >
                                            <MenuItem value="CARD">
                                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                                    {t("menu.trip_list.id_card")}
                                                </Grid>
                                            </MenuItem>
                                            <MenuItem value="PASSPORT">
                                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                                    {t("menu.trip_list.passport")}
                                                </Grid>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControl variant="outlined" className={infoIncomplete[traveler_index] === false ? classes.formControlRootSmall : classes.formControlRootSmallRed}>
                                        <InputLabel id={`identity-numero-label-${traveler.id}`} ref={identityDocNumLabel} className={classes.fontSize16}>
                                            {t("menu.trip_list.id_doc_number")}
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled={isDisabled}
                                            name={`identity-numero-label-${traveler.id}`}
                                            id={`identity-numero-input-${traveler.id}`}
                                            labelWidth={identityDocNumLabelWidth}
                                            className={classes.circleBorder}
                                            value={traveler.identity_doc_number}
                                            onChange={((event) => { handleIdentityDocNum(event, traveler) })}
                                            autoComplete="new-password"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <CountrySearch label={t("menu.profile.passeport_country_from")} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={false} mode={"identity-doc"} redFormControl={infoIncomplete[traveler_index]} size={size} />
                                </Grid>
                            </Grid>
                            {
                                infoIncomplete[traveler_index] && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item xs={3} />
                                        <Grid item xs={5} />
                                        {
                                            traveler.identity_doc_country_emission === "" && (
                                                <Grid item xs={4} className={classes.errorInfoSmall}>
                                                    { t("booking.no_empty_field") }
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                            <Grid container alignItems="center" justify="space-between" spacing={2} className={classes.smallPaddingBot}>
                                <Grid item xs={6}>
                                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ i18n.language }>
                                        <Grid>
                                            <DatePicker
                                                disabled={isDisabled}
                                                label={t("menu.profile.passeport_end_date")}
                                                value={traveler.identity_doc_expiration}
                                                onChange={(newValue) => handleIdentityDocExpiration(newValue, traveler)}
                                                views={["year", "month", "date"]}
                                                openTo="year"
                                                renderInput={(props) =>
                                                    <TextField
                                                        {...props}
                                                        variant={"outlined"}
                                                        className={infoIncomplete[traveler_index] === false ? classes.formControlRootSmall : classes.formControlRootSmallRed}
                                                        InputLabelProps={{ className: classes.fontSize16 }}
                                                        InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                                                        helperText={""}
                                                        error={false}
                                                        fullWidth={true}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <CountrySearch label={t("menu.trip_list.nationality")} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={false} mode={"demonym"} redFormControl={infoIncomplete[traveler_index]} size={size} />
                                </Grid>
                            </Grid>
                            {
                                infoIncomplete[traveler_index] && (
                                    <Grid container alignItems="center" justify="space-between">
                                        {
                                            traveler.identity_doc_expiration === null && (
                                                <Grid item xs={6} className={classes.errorInfoSmall}>
                                                    { t("booking.no_empty_field") }
                                                </Grid>
                                            )
                                        }
                                        {
                                            traveler.nationality === "" && (
                                                <Grid item xs={6} className={classes.errorInfoSmall}>
                                                    { t("booking.no_empty_field") }
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                            {
                                traveler.loyalty_cards.map((card, card_index) =>{
                                    return(
                                        <LoyaltyCard key={`loyalty-${card_index}`} card={card} card_index={card_index} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} size={size} isFrom={isFrom}/>
                                    )
                                })
                            }
                            <Grid container alignItems="center" justify="space-between">
                                <Grid item>
                                    <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={ () => createLoyaltyCard(traveler)}>
                                        {t("menu.trip_list.add_card")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {
                                        open_more_info && (
                                            <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={ () => {
                                                let temp_more_info = moreInfo.slice();
                                                temp_more_info[traveler_index] = !temp_more_info[traveler_index];
                                                setMoreInfo(temp_more_info);
                                            } }>
                                                { t("global.short_less_info") }
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        )
    }else{
        return(
            <Grid>
                <Grid className={classes.textRight} style={{ paddingBottom: 8 }}>
                    {
                        !open_more_info && (
                            <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={ () => {
                                let temp_more_info = moreInfo.slice();
                                temp_more_info[traveler_index] = !temp_more_info[traveler_index];
                                setMoreInfo(temp_more_info);
                            } }>
                                { t("global.short_more_info") }
                            </Button>
                        )
                    }
                </Grid>
                {
                    open_more_info && (
                        <Grid>
                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4}>
                                <Grid item xs={3}>
                                    <MuiPhoneNumber
                                        defaultCountry={traveler.nationality !== null && traveler.nationality !== "" ? traveler.nationality.country_code.toLowerCase() : (user !== null && user.lang !== undefined && user.lang !== null && user.lang !== "en") ? user.lang.split('-')[0] : "gb" }
                                        value={traveler.temp_phone}
                                        disabled={isDisabled}
                                        className={classes.formControlRoot1}
                                        onChange={(value, country_data) => {handlePhoneNumber(value, country_data, traveler)}}
                                        variant="outlined"
                                        label={t("global.phone")}
                                        InputProps={{ className: classes.circleBorder }}
                                        disableAreaCodes/>
                                </Grid>
                                <Grid item xs={9}>
                                    <FormControl variant="outlined" className={classes.formControlRoot1} >
                                        <InputLabel id={`email-label-${traveler.id}`} ref={emailLabel} className={classes.fontSize16}>
                                            {t("global.email")}
                                        </InputLabel>
                                        <OutlinedInput
                                            name="email"
                                            id="email-input"
                                            disabled={isDisabled}
                                            className={classes.circleBorder}
                                            labelWidth={emailLabelWidth}
                                            value={traveler.email}
                                            onChange={((event) => { handleEmail(event, traveler) })}
                                            autoComplete="off"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" justify="space-between" className={classes.spacer} spacing={4}>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={classes.formControlRoot1} style={{ width: "100%" }}>
                                        <InputLabel id={`identity-document-${traveler.id}`} ref={identityDocLabel} className={classes.fontSize16}>
                                            {t("menu.trip_list.identity_doc")}
                                        </InputLabel>
                                        <Select
                                            disabled={isDisabled}
                                            labelId={`identity-document-${traveler.id}`}
                                            id={`identity-document-choose-${traveler.id}`}
                                            value={traveler.identity_doc}
                                            className={`${classes.formControlRoot1} ${classes.circleBorder}`}
                                            onChange={((event) => { handleIdentityDoc(event, traveler) })}
                                            MenuProps={{ disableScrollLock: true }}
                                            input={
                                                <OutlinedInput
                                                    name="identity-document"
                                                    id={`identity-document-${traveler.id}`}
                                                    labelWidth={identityDocLabelWidth}
                                                    autoComplete="off"
                                                />}
                                        >
                                            <MenuItem value="CARD">
                                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                                    {t("menu.trip_list.id_card")}
                                                </Grid>
                                            </MenuItem>
                                            <MenuItem value="PASSPORT">
                                                <Grid className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14} ${classes.noWrap}`}>
                                                    {t("menu.trip_list.passport")}
                                                </Grid>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" className={infoIncomplete[traveler_index] === false ? classes.formControlRoot1 : classes.formControlRootRed}>
                                        <InputLabel id={`identity-numero-label-${traveler.id}`} ref={identityDocNumLabel} className={classes.fontSize16}>
                                            {t("menu.trip_list.id_doc_number")}
                                        </InputLabel>
                                        <OutlinedInput
                                            disabled={isDisabled}
                                            name={`identity-numero-label-${traveler.id}`}
                                            id={`identity-numero-input-${traveler.id}`}
                                            labelWidth={identityDocNumLabelWidth}
                                            className={classes.circleBorder}
                                            value={traveler.identity_doc_number}
                                            onChange={((event) => { handleIdentityDocNum(event, traveler) })}
                                            autoComplete="new-password"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <CountrySearch label={t("menu.profile.passeport_country_from")} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} tempContactLead={tempContactLead} setTempContactLead={setTempContactLead} contact_lead_option={false} mode={"identity-doc"} redFormControl={infoIncomplete[traveler_index]} size={size} isFrom={"tripList"}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <LocalizationProvider dateLibInstance={ moment } dateAdapter={ MomentAdapter } locale={ i18n.language }>
                                        <Grid>
                                            <DatePicker
                                                disabled={isDisabled}
                                                label={t("menu.profile.passeport_end_date")}
                                                value={traveler.identity_doc_expiration}
                                                onChange={(newValue) => handleIdentityDocExpiration(newValue, traveler)}
                                                views={["year", "month", "date"]}
                                                openTo="year"
                                                renderInput={(props) =>
                                                    <TextField
                                                        {...props}
                                                        variant={"outlined"}
                                                        className={infoIncomplete[traveler_index] === false ? classes.formControlRoot1 : classes.formControlRootRed}
                                                        InputLabelProps={{ className: classes.fontSize16 }}
                                                        InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                                                        helperText={""}
                                                        error={false}
                                                        fullWidth={true}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            {
                                infoIncomplete[traveler_index] && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item xs={3} />
                                        <Grid item xs={3} />
                                        {
                                            traveler.identity_doc_country_emission === "" && (
                                                <Grid item xs={3} className={classes.errorInfo}>
                                                    { t("booking.no_empty_field") }
                                                </Grid>
                                            )
                                        }
                                        {
                                            traveler.identity_doc_expiration === null && (
                                                <Grid item xs={3} className={classes.errorInfo}>
                                                    { t("booking.no_empty_field") }
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                )
                            }
                            {
                                traveler.loyalty_cards.map((card, card_index) =>{
                                    return(
                                        <LoyaltyCard key={`loyalty-${card_index}`} card={card} card_index={card_index} traveler={traveler} tempTravelers={tempTravelers} setTempTravelers={setTempTravelers} size={size} isFrom={isFrom}/>
                                    )
                                })
                            }
                            <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                <Grid item>
                                    <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={ () => createLoyaltyCard(traveler)}>
                                        {t("menu.trip_list.add_card")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {
                                        open_more_info && (
                                            <Button className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.letterSpacing}`} onClick={ () => {
                                                let temp_more_info = moreInfo.slice();
                                                temp_more_info[traveler_index] = !temp_more_info[traveler_index];
                                                setMoreInfo(temp_more_info);
                                            } }>
                                                { t("global.short_less_info") }
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        )
    }
})

export default React.memo(TravelerMoreInfo);
