import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
    styled
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import {
    findLast,
    flatten,
    isNumber,
    keyBy,
    mapValues,
    uniqBy
} from "lodash";
import {
    CartPackageDraggableItem, CartPackageDraggableItemProps
} from "./CartPackageDraggableItem";
import {
    CartPackagePackagesList,
    PackageInput
} from "./CartPackagePackagesList";
import { isProductSamePackage } from "./utils/isProductSamePackage";
import { isProductPackaged } from "./utils/isProductPackaged";
import { useCartSummarizedProducts } from "./utils/cartSummarizedProducts";
import { useCartPackagesUpdate } from "./network/cartPackagesUpdate";
import { useShowError } from "../Utils/showError";
import GetCookie from "../Common/Functions/GetCookie";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    onClose: () => void
}

export function CartPackageModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const allProviders = useMemo(() => {
        return providers.concat(manual_providers);
    }, [providers, manual_providers]);
    const [packages, setPackages] = useState<PackageInput>({});
    const [selectedItems, setSelectedItems] = useState<CartPackageDraggableItemProps[]>([]);
    const [loading, setLoading] = useState(false);
    const packagedProducts = useMemo(() => {
        return flatten(
            Object.values(packages).map((item) => {
                return item.products;
            })
        );
    }, [packages]);
    const products = useCartSummarizedProducts();
    const unPackagedProducts = useMemo(() => {
        return products.filter((item) => {
            return packagedProducts.findIndex((product) => {
                return `${product.type}-${product.id}` === `${item.type}-${item.id}`;
            }) < 0 &&
            !isProductPackaged({
                product: {
                    stack_number: item.stackNumber,
                    stack_info_id: item.stackInfoId,
                    is_stack_price: item.isStackPrice
                },
                stackInfos: trip?.stack_info ?? null,
                connected: true
            });
        });
    }, [trip, products, packagedProducts]);
    const showError = useShowError();
    const updatePackages = useCartPackagesUpdate({
        onTrigger() {
            setLoading(true);
        },
        onSuccess() {
            enqueueSnackbar(
                t<string>('cart-material.cart-construction-package-save-success'),
                { variant: 'success' }
            );
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onClose = () => {
        if (!loading) {
            props.onClose();
        }
    };

    const onAdd = () => {
        setPackages((state) => {
            const stackNumbers = Object.keys(state).map((key) => {
                const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                return stackNumber;
            }).filter((item): item is number => {
                return isNumber(item);
            }).sort((a, b) => a - b);
            const newKey = (stackNumbers[stackNumbers.length - 1] ?? 0) + 1 + '-';
            return {
                ...state,
                [newKey]: {
                    provider: null,
                    openInfo: true,
                    openProducts: true,
                    flightPrice: {
                        price: 0,
                        currency: 47,
                        margin: 0,
                        marginType: 'PER'
                    },
                    terrestrialPrice: {
                        price: 0,
                        currency: 47,
                        margin: 0,
                        marginType: 'PER'
                    },
                    products: []
                }
            };
        });
    };

    const onUpdate = () => {
        updatePackages(
            parseInt(GetCookie('trip_id_version') ?? '-1'),
            packages,
            unPackagedProducts.filter((item) => {
                const corresponding = products.find((product) => {
                    return product.id === item.id;
                });
                return corresponding &&
                       isProductPackaged({
                           product: {
                               stack_number: corresponding.stackNumber,
                               stack_info_id: corresponding.stackInfoId
                           },
                           stackInfos: trip?.stack_info ?? null
                       });
            })
        );
    };

    const onChangeSelection = (item: CartPackageDraggableItemProps, checked: boolean) => {
        if (checked) {
            setSelectedItems((state) => {
                return state.concat([item]);
            });
        } else {
            setSelectedItems((state) => {
                return state.filter((current) => {
                    return `${current.productType}-${current.id}` !== `${item.productType}-${item.id}`;
                });
            });
        }
    };

    const onChangeAllSelection = (checked: boolean) => {
        if (checked) {
            setSelectedItems(unPackagedProducts);
        } else {
            setSelectedItems([]);
        }
    };

    useEffect(() => {
        if (trip?.prices_flight && trip.prices_terrestrial && trip.prices_stack_product) {
            const packagesFromProducts = uniqBy(
                products.map((item) => ({
                    stackNumber: item.stackNumber,
                    stackInfoId: item.stackInfoId,
                    isStackPrice: item.isStackPrice
                })),
                (item) => `${item.stackNumber}-${item.stackInfoId}-${item.isStackPrice}`
            ).filter((item) => {
                return isProductPackaged({
                    product: {
                        stack_number: item.stackNumber,
                        stack_info_id: item.stackInfoId,
                        is_stack_price: item.isStackPrice
                    },
                    stackInfos: trip?.stack_info ?? null
                }) && !isProductPackaged({
                    product: {
                        stack_number: item.stackNumber,
                        stack_info_id: item.stackInfoId,
                        is_stack_price: item.isStackPrice
                    },
                    stackInfos: trip?.stack_info ?? null,
                    connected: true
                });
            });

            const result = mapValues(
                keyBy(
                    packagesFromProducts,
                    (item) => `${item.stackNumber ?? ''}-${item.stackInfoId ?? ''}`
                ),
                (_, key): PackageInput[number] => {
                    const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                    const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
                    const terrestrialPrice = findLast(
                        trip.prices_terrestrial!,
                        (item) => {
                            return isProductSamePackage(
                                item,
                                {
                                    stack_number: stackNumber,
                                    stack_info_id: stackInfoId
                                }
                            ) &&
                            item.master_price;
                        }
                    ) ?? findLast(
                        trip.prices_stack_product,
                        (item) => {
                            return item.master_price && stackNumber === 1;
                        }
                    );
                    const flightPrice = findLast(
                        trip.prices_flight!,
                        (item) => {
                            return isProductSamePackage(
                                item,
                                {
                                    stack_number: stackNumber,
                                    stack_info_id: stackInfoId
                                }
                            ) &&
                            item.master_price;
                        }
                    );
                    const provider = allProviders.find((item) => {
                        return item.provider.id === terrestrialPrice?.provider;
                    });

                    return {
                        openInfo: false,
                        openProducts: false,
                        provider: provider ?? null,
                        flightPrice: {
                            currency: flightPrice?.purchase_currency ?? 47,
                            price: parseFloat(flightPrice?.purchase_price ?? '0'),
                            marginType: flightPrice?.custom_rate_type ?? 'PER',
                            margin: parseFloat(flightPrice?.custom_value ?? '0')
                        },
                        terrestrialPrice: {
                            currency: terrestrialPrice?.purchase_currency ?? 47,
                            price: parseFloat(terrestrialPrice?.purchase_price ?? '0'),
                            marginType: terrestrialPrice?.custom_rate_type ?? 'PER',
                            margin: parseFloat(terrestrialPrice?.custom_value ?? '0')
                        },
                        products: products.filter((item) => {
                            return isProductSamePackage(
                                {
                                    stack_number: item.stackNumber,
                                    stack_info_id: item.stackInfoId
                                },
                                {
                                    stack_number: stackNumber,
                                    stack_info_id: stackInfoId
                                }
                            );
                        })
                    };
                }
            );
            setPackages(result);
        }
    }, [trip, allProviders, products]);

    useEffect(() => {
        setSelectedItems((state) => {
            const unpackagedProductIdentifiers = unPackagedProducts.map((item) => {
                return `${item.productType}-${item.id}`;
            });
            return state.filter((item) => {
                return unpackagedProductIdentifiers.includes(`${item.productType}-${item.id}`);
            });
        });
    }, [unPackagedProducts]);

    return (
        <Dialog
            open
            onClose={onClose}
            maxWidth="md"
            PaperProps={{
                sx: (theme) => ({
                    height: `calc(100% - ${theme.spacing(8)})`
                })
            }}
            fullWidth
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h6" fontWeight="bold">
                        {t<string>('cart-material.cart-construction-package-modal-title')}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0, overflow: 'hidden' }}>
                <Grid
                    container
                    spacing={3}
                    sx={(theme) => ({
                        height: `calc(100% + ${theme.spacing(3)})`,
                        maxHeight: `calc(100% + ${theme.spacing(3)})`
                    })}
                >
                    <Grid
                        item
                        xs={6}
                        sx={{
                            maxHeight: '100%',
                            overflow: 'auto',
                            direction: 'rtl'
                        }}
                    >
                        <Box sx={{ direction: 'ltr' }}>
                            <Typography
                                variant="h6"
                                textAlign="center"
                                fontWeight={700}
                                component="div"
                                sx={{ marginBottom: 2 }}
                            >
                                {t<string>('cart-material.cart-construction-package-modal-products')}
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={1} sx={{ marginBottom: 1.5 }}>
                                <Checkbox
                                    checked={
                                        selectedItems.length === unPackagedProducts.length
                                    }
                                    indeterminate={
                                        selectedItems.length > 0 &&
                                        selectedItems.length < unPackagedProducts.length
                                    }
                                    onChange={(_, checked) => onChangeAllSelection(checked)}
                                />
                                <Typography>
                                    {
                                        t<string>(
                                            'cart-material.cart-construction-package-selected-items-count',
                                            { count: selectedItems.length }
                                        )
                                    }
                                </Typography>
                            </Stack>
                            <List>
                                {
                                    [...unPackagedProducts].sort((a, b) => {
                                        return window.moment.utc(a.startDate).isBefore(
                                            window.moment.utc(b.startDate)
                                        ) ? -1 : 1;
                                    }).map((item) => {
                                        return (
                                            <CartPackageDraggableItem
                                                key={`${item.productType}-${item.id}`}
                                                item={item}
                                                selectedItems={selectedItems}
                                                onChangeSelection={onChangeSelection}
                                            />
                                        );
                                    })
                                }
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ maxHeight: '100%', overflow: 'auto' }}>
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontWeight={700}
                            component="div"
                            sx={{ marginBottom: 2 }}
                        >
                            {t<string>('cart-material.cart-construction-package-modal-packages')}
                        </Typography>
                        <CartPackagePackagesList
                            packages={packages}
                            onAdd={onAdd}
                            onChangePackages={setPackages}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    disabled={loading}
                >
                    {t<string>('shared.cancel')}
                </Button>
                <LoadingButton
                    onClick={onUpdate}
                    loading={loading}
                    disabled={
                        Object.values(packages).findIndex((item) => {
                            return item.products.length === 0;
                        }) >= 0
                    }
                >
                    {t<string>('shared.save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

const List = styled('ul')(() => ({
    listStyle: 'none',
    paddingLeft: 0
}));
