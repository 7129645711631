
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import TravelExchangeOptions from "../ItineraryType/TravelExchangeOptions";
import { AppState } from "../../Reducers/Reducers";

type ValidateOptions = {
    selectedTravelExchangePrices: any,
    selectedTravelExchangeOptions: any,
    mealPlan: any
}

type Props = {
    loading?: boolean,
    onValidate: (options: ValidateOptions) => Promise<any>,
    onClose: () => void
}

export function ItineraryBlocksTravelExchangeModal(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const version = useSelector((state: AppState) => {
        if (state.itinerarySlice.travelExchangeModalAddOptions?.type === 'circuit') {
            return state.itinerarySlice.travelExchangeModalAddOptions?.circuitVersion;
        }
        return null;
    });
    const startDate = useSelector((state: AppState) => state.itinerarySlice.travelExchangeModalAddOptions?.startDate);
    const traveler_groups = useSelector((state: AppState) => state.trip.traveler_groups);
    const [mealPlanChoice, setMealPlanChoise] = useState([]);
    const [mealPlan, setMealPlan] = useState('');
    const [travelExchangePrices, setTravelExchangePrices] = useState<any[] | null>(null);
    const [selectedTravelExchangePrices, setSelectedTravelExchangePrices] = useState<any | null>(null);
    const [selectedTravelExchangeOptions, setSelectedTravelExchangeOptions] = useState([]);
    const [currencyIso, setCurrencyIso] = useState('');
    const [priceOptions, setPriceOptions] = useState([]);
    const groups = useMemo(() => {
        if (traveler_groups.length !== 0) {
            let tmp_groups: unknown[] = [];
            traveler_groups.map((group) => {
                if (group.default) {
                    tmp_groups.push(group);
                }
            });
            return tmp_groups;
        }
    }, [traveler_groups]);
    const blockStartDate = useMemo(() => {
        return window.moment.utc(startDate);
    }, [startDate]);
    const [loading, setLoading] = useState(false);
    const [validating, setValidating] = useState(false);
    const classes = useStyles();

    const handleTravelExchangeChange = (option_id: any) => () => {
        let tmp_array = [...selectedTravelExchangeOptions];
        if (tmp_array.includes(option_id)) {
            tmp_array = tmp_array.filter(item => item !== option_id);
        } else {
            tmp_array.push(option_id);
        }
        setSelectedTravelExchangeOptions(tmp_array);
    }

    const onClose = () => {
        if (!loading && !validating) {
            props.onClose();
        }
    }

    return (
        <>
            <Dialog
                open
                onClose={onClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Travel Exchange
                </DialogTitle>
                <DialogContent>
                    {
                        loading &&
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    }
                    {
                        !loading &&
                        <>
                            {
                                (travelExchangePrices?.length ?? 0) > 0 &&
                                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                    <Grid container direction={"column"}>
                                        <Typography variant={"h6"} className={classes.genericText} gutterBottom> {t("home.modal.room-type-choice")} </Typography>
                                        <FormControl variant={"outlined"} fullWidth>
                                            <Select
                                                id={`meal-plan`}
                                                value={selectedTravelExchangePrices?.id}
                                                //onChange={handleChange}
                                                MenuProps={{ disablePortal: true }}
                                                className={classes.formControlRoot1}
                                                onChange={(e) => {

                                                    const price = travelExchangePrices?.find((item) => item.id === parseInt(e.target.value));
                                                    let meal_plan_choice = [];
                                                    let default_meal = '';
                                                    if (price.standard_board !== null) {
                                                        switch (price.standard_board) {
                                                            case 'half_board':
                                                                default_meal = t('accommodation_filters.half_board');
                                                                break;
                                                            case 'full_board':
                                                                default_meal = t('accommodation_filters.all_inclusive');
                                                                break;
                                                            case 'b_and_b':
                                                                default_meal = t('accommodation_filters.breakfast_included');
                                                                break;
                                                            case 'inclusive_board':
                                                                default_meal = t('accommodation_filters.inclusive_board');
                                                                break;
                                                            case 'as_per_program': {
                                                                default_meal = t('accommodation_filters.as_per_program');
                                                                break;
                                                            }
                                                        }
                                                        meal_plan_choice.push({ type: default_meal, price: 0 });
                                                        if (price.full_board_price !== null) {
                                                            meal_plan_choice.push({ type: t('accommodation_filters.all_inclusive'), price: price.full_board_price });
                                                        }
                                                        if (price.bed_and_breakfast_price !== null) {
                                                            meal_plan_choice.push({ type: t('accommodation_filters.breakfast_included'), price: price.bed_and_breakfast_price });
                                                        }
                                                        if (price.inclusive_board_price !== null) {
                                                            meal_plan_choice.push({ type: t('accommodation_filters.inclusive_board'), price: price.inclusive_board_price });
                                                        }
                                                        if (price.half_board_price !== null) {
                                                            meal_plan_choice.push({ type: t('accommodation_filters.half_board'), price: price.half_board_price });
                                                        }
                                                        setMealPlanChoise(meal_plan_choice);
                                                        setMealPlan(default_meal);
                                                    }
                                                    setPriceOptions([]);
                                                    setSelectedTravelExchangePrices(price);
                                                }}
                                                disabled={loading || validating}
                                            >
                                                {
                                                    travelExchangePrices?.map((item) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.name}
                                                                {
                                                                    item.on_request &&
                                                                    <Chip
                                                                        size="small"
                                                                        label={t('shared.stock-type-on-demand')}
                                                                        sx={{ marginLeft: 2 }}
                                                                    />
                                                                }
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }
                            {
                                mealPlanChoice.length !== 0 &&
                                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                    <Grid container direction={"column"}>
                                        <Typography variant={"h6"} className={classes.genericText} gutterBottom> {t("accommodation_filters.meal_plan")} </Typography>
                                        <FormControl variant={"outlined"} fullWidth>
                                            <Select
                                                id={`meal-plan`}
                                                value={mealPlan}
                                                //onChange={handleChange}
                                                MenuProps={{ disablePortal: true }}
                                                className={classes.formControlRoot1}
                                                onChange={(e) => {
                                                    setMealPlan(e.target.value);
                                                }}
                                                disabled={loading || validating}
                                            >
                                                {
                                                    mealPlanChoice.map((choice, index) => {
                                                        return (
                                                            <MenuItem key={index} value={choice.type}>{`${choice.type} ${parseFloat(choice.price) !== 0 ? `(+${new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currencyIso ? currencyIso : 'EUR' }).format(parseFloat(choice.price))})` : ''}`}</MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <br />
                                    </Grid>
                                </Grid>
                            }
                            {
                                priceOptions.filter((option: any) => option.parent === selectedTravelExchangePrices?.parent).length > 0 &&
                                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                    <Grid container direction={"column"}>
                                        {
                                            priceOptions.filter((option: any) => option.parent === selectedTravelExchangePrices?.parent).map((option, option_index) => {
                                                return (
                                                    <Grid item key={option_index}>
                                                        <Grid container direction={"column"} spacing={0}>
                                                            <Grid
                                                                item
                                                                className={`${classes.font12} ${classes.justify} ${classes.truncText}`}
                                                                style={{ marginLeft: 30 }}
                                                            >
                                                                <FormControlLabel
                                                                    control={<Checkbox sx={{
                                                                        "color": `${main_color}`,
                                                                        '&.Mui-checked': {
                                                                            color: `${main_color}`,
                                                                        },
                                                                    }}
                                                                        checked={selectedTravelExchangeOptions.includes(option.id)}
                                                                        onChange={handleTravelExchangeChange(option.id)} />}
                                                                    label={option.name}
                                                                    disabled={loading || validating}
                                                                />
                                                            </Grid>
                                                            {
                                                                option.price !== undefined && option.price !== null && option.price !== '' && (
                                                                    <Grid item style={{ textAlign: "right" }}>
                                                                        <span className={`${classes.alignRight} ${classes.floatRight}`}>
                                                                            {`(+${Intl.NumberFormat(i18n.language, { style: "currency", currency: currencyIso ? currencyIso : 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(option.price))}/${option.price_type === 'group' ? t('poi.group') : t('global.person_short')})`}
                                                                        </span>
                                                                    </Grid>
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        disabled={loading || validating}
                    >
                        {t('shared.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={
                            props.loading &&
                            <CircularProgress size="1em" />
                        }
                        onClick={async () => {
                            setValidating(true);
                            await props.onValidate({ mealPlan, selectedTravelExchangeOptions, selectedTravelExchangePrices });
                            props.onClose();
                            setValidating(false);
                        }}
                        disabled={loading || validating || props.loading}
                    >
                        {t('shared.validate')}
                    </Button>
                </DialogActions>
            </Dialog>
            <TravelExchangeOptions
                version={version}
                startDate={blockStartDate}
                groups={groups}
                setMealPlanChoise={setMealPlanChoise}
                setMealPlan={setMealPlan}
                setTravelExchangePrices={setTravelExchangePrices}
                setSelectedTravelExchangePrices={setSelectedTravelExchangePrices}
                setPriceOptions={setPriceOptions}
                setCurrencyIso={setCurrencyIso}
                setLoading={setLoading}
            />
        </>
    );
}

const main_color = JSON.parse(localStorage.getItem("config") ?? '{}').main_color;

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 500
        },
        "& .mui-jss-MuiDialog-paperWidthSm": {
            maxWidth: 500
        }
    },
    customButton: {
        "background": `${main_color}`,
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 20,
        "textTransform": "none",
        "&:hover": {
            background: `${main_color}CA`
        }
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        left: "91%"
    },
    buttonProgress1: {
        color: green[500],
        position: "absolute",
        left: "46%"
    }
}));
