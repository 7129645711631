//---- Dependencies ----//
import React from 'react';
import { connect } from "react-redux";
import { Link, History } from "react-router";
import { withTranslation } from "react-i18next";

//---- Functions ----//

//---- CSS ----//
import "../../../Style/FlightSearchMobile.css";

@connect((store) => {
    return {
        flight_groups: store.flight.flight_groups,
        index_group: store.flight.index_group,
        results: store.flight_search.results
    };
})
@withTranslation()
export default class SummaryStepFlightMobile extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        	tab_array: []
        };
    }

    componentDidMount() {
        const { transport } = this.props;
        let tmp_tab = [];
        for (let i = 0; i < transport.segment.legs.length; i++) {
            tmp_tab.push(0);
        }
        this.setState({ tab_array: tmp_tab });
    }
    detailedItineraryDate(date, time) {
        let split_date = date.split(" ");
        let month = split_date[2].split("", 3);
        let day = split_date[0].split("", 3);
        return (
		    <span>{day} {split_date[1]} {month}, {time}</span>
        );
    }
    detailedItineraryDateOutside(departure_time, destination_time) {
        const { t } = this.props;
        let departure_date = moment.utc(departure_time).format("MM[/]DD[/]YYYY");
        let arrival_date = moment.utc(destination_time).format("MM[/]DD[/]YYYY");
        let day = moment.utc(new Date(arrival_date)).diff(new Date(departure_date), 'days');
        if (day >= 1) {
            return (<span className="itinerary-day-outside">+{day}{t("global.abrev_day")}</span>);
        }
    }
    render() {
        const { airlines, transport, trip_info, details, t } = this.props;
        let last_leg = transport.segment.legs.length - 1;
        let origin_city = transport.segment.legs[0].origin !== null ? transport.segment.legs[0].origin.airport_code : transport.segment.legs[0].origin_station.station_code;
        let dest_city = transport.segment.legs[last_leg].destination !== null ? transport.segment.legs[last_leg].destination.airport_code : transport.segment.legs[last_leg].destination_station.station_code;
        let departure_locale = transport.segment.legs[0].departure_time.split("Z");
        let departure_time = moment.utc(departure_locale[0]).format("HH[h]mm");
        let arrival_locale = transport.segment.legs[last_leg].arrival_time.split("Z");		
        let arrival_time = moment.utc(arrival_locale[0]).format("HH[h]mm");
        let detailed_logo = null;
        let flight_duration = 0;
        for (let i = 0; i < transport.segment.legs.length; i++) {
            flight_duration += transport.segment.legs[i].flight_time;
        }
        let total_flight_duration = (parseInt(flight_duration / 60) < 10 ? "0" + parseInt(flight_duration / 60) : parseInt(flight_duration / 60)) + 'h' + ((flight_duration % 60 < 10) ? "0" + (flight_duration % 60) : (flight_duration % 60));
        return (
            <div className={ "mobile-results" }>
                <div className={ "mobile-results-card" }>
                    <div className={ "mobile-card-itinerary row" }>
                        <div className={ "mobile-card-flight col-sm-9 col-9"}>
                            <div className="row">
                                <div className="mobile-card-origin col-sm-4 col-4 ft-pl30">
                                    <div className="row">
                                        <div className={"origin-time col-12 col-sm-12 block-center text-center"}>{departure_time}</div>
                                        <div className={"origin-airport col-12 col-sm-12 block-center text-center"}>({origin_city})</div>
                                    </div>
                                </div>
                                <div className="mobile-card-plane col-sm-4 col-4 no-padding">
                                    <div className="path-info-line-center">
                                        <i className="icon-020-flight" />
                                    </div>
                                </div>
                                <div className="mobile-card-destination col-sm-3 col-3 no-padding">
                                    <div className="row">
                                        <div className={"destination-time col-12 col-sm-12 block-center text-center"}>{arrival_time}</div>
                                        <div className={"destination-airport col-12 col-sm-12 block-center text-center"}>({dest_city})</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ "mobile-card-flight-duration col-sm-3 col-3 text-right"}>
                            <div className={ "flight-duration col-12 col-sm-12 no-padding" }>{total_flight_duration}</div>
                            <div className={ "stopover col-12 col-sm-12 no-padding" }> { transport.segment.legs.length > 1 ? (transport.segment.legs.length - 1 > 1 ? (transport.segment.legs.length - 1 + " " + t("flight_search.stopovers")) : (transport.segment.legs.length - 1 + " " + t("flight_search.stopover"))) : t("flight_groups.direct_flight") } </div>
                        </div>
                    </div>
                    {
                        transport.segment.legs.map((leg, leg_index) => {
                            let detailed_logo = "https://facilitatrip-staging-picture.s3.eu-west-3.amazonaws.com/iata-company/" + leg.marketing_airline.airline_code + ".png";
                            let departure_locale = leg.departure_time.split("Z");
                            let arrival_locale = leg.arrival_time.split("Z");
                            let departure_date = moment.utc(departure_locale[0]).format("dddd Do MMMM YYYY");
                            let departure_time = moment.utc(departure_locale[0]).format("HH[h]mm");
                            let arrival_date = moment.utc(arrival_locale[0]).format("dddd Do MMMM YYYY");
                            let arrival_time = moment.utc(arrival_locale[0]).format("HH[h]mm");
                            let origin_time = moment.utc(departure_locale[0]);
                            let dest_time = moment.utc(arrival_locale[0]);
                            let nb_carry_on = 0;
                            let baggage = 0;
                            for (let j = 0; j < leg.baggage.length; j++) {
                                if (leg.baggage[j].is_carry_on === true) {
                                    nb_carry_on += leg.baggage[j].quantity;
                                }
                                if (leg.baggage[j].is_carry_on === false) {
                                    baggage += leg.baggage[j].quantity;
                                }
                            }
                            let flight_duration = (parseInt(leg.flight_time / 60) < 10 ? "0" + parseInt(leg.flight_time / 60) : parseInt(leg.flight_time / 60)) + 'h' + ((leg.flight_time % 60 < 10) ? "0" + (leg.flight_time % 60) : (leg.flight_time % 60));
                            return (
                                <div key={leg_index} className={ "mobile-card-details" }>
                                    {
                                        leg_index > 0 && (
                                            <div className={"transit-container"} >
                                                <svg x="0px" y="0px" viewBox="0 0 53.5 89.0625" height="50px" width="50px" className={"transit-icon block-center text-center"}>
                                                    <path d="M45.85,17.837c-0.001-0.05,0.003-0.1-0.003-0.15L44.83,9.489c-0.102-0.823-0.853-1.408-1.673-1.304  c-0.822,0.102-1.406,0.851-1.304,1.673l0.538,4.338c-4.625-3.439-10.203-5.299-16.065-5.299c0,0,0,0,0,0  c-7.208,0-13.988,2.811-19.091,7.914c-0.586,0.585-0.586,1.536,0,2.121c0.586,0.586,1.535,0.586,2.121,0  c4.537-4.537,10.564-7.035,16.971-7.035c5.313,0,10.363,1.724,14.521,4.898l-4.29,0.532c-0.822,0.102-1.406,0.851-1.304,1.673  c0.094,0.759,0.741,1.315,1.487,1.315c0.062,0,0.124-0.003,0.187-0.011l7.618-0.945C45.295,19.265,45.867,18.595,45.85,17.837  C45.849,17.787,45.851,17.859,45.85,17.837z"/>
                                                    <path d="M43.223,52.986c-4.536,4.536-10.563,7.035-16.97,7.035c-5.313-0.001-10.362-1.725-14.521-4.899l4.29-0.532  c0.822-0.102,1.406-0.851,1.304-1.673s-0.853-1.404-1.673-1.304l-7.617,0.945c-0.801,0.096-1.405,0.87-1.304,1.673l1.016,8.199  c0.094,0.76,0.741,1.315,1.487,1.315c0.062,0,0.124-0.004,0.187-0.012c0.822-0.102,1.406-0.851,1.304-1.673l-0.538-4.341  c4.625,3.44,10.204,5.3,16.066,5.301c0,0,0,0,0.001,0c7.208,0,13.987-2.811,19.09-7.914c0.586-0.586,0.586-1.535,0-2.121  S43.808,52.4,43.223,52.986C38.687,57.522,43.808,52.4,43.223,52.986z"/>
                                                    <path d="M36.002,36.646l-8.518-4.461L27.57,26.8c0-0.975-0.719-1.767-1.604-1.767h-0.205c-0.886,0-1.604,0.792-1.604,1.767  l0.09,5.434l-8.325,4.413c-0.771,0.41-1.077,1.467-0.684,2.361l0.012,0.028c0.139,0.313,0.378,0.447,1.75,0.066l7.318-1.95  l0.11,5.342l-2.267,2.37c-0.065,0.067-0.102,0.159-0.102,0.255v1.031c0,0.307,0.328,0.451,0.555,0.318l3.197-1.246l3.217,1.246  c0.229,0.133,0.556-0.012,0.556-0.318v-1.031c0-0.097-0.037-0.186-0.102-0.255l-2.186-2.284l0.115-5.508l7.512,2.03  c1.328,0.336,1.611,0.247,1.75-0.067l0.011-0.027C37.077,38.112,36.771,37.056,36.002,36.646z"/>
                                                </svg>
                                                <div className={"ft-mt-10"} >  
                                                    {leg.origin !== null ? t("flight_search.change_airport") : t("flight_search.change_train")} <span className={ "stopover-detail" }>{leg.origin !== null ? leg.origin.iata_city.international_name : leg.origin_station.iata_city.international_name} - {leg.origin !== null ? (leg.origin.name !== undefined ? leg.origin.name : leg.origin.international_name) : leg.origin_station.international_name} ({leg.origin !== null ? leg.origin.airport_code : leg.origin_station.station_code})</span>,
			    			        				{t("flight_search.stopover_duration")}<span className={ " stopover-detail" }>{moment.utc(transport.segment.stopover_duration[leg_index - 1]).format("HH[h]mm")}</span>. {t("flight_search.stopover_change_airport")}
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className={"blue-segment-info row"} >
                                        <div className={"left-part-big-container col-10 no-padding"} >
                                            {
                                                this.state.tab_array[leg_index] === 0 && (
                                                    <div>
                                                        <div className={"row logo-container-mobile-flight"} >
                                                            <img src={detailed_logo} />
                                                        </div>
                                                        <div className={"row"} >
                                                            <span className={"col-12 bold capitalize"} > { departure_date } </span>
                                                            <span className={"col-12 ft-gray italic"} >{leg.marketing_airline.commercial_name}, <strong>{leg.marketing_airline.airline_code}-{leg.flight_number}</strong></span>
                                                            <span className={"col-12 ft-gray italic"} >{t("flight_search.operate_by")} {leg.operating_airline?.commercial_name}</span>
                                                            <span className={"col-12 ft-gray italic"} >{ leg.aircraft ? leg.aircraft.commercial_name : null}</span>
                                                        </div>
                                                        <br />									
                                                        <div className={"row"} >
                                                            <span className={"col-12 bold"} > Départ {this.detailedItineraryDate(departure_date, departure_time)} </span>
                                                            <span className={"col-12 ft-gray italic"} > {leg.origin !== null ? leg.origin.iata_city.international_name : leg.origin_station.iata_city.international_name} {leg.origin !== null ? (leg.origin.name !== undefined ? leg.origin.name : leg.origin.international_name) : leg.origin_station.international_name} ({leg.origin !== null ? leg.origin.airport_code : leg.origin_station.station_code})  </span>
                                                            <span className={"col-12 ft-gray italic"} > 
                                                                {leg.origin_terminal !== "No data" && (
													            	"(" + t("flight_search.terminal") + " " + leg.origin_terminal + ")"
													            	) 
													            }
													        </span>
                                                        </div>
                                                        <br />									
                                                        <div className={"row"} >
                                                            <span className={"col-12 bold"} > Arrivée {this.detailedItineraryDate(arrival_date, arrival_time)}{this.detailedItineraryDateOutside(origin_time, dest_time)} </span>
                                                            <span className={"col-12 ft-gray italic"} > {leg.destination !== null ? leg.destination.iata_city.international_name : leg.destination_station.iata_city.international_name} {leg.destination !== null ? (leg.destination.name !== undefined ? leg.destination.name : leg.destination.international_name) : leg.destination_station.international_name} ({leg.destination !== null ? leg.destination.airport_code : leg.destination_station.station_code}) </span>
                                                            <span className={"col-12 ft-gray italic"} > 
                                                                {
													            	leg.destination_terminal !== "No data" && (
													           		 	"(" + t("flight_search.terminal") + " " + leg.destination_terminal + ")"
													            	)
													            }
												            </span>
                                                        </div>
                                                        <br />									
                                                        <div className={"row"} >
                                                            <span className={"col-12 ft-gray"} > - { leg.class_name } </span>
                                                            <span className={"col-12 ft-gray"} > - { baggage > 0 ? baggage + " baggage(s) inclut" : "Pas de baggage inclut" } </span>												
                                                        </div>
                                                        <br />									
                                                        <div className={"row"} >
                                                            <span className={"col-12 bold"} > Durée du voyage : <span className={"ft-dark-green"} > {flight_duration} </span> </span>
                                                        </div>														
                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.tab_array[leg_index] === 1 && (
                                                    <div className={"blue-segment-info row"} >
                                                        <div className={"row"} >
                                                            <span className={"col-12 bold"} > { t("flight_search.luggage_carry_on") } </span>
                                                            <span className={"col-12 ft-gray italic"} >{ nb_carry_on }</span>
                                                        </div>
                                                        <br />
                                                        <div className={"row"} >
                                                            <span className={"col-12 bold"} > { t("flight_search.luggage_check") } </span>
                                                            <span className={"col-12 ft-gray italic"} >{ baggage }</span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className={"right-part-big-container col-2 no-padding"} >
                                            <div className={"row ft-no-margin"}>
                                                <div onClick={() => {
                                                    let tmp = this.state.tab_array;
                                                    tmp[leg_index] = 0;
                                                    this.setState({
                                                        tab_array: tmp
                                                    });
                                                }} className={this.state.tab_array[leg_index] === 0 ? "mobile-flight-icon-container is-active col-12" : "mobile-flight-icon-container col-12"} >
                                                    <i className={"icon-020-flight absolute-center"} />
                                                </div>		
                                                <div onClick={() => {
                                                    let tmp = this.state.tab_array;
                                                    tmp[leg_index] = 1;
                                                    this.setState({
                                                        tab_array: tmp
                                                    });
                                                }} className={this.state.tab_array[leg_index] === 1 ? "mobile-flight-icon-container is-active col-12" : "mobile-flight-icon-container col-12"} >
                                                    <i className={"icon-travel-case absolute-center"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>											
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}
