import { useMemo } from "react";
import { useSelector } from "react-redux";
import { flattenDeep, isArray, isNumber } from "lodash";
import { isProductPackaged } from "./isProductPackaged";
import { isProductSamePackage } from "./isProductSamePackage";
import { useGetFlightSellingPrice } from "./getFlightSellingPrice";
import { useGetPrice } from "./getPrice";
import { usePackagedProducts } from "./packagedProducts";
import { Currency } from "../../../Reducers/objects/currency";
import { AppState } from "../../../Reducers/Reducers";

type Package = {
    stackNumber: number | null,
    stackInfoId: number | null
}

type Costs = {
    cost: number,
    currency: Currency | undefined,
    purchaseCost: number,
    purchaseCurrency: Currency | undefined,
    diff: number
}[]

export function usePackagePrice(packageItem: Package): Costs {
    const trip = useSelector((state: AppState) => state.trip?.data_trip);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const getPrice = useGetPrice();
    const [getFlightPrice] = useGetFlightSellingPrice();
    const packagedProducts = usePackagedProducts();
    const products = useMemo(() => {
        return packagedProducts[`${packageItem.stackNumber ?? ''}-${packageItem.stackInfoId ?? ''}`];
    }, [
        packagedProducts,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const counterOfferIds = useMemo(() => {
        return flattenDeep(
            Object.values(products ?? {}).map((products) => {
                return products.map((product) => {
                    if ('type' in product) {
                        const { type, ...rest } = product;
                        return Object.values(rest).map((product) => {
                            return product.counter_offer_of as number | null;
                        });
                    }
                    return [product.counter_offer_of];
                });
            })
        ).filter((item) => isNumber(item));
    }, [products]);
    const pricesFlight = useMemo(() => {
        return trip?.prices_flight?.filter((item) => {
            return isProductSamePackage(
                item,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            );
        });
    }, [
        trip,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesTerrestrial = useMemo(() => {
        return trip?.prices_terrestrial?.filter((item) => {
            return isProductSamePackage(
                item,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            );
        });
    }, [
        trip,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesAccommodations = useMemo(() => {
        return products?.accommodations.filter((item) => {
            return item.accommodation.is_available && isProductPackaged({
                product: item.accommodation,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.accommodation,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
                !item.accommodation.is_optional &&
                !item.accommodation.provider_refused &&
                !counterOfferIds.includes(item.accommodation.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.accommodations,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesTransfers = useMemo(() => {
        return products?.transfers.filter((item) => {
            return item.transfer.is_available && isProductPackaged({
                product: item.transfer,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.transfer,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
                !item.transfer.is_optional &&
                !item.transfer.provider_refused &&
                !counterOfferIds.includes(item.transfer.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.transfers,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesPois = useMemo(() => {
        return products?.pois.filter((item) => {
            return item.poi.is_available && isProductPackaged({
                product: item.poi,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.poi,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
                !item.poi.is_optional &&
                !item.poi.provider_refused &&
                !counterOfferIds.includes(item.poi.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.pois,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesCars = useMemo(() => {
        return products?.cars.filter((item) => {
            return item.car.is_available && isProductPackaged({
                product: item.car,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.car,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
                !item.car.is_optional &&
                !item.car.provider_refused &&
                !counterOfferIds.includes(item.car.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.cars,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesFlightsProducts = useMemo(() => {
        return products?.flights.filter((item) => {
            return isProductPackaged({
                product: item.flight,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.flight,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
                !item.flight.is_optional &&
                !item.flight.provider_refused &&
                !counterOfferIds.includes(item.flight.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.flights,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);
    const pricesManualProducts = useMemo(() => {
        return products?.manualProducts.filter((item) => {
            return isProductPackaged({
                product: item,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item,
                {
                    stack_number: packageItem.stackNumber,
                    stack_info_id: packageItem.stackInfoId
                }
            ) &&
                !item.is_optional &&
                !item.provider_refused &&
                !counterOfferIds.includes(item.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.manualProducts,
        packageItem.stackNumber,
        packageItem.stackInfoId
    ]);

    return useMemo(() => {
        let total_cost: Costs = [];

        if ((pricesFlight?.length ?? 0) > 0) {
            let price = getPrice(pricesFlight!);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost + (trip?.flight_taxes !== null ? parseFloat(trip?.flight_taxes ?? '0') : 0);
                    currency_cost.purchaseCost += price.purchaseCost;
                    currency_cost.diff += price.cost - price.oldCost;

                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost + (trip?.flight_taxes !== null ? parseFloat(trip?.flight_taxes ?? '0') : 0),
                    currency: price.currency,
                    purchaseCost: price.purchaseCost,
                    purchaseCurrency: price.purchaseCurrency,
                    diff: price.cost - price.oldCost
                });
            }
        }
        if ((pricesTerrestrial?.length ?? 0) > 0) {
            let price = getPrice(pricesTerrestrial!);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                    currency_cost.purchaseCost += price.purchaseCost;
                    currency_cost.diff += price.cost - price.oldCost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency,
                    purchaseCost: price.purchaseCost,
                    purchaseCurrency: price.purchaseCurrency,
                    diff: price.cost - price.oldCost
                });
            }
        }
        if ((pricesAccommodations?.length ?? 0) > 0) {
            for (const item of pricesAccommodations ?? []) {
                if (item.type === 'normal') {
                    for (const room of item.accommodation.rooms) {
                        let price = getPrice(room.prices);
                        let matched = false;
                        total_cost.map(currency_cost => {
                            if (currency_cost.currency?.id === price.currency?.id) {
                                matched = true;
                                currency_cost.cost += price.cost;
                                currency_cost.purchaseCost += price.purchaseCost;
                                currency_cost.diff += price.cost - price.oldCost;
                            }
                        });
                        if (!matched) {
                            total_cost.push({
                                cost: price.cost,
                                currency: price.currency,
                                purchaseCost: price.purchaseCost,
                                purchaseCurrency: price.purchaseCurrency,
                                diff: price.cost - price.oldCost
                            });
                        }
                    }
                } else {
                    let price = getPrice(item.accommodation.prices);
                    let matched = false;
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency?.id === price.currency?.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                            currency_cost.purchaseCost += price.purchaseCost;
                            currency_cost.diff += price.cost - price.oldCost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency,
                            purchaseCost: price.purchaseCost,
                            purchaseCurrency: price.purchaseCurrency,
                            diff: price.cost - price.oldCost
                        });
                    }
                }
            }
        }
        if ((pricesTransfers?.length ?? 0) > 0) {
            for (const item of pricesTransfers ?? []) {
                let price = getPrice(item.transfer.prices);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                        currency_cost.purchaseCost += price.purchaseCost;
                        currency_cost.diff += price.cost - price.oldCost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency,
                        purchaseCost: price.purchaseCost,
                        purchaseCurrency: price.purchaseCurrency,
                        diff: price.cost - price.oldCost
                    });
                }
            }
        }
        if ((pricesPois?.length ?? 0) > 0) {
            for (const item of pricesPois ?? []) {
                let price = getPrice(item.poi.prices);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                        currency_cost.purchaseCost += price.purchaseCost;
                        currency_cost.diff += price.cost - price.oldCost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency,
                        purchaseCost: price.purchaseCost,
                        purchaseCurrency: price.purchaseCurrency,
                        diff: price.cost - price.oldCost
                    });
                }
            }
        }
        if ((pricesCars?.length ?? 0) > 0) {
            for (const item of pricesCars ?? []) {
                let price = getPrice(item.car.prices);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                        currency_cost.purchaseCost += price.purchaseCost;
                        currency_cost.diff += price.cost - price.oldCost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency,
                        purchaseCost: price.purchaseCost,
                        purchaseCurrency: price.purchaseCurrency,
                        diff: price.cost - price.oldCost
                    });
                }
            }
        }
        if ((pricesFlightsProducts?.length ?? 0) > 0) {
            for (const item of pricesFlightsProducts ?? []) {
                let price = item.type === 'normal' ?
                    getFlightPrice(item.flight) :
                    getPrice(item.flight.prices);
                const priceItem = isArray(price) ?
                    price[0] :
                    price;
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === priceItem?.currency?.id) {
                        matched = true;
                        currency_cost.cost += priceItem?.cost ?? 0;
                        currency_cost.purchaseCost += getPrice(item.flight.prices).purchaseCost;
                        currency_cost.diff += getPrice(item.flight.prices).cost - getPrice(item.flight.prices).oldCost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: priceItem?.cost ?? 0,
                        currency: priceItem?.currency,
                        purchaseCost: getPrice(item.flight.prices).purchaseCost,
                        purchaseCurrency: getPrice(item.flight.prices).purchaseCurrency,
                        diff: getPrice(item.flight.prices).cost - getPrice(item.flight.prices).oldCost
                    });
                }
            }
        }
        if ((pricesManualProducts?.length ?? 0) > 0) {
            for (const item of pricesManualProducts ?? []) {
                let price = getPrice(item.prices);
                let matched = false;
                total_cost.map(currency_cost => {
                    if (currency_cost.currency?.id === price.currency?.id) {
                        matched = true;
                        currency_cost.cost += price.cost;
                        currency_cost.purchaseCost += price.purchaseCost;
                        currency_cost.diff += price.cost - price.oldCost;
                    }
                });
                if (!matched) {
                    total_cost.push({
                        cost: price.cost,
                        currency: price.currency,
                        purchaseCost: price.purchaseCost,
                        purchaseCurrency: price.purchaseCurrency,
                        diff: price.cost - price.oldCost
                    });
                }
            }
        }
        if (((pricesTerrestrial?.length ?? 0) === 0 && (pricesFlight?.length ?? 0) === 0) && (trip?.prices_stack_product?.length ?? 0) > 0) {
            let price = getPrice(trip?.prices_stack_product!);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                    currency_cost.purchaseCost += price.purchaseCost;
                    currency_cost.diff += price.cost - price.oldCost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency,
                    purchaseCost: price.purchaseCost,
                    purchaseCurrency: price.purchaseCurrency,
                    diff: price.cost - price.oldCost
                });
            }
        }

        return total_cost;
    }, [
        trip,
        language,
        getPrice,
        getFlightPrice,
        packagedProducts,
        products,
        pricesFlight,
        pricesFlightsProducts,
        pricesTerrestrial,
        pricesAccommodations,
        pricesTransfers,
        pricesPois,
        pricesCars,
        pricesManualProducts
    ]);
}
