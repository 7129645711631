import React from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import ScheduleIcon from '@material-ui/icons/Schedule';
import FlightIcon from '@material-ui/icons/Flight';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';

import ConvertTime from "../Functions/ConvertTime";

import moment from "moment";

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    bold: {
        fontWeight: "bold"
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    lightGrey: {
        color: '#00000040'
    },
    black: {
        color: "#000000"
    },
    red: {
        color: "#FF0000"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    spacer: {
        padding: "8px 0px"
    },
    spacerSmall:{
        padding: 4
    },
    marginIcon: {
        marginTop: 6
    },
    redCircle: {
        border: "3px solid #FF0000",
        width: 15,
        height: 15,
        borderRadius: 8
    },
    redLine: {
        border: "2px solid #FF0000",
        background: "#FF0000",
        width: 0,
        height: 50,
        marginLeft: 5
    },
    redLineSmall: {
        border: "2px solid #FF0000",
        background: "#FF0000",
        width: 0,
        height: 29,
        marginLeft: 5
    },
    durationDaysPosition: {
        position: "relative",
        bottom: 28,
        left: 47
    },
    durationDaysPositionSmall: {
        position: "relative",
        bottom: 23,
        left: 32
    }
}))

const LegDetails = ({ leg, leg_index, max_index, stopover_duration, duration_days, small }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between(600, 960));

    const language = useSelector(store => store.header.language);
    const airlines = useSelector(store => store.flight_search.airlines);
    const airports = useSelector(store => store.flight_search.airports);
    const stations = useSelector(store => store.flight_search.stations);
    const flight_list_render = useSelector(store => store.flight_search.flight_list_render);
    const detailed_index = useSelector(store => store.flight_search.detailed_index);

    const airlineObj = airlines !== null ? airlines.find(item => item.id === leg.operating_airline) : null;
    const render_flight = flight_list_render.find(item => item.id === detailed_index);
    moment.locale(language);

    return(
        <Grid className={(isTablet || small) ? "" : classes.spacer}>
            {
                !!airlineObj && (
                <Grid container alignItems="baseline" justify='flex-start' spacing={1} className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
                    <Grid item>
                        <img src={ "https://facilitatrip-staging-picture.s3.eu-west-3.amazonaws.com/iata-company/" + airlineObj.code + ".png" } alt={ "provider logo" } style={{ maxWidth: small ? 50 : 90, maxHeight: 25 }} />
                    </Grid>
                    <Grid item className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize12} ${classes.uppercase}`}>
                        { t("flight_search.operate_by") + airlineObj.name + " - " + airlineObj.code + " " }
                        { render_flight !== undefined && render_flight.name === null && leg.operating_flight_number !== undefined && leg.operating_flight_number }
                        { render_flight !== undefined && render_flight.name === null && leg.operating_flight_number === undefined && leg.flight_number !== undefined && leg.flight_number}
                    </Grid>
                </Grid>
                    )
                }
            <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 3} className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
                <Grid item className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                    { t("global.flight") + " " + (leg_index + 1) }
                </Grid>
                <Grid item>
                    <div className={classes.redCircle} />
                    <div className={(isTablet || small) ? classes.redLineSmall : classes.redLine} />
                    <div className={classes.redCircle} />
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 2}>
                        <Grid item className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                            { t("flight_search.departure") }
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.fontWeight500} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.black} ${classes.uppercase}`} style={small ? { paddingLeft: 9 } :{ paddingLeft: 15 }}>
                            { moment(leg.departure_datetime_lt).format("DD MMM") }
                        </Grid>
                        {
                            render_flight !== undefined && !render_flight.no_time_data && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.black} ${classes.uppercase}`}>
                                    { language === "fr" ? moment(leg.departure_datetime_lt).format("HH[h]mm") : moment(leg.departure_datetime_lt).format("HH[:]mm")}
                                </Grid>
                            )
                        }
                        <Grid item className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.red}`}>
                            { leg.origin_airport !== undefined && airports !== null ? airports[leg.origin_airport].code : (stations !== null ? stations[leg.origin_station].code : "") }
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                            {
                                leg.origin_airport !== undefined && airports !== null
                                ? airports[leg.origin_airport].name !== undefined ? airports[leg.origin_airport].name : airports[leg.origin_airport].international_name
                                : (stations !== null ? stations[leg.origin_station].name !== undefined ? stations[leg.origin_station].name : stations[leg.origin_station].international_name : "")
                            }
                            { leg.flight_details.terminal.origin !== "" ? " " + t("flight_search.terminal") + " " + leg.flight_details.terminal.origin : "" }
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 : 2}>
                        {
                            render_flight !== undefined && !render_flight.no_time_data && (
                                <Grid item style={{ paddingRight: 0 }}>
                                    <ScheduleIcon className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                                </Grid>
                            )
                        }
                        {
                            render_flight !== undefined && !render_flight.no_time_data && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                    { ConvertTime(leg.flight_time, "minutes", language, t) }
                                </Grid>
                            )
                        }
                        {
                            leg.aircraft !== undefined && (
                                <Grid item style={{ paddingRight: 0 }}>
                                    <FlightIcon className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                                </Grid>
                            )
                        }
                        {
                            leg.aircraft !== undefined && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                    { leg.aircraft !== undefined && leg.aircraft.commercial_name }
                                </Grid>
                            )
                        }
                        {
                            leg.cabin_class !== undefined && (
                                <Grid item style={{ paddingRight: 0 }}>
                                    <AirlineSeatReclineExtraIcon className={`${classes.marginIcon} ${classes.genericText} ${(isTablet || small) ? classes.fontSize12 : classes.fontSize14}`} />
                                </Grid>
                            )
                        }
                        {
                            leg.cabin_class !== undefined && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                    { t(`flight_search.cabin_class.${leg.cabin_class.replaceAll(' ', '_').toLowerCase()}`) }
                                </Grid>
                            )
                        }
                        {
                            leg.booking_code !== undefined && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                    { leg.booking_code }
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid container alignItems="center" justify="flex-start" spacing={(isTablet || small) ? 1 :2}>
                        <Grid item className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                            { t("flight_search.arrival") }
                        </Grid>
                        <Grid item
                            className={`${classes.genericText} ${classes.fontWeight500} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.black} ${classes.uppercase}`}
                            style={ (leg_index === max_index && duration_days !== undefined && duration_days !== 0) ? (small ? { marginBottom: -13} : { marginBottom: -12 }) : {}}>
                            { moment(leg.arrival_datetime_lt).format("DD MMM")}
                            {
                                leg_index === max_index && duration_days !== undefined && duration_days !== 0 && (
                                    <div className={`${classes.genericText} ${classes.fontSize9} ${classes.red} ${small ? classes.durationDaysPositionSmall : classes.durationDaysPosition}`}>
                                        { "+" + duration_days + t("global.abrev_day")}
                                    </div>
                                )
                            }
                        </Grid>
                        {
                            render_flight !== undefined && !render_flight.no_time_data && (
                                <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.black} ${classes.uppercase}`}>
                                    { language === "fr" ? moment(leg.arrival_datetime_lt).format("HH[h]mm") : moment(leg.arrival_datetime_lt).format("HH[:]mm")}
                                </Grid>
                            )
                        }
                        <Grid item className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.red}`}>
                            { leg.destination_airport !== undefined && airports !== null ? airports[leg.destination_airport].code : (stations !== null ? stations[leg.destination_station].code : "") }
                        </Grid>
                        <Grid item className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                            {
                                leg.destination_airport !== undefined && airports !== null
                                ? airports[leg.destination_airport].name !== undefined ? airports[leg.destination_airport].name : airports[leg.destination_airport].international_name
                                : (stations !== null ? stations[leg.destination_station].name !== undefined ? stations[leg.destination_station].name : stations[leg.destination_station].international_name : "")
                            }
                            { leg.flight_details.terminal.destination !== "" ? " " + t("flight_search.terminal") + " " + leg.flight_details.terminal.destination : "" }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                leg_index !== max_index && (
                    <Grid className={(isTablet || small) ? classes.spacerSmall : classes.spacer}>
                        <Divider />
                        <Grid className={`${(isTablet || small) ? classes.spacerSmall : classes.spacer} ${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase} ${classes.textCenter}`}>
                            <div className={classes.genericText}>
                                {
                                    leg.destination_airport !== undefined && airports !== null
                                    ? t("flight_search.change_airport") + airports[leg.destination_airport].iata_city.international_name + " - "
                                    : (stations !== null ? t("flight_search.change_train") + stations[leg.destination_station].iata_city.international_name + " - " : "")
                                }
                                <span className={classes.black}>
                                    {
                                        leg.destination_airport !== undefined && airports !== null
                                        ? airports[leg.destination_airport].name !== undefined ? airports[leg.destination_airport].name : airports[leg.destination_airport].international_name
                                        : (stations !== null ? stations[leg.destination_station].name !== undefined ? stations[leg.destination_station].name : stations[leg.destination_station].international_name : "")
                                    }
                                    { ` (${leg.destination_airport !== undefined && airports !== null ? airports[leg.destination_airport].code : (stations !== null ? stations[leg.destination_station].code : "")})`}
                                </span>
                            </div>
                            <div className={classes.genericText}>
                                { t("flight_search.stopover_duration")}
                                <span className={classes.black}>
                                    { ConvertTime(stopover_duration, "seconds", language, t) }
                                </span>
                            </div>
                            <div className={classes.genericText}>
                                { t("flight_search.stopover_change_airport")}
                            </div>
                        </Grid>
                        <Divider />
                    </Grid>
                )
            }
        </Grid>
    )
}

export default React.memo(LegDetails);