import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DndProvider, useDragDropManager } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    IconButton,
    styled,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { ItineraryDestinationsList } from "./itineraryDestinationsList";
import { ItineraryDestinationInfoModal } from "./itineraryDestinationInfoModal";
import { ItinerarySavingRouteBlock } from "./itinerarySavingRouteBlock";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { ItinerarySteps } from "./itinerarySteps";
import { ItineraryBlockTextsCheckModal } from "./itineraryBlockTextsCheckModal";
import { ItineraryContext } from "./utils/itineraryContext";
import { useAdaltePackages } from "../Common/hooks/adaltePackages";
import { findDestinationChildren } from "./utils/findDestinationChildren";
import UpdateCart from "./Functions/UpdateCart";
import {
    resetItinerary,
    setDestinations,
    toggleShowDestinationsList
} from "./redux/reducer";
import { ToggleRightPanel } from "../../Actions/Base";
import { AppState } from "../../Reducers/Reducers";
import "../../Style/Itinerary.css";

export default function Itinerary(props: { route: any }): JSX.Element {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const map = useSelector((state: AppState) => state.itinerarySlice.map);
    const allData = useSelector((state: AppState) => state.trip.all_data);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const loading = useSelector((state: AppState) => state.itinerarySlice.loading);
    const from_duplication = useSelector((state: AppState) => state.menu.from_duplication);
    const accommodation_cart = useSelector((state: AppState) => state.accommodation.sort_cart);
    const flight_cart = useSelector((state: AppState) => state.flight.cart);
    const car_cart = useSelector((state: AppState) => state.cars_search.cart);
    const poi_cart = useSelector((state: AppState) => state.poi.cart);
    const manual_item_list = useSelector((state: AppState) => state.cart.manual_item_list);
    const traveler_groups = useSelector((state: AppState) => state.trip.traveler_groups);

    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const showDestinationsList = useSelector((state: AppState) => state.itinerarySlice.showDestinationsList);
    const [openTransportsModification, setOpenTransportsModification] = useState(false);
    const adaltePackages = useAdaltePackages();

    const onToggleDestinationsList = () => {
        dispatch(toggleShowDestinationsList());
    };
    const updateCartFromDuplication = () => {
        let nb_cart_item = (accommodation_cart?.length ?? 0) + (car_cart?.length ?? 0) + (flight_cart?.length ?? 0) + (manual_item_list?.length ?? 0);
        dispatch({
            type: "MENU_FROM_DUPLICATION",
            payload: {
                from_duplication: false
            }
        });
        let trav_arr = [];
        for (let i = 0; i < traveler_groups.length; i++) {
            if (traveler_groups[i]?.default === true) {
                trav_arr.push(traveler_groups[i]!.id);
            }
        }
        if (nb_cart_item > 0 || trip?.trip?.from_circuit_id) {
            UpdateCart(
                "COMP",
                dispatch,
                null,
                null,
                trav_arr,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                adaltePackages
            );
        }
    };
    useEffect(() => {
        dispatch(ToggleRightPanel(3));
        if (from_duplication) {
            updateCartFromDuplication();
        }
        return () => {
            dispatch(ToggleRightPanel(0));
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (map && trip?.destination) {
                if (trip.destination.data?.type !== 4) {
                    const children = await findDestinationChildren(trip.destination.id, isUserTO);
                    const position = new google.maps.LatLng({
                        lat: parseFloat(trip.destination?.data?.latitude ?? '0'),
                        lng: parseFloat(trip.destination?.data?.longitude ?? '0')
                    });
                    map.set('noNotify', true);
                    map.setCenter(position);
                    map.setZoom(trip.destination?.data?.zoom_level ?? 8);
                    dispatch(setDestinations({ state: 'success', data: children }));
                } else {
                    const position = new google.maps.LatLng({
                        lat: parseFloat(trip.destination?.data?.latitude ?? '0'),
                        lng: parseFloat(trip.destination?.data?.longitude ?? '0')
                    });
                    map.set('noNotify', true);
                    map.setCenter(position);
                    map.setZoom(trip.destination?.data?.zoom_level ?? 8);
                    dispatch(
                        setDestinations({
                            state: 'success',
                            data: [
                                {
                                    destination_id: trip.destination.id,
                                    note: trip.destination.data.note,
                                    cover_pic__url: trip.destination.data.cover_picture?.thumbnail_big ??
                                        trip.destination.data.cover_picture?.thumbnail_medium ??
                                        trip.destination.data.cover_picture?.thumbnail_little ??
                                        trip.destination.data.cover_picture?.url ??
                                        trip.destination.data.pictures?.[0]?.thumbnail_big ??
                                        trip.destination.data.pictures?.[0]?.thumbnail_medium ??
                                        trip.destination.data.pictures?.[0]?.thumbnail_little ??
                                        trip.destination.data.pictures?.[0]?.url ??
                                        '',
                                    international_name: trip.destination.data.localization.find((item) => {
                                        return item.locale === locale;
                                    })?.name ??
                                        trip.destination.data.international_name,
                                    latitude: parseFloat(trip.destination.data.latitude),
                                    longitude: parseFloat(trip.destination.data.longitude),
                                    suggested_hours: trip.destination.data.suggested_hours,
                                    type: trip.destination.data.type,
                                }
                            ]
                        })
                    );
                }
            }
        })();
    }, [
        map,
        trip?.destination?.id,
        trip?.destination?.parent,
        isUserTO
    ]);

    //this is needed so blocks are not reloaded when a scrollbar appears
    useEffect(() => {
        document.documentElement.style.overflow = 'hidden';
        return () => {
            document.documentElement.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetItinerary());
        }
    }, []);

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Wrapper>
                    <Grid
                        container
                        flexWrap={isMobile ? 'wrap' : 'nowrap'}
                        sx={{ minHeight: 'calc(100% - 63px)', maxHeight: 'calc(100% - 63px)', position: 'relative' }}
                    >
                        {
                            showDestinationsList &&
                            <Grid item xs={12} lg={4} sx={{ width: '100%', borderRight: '1px solid #ddd', overflowY: 'auto' }}>
                                <ItineraryDestinationsList />
                            </Grid>
                        }
                        <Grid
                            item
                            xs={12}
                            lg={showDestinationsList ? 8 : undefined}
                            sx={{ overflowY: openTransportsModification ? 'hidden' : 'auto' }}
                        >
                            <HideDestinationsListButton onClick={onToggleDestinationsList}>
                                {
                                    showDestinationsList ?
                                        <KeyboardArrowLeft viewBox="0 0 10 24" /> :
                                        <KeyboardArrowRight viewBox="0 0 10 24" />
                                }
                            </HideDestinationsListButton>
                            <ItinerarySteps
                                openTransportModification={openTransportsModification}
                                onToggleTransportModification={setOpenTransportsModification}
                            />
                        </Grid>
                    </Grid>
                </Wrapper>
            </DndProvider>
            <ItineraryDestinationInfoModal />
            <ItinerarySavingRouteBlock route={props.route} />
            <LoadingBackDrop open={loading} />
            <ItineraryBlockTextsCheckModal />
        </>
    );
}

type WrapperProps = { children: JSX.Element }

function Wrapper(props: WrapperProps): JSX.Element {
    const [value, setValue] = useState<Parameters<typeof ItineraryContext.Provider>[0]['value']>({
        isDragging: false
    });
    const dragDropManager = useDragDropManager();

    useEffect(() => {
        return dragDropManager.getMonitor().subscribeToStateChange(() => {
            //@see https://stackoverflow.com/questions/28408720/jquery-changing-the-dom-on-dragstart-event-fires-dragend-immediately
            setTimeout(() => {
                const monitor = dragDropManager.getMonitor();
                setValue((state) => {
                    if (state.isDragging !== monitor.isDragging()) {
                        return { isDragging: monitor.isDragging() };
                    }
                    return state;
                });
            }, 0);
        });
    }, [dragDropManager]);

    return (
        <ItineraryContext.Provider value={value}>
            {props.children}
        </ItineraryContext.Provider>
    );
}

const HideDestinationsListButton = styled(IconButton)((props) => ({
    "position": 'fixed',
    "top": '50%',
    "transform": 'translateX(-20px)',
    "backgroundColor": '#C4C4C4',
    "zIndex": 1,
    '&:hover': { backgroundColor: '#C4C4C4' },
    [props.theme.breakpoints.down('lg')]: {
        display: 'none'
    }
}));
