import React, { useEffect, useState, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import Add from '@material-ui/icons/Add';
import { Download, Warning } from "@mui/icons-material";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import SortByKey from "../../Common/SortByKey";

import { AddFileToDl, ClearFilesToDl, SetTripDocs, SetTripDocsDoc, SetUpdateDocsDoc } from "../../../Actions/Menu";

import GetProductType from "./Functions/GetProductType.js";
import TripListOtherDocuments from "./TripListOtherDocuments";
import { TripListUploadVoucher } from "./TripListUploadVoucher";
import { TripListRoadbookAddButton } from "./TripListRoadbookAddButton";

import axios from "axios";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DocumentActionBar from "./TripListDocumentActionBar.js";
import { FormControl, InputLabel } from '@material-ui/core';
import { Stack, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    upperCase: {
        textTransform: "uppercase"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter: {
        textAlign: "center"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    genericPadding: {
        padding: "0px 20px"
    },
    genericPaddingMobile: {
        padding: "0px 8px"
    },
    categoryPadding: {
        padding: 8
    },
    spaceTop: {
        marginTop: 20
    },
    spaceTopMobile: {
        marginTop: 10
    },
    spacer: {
        margin: "16px 0px"
    },
    spacerMobile: {
        margin: "8px 0px"
    },
    indentation: {
        paddingLeft: 16
    },
    borderTableCell: {
        borderBottom: "none"
    },
    checkboxCellWidth: {
        width: 150
    },
    colorBlue: {
        color: '#4A75DA'
    },
    colorRed: {
        color: 'red'
    },
    colorGrey: {
        color: "#808080"
    },
    iconBorder: {
        paddingRight: 4,
        marginLeft: 8,
        verticalAlign: "sub",
        borderRadius: 20
    },
    checkBorder: {
        border: "1px solid #808080"
    },
    paddingLeft60: {
        paddingLeft: '60px !important'
    }
}));

const MAX_REQUESTS_COUNT = 29;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

const getIndexNbForVersion = (array, nb) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === nb) {
            return (i + 1);
        }
    }
    return 1;
};

const getFileName = (trip_info, trip_displayed_version, doc, voucher, traveler, label, t) => {
    let index_version = getIndexNbForVersion(trip_info.data, trip_displayed_version);
    console.log('doc:', doc);
    console.log('voucher:', voucher);
    let voucher_name = null;
    if (voucher !== undefined && voucher !== null) {
        voucher_name = voucher.name.replaceAll("/", "-");
    }
    let name_split = doc.file_name !== null ? doc.file_name.split(".") : [];
    let extention = name_split.length !== 0 ? name_split[name_split.length - 1] : "";
    switch (doc.type) {
        case "CARD":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.id_card") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "PASSPORT":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.passport") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "E-VISA":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + "e-Visa" + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "VOUCHER_FLIGHT":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.plane_ticket") + " " + t("global.of") + " " + t(`title_value.${traveler.title}`) + " " + traveler.first_name + " " + traveler.last_name + "." + extention;
        case "VOUCHER":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.voucher") + " " + (voucher !== undefined ? (t("providers." + GetProductType(voucher.product_type)) + " : " + voucher_name + "." + extention) : (doc.file_name !== '' ? doc.file_name : ''));
        case "VOUCHER_HOTELS":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("global.voucher") + " " + t('accommodation.hotels') + "." + extention;
        case "QUOTATION":
            return t("menu.trip_list.quotation_title") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + "." + extention;
        case "CONDITIONS":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.conditions") + "." + extention;
        case "TRAVELBOOK":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.travelbook") + "." + extention;
        case "HOTELCARD":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + label + "." + extention;
        case "CONTRACT":
            return t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version + " " + t("menu.trip_list.contract") + "." + extention;
        default:
            return doc.file_name;
    }
};

const TripListDocument = (({ trip_displayed_version }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const uploadVoucherInputRef = useRef(null);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const travelers = useSelector(store => store.trip.travelers);
    const trip_info = useSelector(store => store.menu.trip_info);
    const store_trip_docs = useSelector(store => store.menu.trip_docs);
    const files_to_dl = useSelector(store => store.menu.files_to_dl);
    const need_update_docs = useSelector(store => store.menu.need_update_docs);
    const deleted_doc = useSelector(store => store.menu.deleted_doc);
    const accommodation_cart = useSelector(store => store.accommodation.cart);
    const data_trip = useSelector(store => store.trip.data_trip);
    const locales = useSelector(state => state.user.locales);
    const user = useSelector(state => state.user.user);
    const currentLocale = useSelector((state) => state.header.language);

    const [storeTripDocs, setStoreTripDocs] = useState(store_trip_docs);
    const [modalUploadVoucher, setModalUploadVoucher] = useState(false);
    const [displayIDCard, setDisplayIDCard] = useState(false);
    const [displayPassport, setDisplayPassport] = useState(false);
    const [carVoucher, setCarVoucher] = useState([]);
    const [roomVoucher, setRoomVoucher] = useState([]);
    const [flightVoucher, setFlightVoucher] = useState([]);
    const [activityVoucher, setActivityVoucher] = useState([]);
    const [transferVoucher, setTransferVoucher] = useState([]);
    const [allVoucher, setAllVoucher] = useState([]);
    const [voucherAreLoaded, setVoucherAreLoaded] = useState(false);
    const [docsAreLoaded, setDocsAreLoaded] = useState(false);
    const [storeDocsAreLoaded, setStoreDocsAreLoaded] = useState(false);
    const [update, setUpdate] = useState(false);
    const [displayMiniSiteAll, setDisplayMiniSiteAll] = useState(false);
    const [displayVoucherAll, setDisplayVoucherAll] = useState(false);
    const [displayManualMiniSiteAll, setDisplayManualMiniSiteAll] = useState(false);
    const [displayManualVoucherAll, setDisplayManualVoucherAll] = useState(false);
    const [refreshSpinner, setRefreshSpinner] = useState(false);
    const [downloadSpinner, setDownloadSpinner] = useState(false);
    const [checkDocument, setCheckDocument] = useState(false);
    const [voucherLocale, setVoucherLocale] = useState(currentLocale);
    const [trip_docs, setTrip_docs] = useState([]);
    const [downloadType, setDownloadType] = useState(null);
    const onVoucherLocaleChange = (event) => {
        setVoucherLocale(event.target.value);
    };
    useEffect(() => {
        let uniqueTripDocs = [];
        let seen = new Map();
        store_trip_docs.forEach(doc => {
            const { product, type } = doc;
            if (product === null) {
                uniqueTripDocs.push(doc);
            } else {
                // Check if the product is already seen
                if (!seen.has(product)) {
                    seen.set(product, doc);
                } else {
                    // If it's seen, only replace if the current type is 'CUSTOM_VOUCHER'
                    if (type === 'CUSTOM_VOUCHER') {
                        seen.set(product, doc);
                    }
                }
            }
        });
        let new_docs = Array.from(seen.values());
        uniqueTripDocs = [...uniqueTripDocs, ...new_docs];
        if ((store_trip_docs.length !== storeTripDocs.length) && (uniqueTripDocs.length !== store_trip_docs.length || deleted_doc)) {
            dispatch({type: 'MENU_SET_NEEDS_UPDATE_DOCS', payload: true});
            dispatch({type: 'MENU_SET_DELETED_DOC', payload: false});
        }
        setTrip_docs(uniqueTripDocs);
        setStoreTripDocs(store_trip_docs);
    }, [store_trip_docs]);
    // useEffect(() => {
    //     if (need_update_docs && roomVoucher.length !== 0) {
    //         refreshAllVoucher();
    //     }
    // }, [need_update_docs, roomVoucher]);
    useEffect(() =>{
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/voucher/get_voucher_list/`,
                headers: headers
            }).then(function (response) {
                let temp_room_voucher = [];
                let temp_flight_voucher = [];
                let temp_car_voucher = [];
                let temp_activity_voucher = [];
                let temp_transfer_voucher = [];
                let temp_all_voucher = [];

                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].product_type === 0 || response.data[i].product_type === 7) {
                        temp_room_voucher.push(response.data[i]);
                    } else if (response.data[i].product_type === 6) {
                        temp_flight_voucher.push(response.data[i]);
                    } else if (response.data[i].product_type === 2) {
                        temp_car_voucher.push(response.data[i]);
                    } else if (response.data[i].product_type === 4) {
                        temp_transfer_voucher.push(response.data[i]);
                    } else {
                        temp_activity_voucher.push(response.data[i]);
                    }
                    temp_all_voucher.push(response.data[i]);
                }
                setCarVoucher(temp_car_voucher.sort(SortByKey(true, "start_date")));
                setRoomVoucher(temp_room_voucher.sort(SortByKey(true, "start_date")));
                setFlightVoucher(temp_flight_voucher.sort(SortByKey(true, "start_date")));
                setActivityVoucher(temp_activity_voucher.sort(SortByKey(true, "start_date")));
                setTransferVoucher(temp_transfer_voucher.sort(SortByKey(true, "start_date")));
                setAllVoucher(temp_all_voucher.sort(SortByKey(true, "start_date")));
                setVoucherAreLoaded(true);
                refreshDocs();
            });
        }
        return () => {
            dispatch(SetTripDocs([]));
        };
    }, []);

    useEffect(() => {
        if (data_trip !== null && data_trip.for_user !== undefined && data_trip.for_user !== null && trip_docs.length !== 0 && !checkDocument) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/user/${data_trip.for_user.id}/`,
                    headers: headers
                }).then((response) => {
                    let identity_document = response.data.identity_documents.find((el) => el.identity_doc === 'PASSPORT');
                    if (identity_document !== undefined) {
                        let traveler_info = travelers.find((traveler) => {
                            return traveler.identity_doc_number !== undefined && traveler.identity_doc_number !== null && traveler.identity_doc_number === identity_document.identity_doc_number;
                        });
                        if (traveler_info !== undefined) {
                            let doc_user = trip_docs.find((doc) => doc.type === 'PASSPORT' && doc.traveler === traveler_info.id);
                            if (!doc_user.received) {
                                let request = {
                                    bucket_name: identity_document.document.bucket_name,
                                    bucket_region: identity_document.document.bucket_region,
                                    file_name: identity_document.document.file_name,
                                    object_name: identity_document.document.object_name,
                                    received: true
                                };
                                if (pass_check) {
                                    axios({
                                        method: "PATCH",
                                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${doc_user.id}/`,
                                        headers: headers,
                                        data: JSON.stringify(request)
                                    }).then(function (response) {
                                        enqueueSnackbar(t("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                                        dispatch(SetTripDocsDoc(response.data));
                                    }).catch(function (error) {
                                        console.log(error.responseJSON);
                                        enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                                    });
                                }
                            }
                        }
                    }
                    setCheckDocument(true);
                    
                }).catch((error) => {
                    console.log('error:', error);
                });
            }
        }
    }, [data_trip, trip_docs]);

    useEffect(() =>{
        for (travelers_index = 0; travelers_index < travelers.length; travelers_index++) {
            if (travelers[travelers_index].identity_doc === "PASSPORT" && displayPassport === false) {
                setDisplayPassport(true);
            }
            if (travelers[travelers_index].identity_doc === "CARD" && displayIDCard === false) {
                setDisplayIDCard(true);
            }
        }
    }, [travelers]);

    useEffect(() =>{
        if (voucherAreLoaded && docsAreLoaded && (trip_docs.length !== 0 || store_trip_docs.length === 0)) {
            createAllDocs();
        }
    }, [travelers, voucherAreLoaded, docsAreLoaded, trip_docs]);
    useEffect(() => {
        if (trip_docs.length !== 0) {
            let all_voucher_doc = trip_docs.filter((doc) => doc.type === "VOUCHER" || doc.type === "VOUCHER_HOTELS");
            let all_manual_voucher_doc = trip_docs.filter((doc) => doc.type === "VOUCHER_FLIGHT");
            let all_display_voucher = true;
            let all_display_mini_site = true;
            let all_display_manual_voucher = true;
            let all_display_manual_mini_site = true;
            all_voucher_doc.map((doc) => {
                if (!doc.display_voucher) {
                    all_display_voucher = false;
                }
                if (!doc.display_minisite) {
                    all_display_mini_site = false;
                }
            });
            all_manual_voucher_doc.map((doc) => {
                if (!doc.display_voucher) {
                    all_display_manual_voucher = false;
                }
                if (!doc.display_minisite) {
                    all_display_manual_mini_site = false;
                }
            });
            setDisplayVoucherAll(all_display_voucher);
            setDisplayMiniSiteAll(all_display_mini_site);
            setDisplayManualVoucherAll(all_display_manual_voucher);
            setDisplayManualMiniSiteAll(all_display_manual_mini_site);
            setDocsAreLoaded(true);
        }
    }, [trip_docs]);

    useEffect(() => {
        if (files_to_dl.length > 0) {
            let link = document.createElement('a');
            let JSZip = require("jszip");
            let zip = new JSZip();

            files_to_dl.map((file) => {
                zip.file(file.file_name, file.blob, { binary: true, createFolders: false });
            });
            zip.generateAsync({ type: "blob" })
                .then(function (blob) {
                    link.href = window.URL.createObjectURL(blob);
                    let index_version = getIndexNbForVersion(trip_info.data, trip_displayed_version);
                    let zip_name = downloadType === 'all' ? t("menu.trip_list.documents") + " " + t("menu.file_nb") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + index_version : t('menu.trip_list.all_vouchers');
                    link.download = zip_name;
                    link.click();
                    dispatch(ClearFilesToDl());
                    setDownloadType(null);
                    setDownloadSpinner(false);
                });
        }
    }, [files_to_dl]);

    const checkDocExists = (doc_type, traveler, product_id, hotel_id) => {
        let docFound = false;
        let traveler_to_check = traveler !== null ? traveler.id : traveler;
        trip_docs.map((doc) => {
            if (doc.type === doc_type && doc.traveler === traveler_to_check && doc.product === product_id && doc.hotel === hotel_id) {
                docFound = true;
            }
        });
        return docFound;
    };

    const createDocObject = (doc_type, traveler, product_id, hotel_id = null) => {
        let docFound = checkDocExists(doc_type, traveler, product_id, hotel_id);
        if (docFound === false) {
            let { pass_check, headers } = CheckBeforeRequest();
            let request = {
                type: doc_type,
                traveler: traveler !== null ? traveler.id : traveler,
                product: product_id,
                hotel: hotel_id
            };
            if (pass_check) {
                if (doc_type === 'QUOTATION') {
                    if (["cercledesvoyages"].includes(quotation_code)) {
                        let quotation_request = [];
                        trip_info.data.map((data) => {
                            if (data.status_contract !== "AMENDMENT") {
                                quotation_request.push(
                                    axios({
                                        method: "POST",
                                        // url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/`,
                                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${data.id}/file/`,
                                        headers: headers,
                                        data: JSON.stringify(request)
                                    })
                                );
                            }
                            if (quotation_request.length !== 0) {
                                axios.all([...quotation_request]);
                            }
                        });
                    } else {
                        axios({
                            method: "POST",
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/`,
                            headers: headers,
                            data: JSON.stringify(request)
                        });
                    }
                }
                if (doc_type === "CUSTOM_FILE" && ["cercledesvoyages", "touristforum"].includes(quotation_code)) {
                    for (let i = 0; i < 8; i++) {
                        let custom_file_request = [];
                        custom_file_request.push(
                            axios({
                                method: "POST",
                                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/`,
                                headers: headers,
                                data: JSON.stringify(request)
                            })
                        );
                        axios.all([...custom_file_request]).then((responses) => {
                            refreshDocs();
                        }).catch((error) => {
                            console.log('error post custom file:', error);
                        });
                    }
                }
                if (['PASSPORT', 'E-VISA', 'OTHER', 'CARD'].includes(doc_type)) {
                    axios({
                        method: "POST",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    });
                } else {
                    axios({
                        method: "POST",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    });
                }
            }
        }
    };
    const createDocObjects = (doc_type, traveler, product_ids, hotel_id = null) => {
        let docFound = checkDocExists(doc_type, traveler, null, hotel_id);
        if (docFound === false) {
            let { pass_check, headers } = CheckBeforeRequest();
            let request = {
                type: doc_type,
                traveler: traveler !== null ? traveler.id : traveler,
                product: null,
                hotel: hotel_id
            };
            let url = '';
            if (['PASSPORT', 'E-VISA', 'CUSTOM_FILE', 'QUOTATION', 'OTHER'].includes(doc_type)) {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/`;
            } else {
                url = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/`;
            }
            if (pass_check) {
                axios({
                    method: "POST",
                    url: url,
                    headers: headers,
                    data: JSON.stringify(request)
                });
            }
        }
    };
    const createAllDocs = () => {
        if (quotation_code !== "marcovasco") {
            createDocObject("QUOTATION", null, null);
        }
        flightVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        });
        carVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        });
        roomVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        });
        if (roomVoucher.length > 0) {
            let ids = [];
            roomVoucher.map((voucher) => {
                voucher.product_ids.map(id => ids.push(id));
            });
            createDocObjects('VOUCHER_HOTELS', null, ids);
        }
        activityVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        });
        transferVoucher.map((voucher) => {
            createDocObject("VOUCHER", null, voucher.product_ids[0]);
        });

        if (['cercledesvoyages', 'continentsinsolites', 'verdie', 'americavoyages', 'connections', 'autigrevanille', 'terreslointaines', 'tropicalementvotre', 'touristforum', 'travelmuz'].includes(quotation_code)) {
            createDocObject("CONDITIONS", null, null);
            createDocObject("TRAVELBOOK", null, null);
            if (quotation_code === "verdie") {
                createDocObject("CONTRACT", null, null);
            }
            if (['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code)) {
                createDocObject("INSURANCE_BOOKLET", null, null);
            }
            travelers.map((traveler) => {
                createDocObject(traveler.identity_doc, traveler, null);
                createDocObject("E-VISA", traveler, null);
                if (['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'travelmuz'].includes(quotation_code)) {
                    createDocObject("VOUCHER_FLIGHT", traveler, null);
                }
            });
            let hotels_id_list = [];
            accommodation_cart.map((accommodation) => {
                if (accommodation.booking_status !== null && accommodation.booking_status.status_booking === "CONFIRMED") {
                    if (!hotels_id_list.includes(accommodation.hotel[0].id)) {
                        hotels_id_list.push(accommodation.hotel[0].id);
                    }
                }
            });

            hotels_id_list.map((hotel_id) => {
                createDocObject("HOTELCARD", null, null, hotel_id);
            });
        }
        setVoucherAreLoaded(false);
        setDocsAreLoaded(false);
        refreshDocs();
    };

    const refreshDocs = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            let file_request = [];
            file_request.push(
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/`,
                    headers: headers
                })
            );
            file_request.push(
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/?trip_version_id=${trip_displayed_version}`,
                    headers: headers
                })
            );
            axios.all([...file_request]).then(axios.spread((...responses) => {
                let version_doc = responses[0].data.results.slice();
                version_doc = version_doc.filter((doc) => !['PASSPORT', 'E-VISA', 'OTHER', 'CARD', 'CUSTOM_FILE'].includes(doc.type));
                let trip_doc = responses[1].data.results.slice();
                let tmp_doc = version_doc.filter(elem => !trip_doc.find(subElem => subElem.id === elem.id)).concat(trip_doc);
                if ((quotation_code === "cercledesvoyages" || quotation_code === "continentsinsolites" || quotation_code === "touristforum" || quotation_code === "travelmuz")) {
                    let custom_file = tmp_doc.filter((doc) => doc.type === "CUSTOM_FILE");
                    if (custom_file.length === 0) {
                        createDocObject("CUSTOM_FILE", null, null);
                    }
                }
                setStoreDocsAreLoaded(true);
                dispatch(SetTripDocs(tmp_doc));
            })).catch(function (error) {
                console.log(error);
            });
        }
    };

    const downloadAllDocs = () => {
        console.log('trip_docs:', trip_docs);
        let doc_to_download = [];
        let request_nb = 0;
        setDownloadType('all');
        trip_docs.map((doc) => {
            if (doc.received && doc.bucket_name !== null && doc.file_name !== null) {
                request_nb++;
                let object_name = `${doc.type}-TRIP%23${trip_info.id}-VER%23${trip_displayed_version}`;
                let traveler = null;
                let label = "";
                let accommodation = null;

                if (doc.traveler !== null) {
                    object_name += `-ID-${doc.traveler}`;
                    traveler = travelers.find(item => item.id === doc.traveler);
                } else if (doc.product !== null) {
                    object_name += `-ID-${doc.product}`;
                } else if (doc.hotel !== null) {
                    object_name += `-ID-${doc.hotel}`;
                    for (let room_index = 0; room_index < accommodation_cart.length; room_index++) {
                        if (accommodation_cart[room_index].booking_status !== null && accommodation_cart[room_index].booking_status.status_booking === "CONFIRMED" && doc.hotel === accommodation_cart[room_index].hotel[0].id) {
                            accommodation = accommodation_cart[room_index];
                        }
                    }
                }

                let voucher = null;
                if (doc.type === "VOUCHER") {
                    voucher = allVoucher.find(item => item.product_ids[0] === doc.product);
                }

                if (doc.type === "CONDITIONS") {
                    label = t("menu.trip_list.conditions");
                } else if (doc.type === "TRAVELBOOK") {
                    label = t("menu.trip_list.travelbook");
                } else if (doc.type === "HOTELCARD" && accommodation !== null) {
                    label = t("menu.trip_list.hotelcard") + " " + accommodation.hotel[0].name;
                } else if (doc.type === "CONTRACT") {
                    label = t("menu.trip_list.contract");
                }

                let file_name = getFileName(trip_info, trip_displayed_version, doc, voucher, traveler, label, t).slice(0, 250);
                let { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    const domain = window.location.host.replace("www.", "").split('.');
                    axios({
                        method: "GET",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${doc.bucket_name}&object_name=${encodeURIComponent(doc.object_name)}&file_name=${encodeURIComponent(file_name)}`,
                        headers: headers,
                        responseType: 'arraybuffer'
                    }).then(function (response) {
                        let blob = new Blob([response.data], { type: "application/pdf" });
                        // dispatch(AddFileToDl(file_name, blob));
                        doc_to_download.push({
                            file_name: decodeURIComponent(file_name),
                            blob: blob
                        });
                        if (doc_to_download.length === request_nb) {
                            dispatch({
                                type: 'MENU_FILE_TO_DL',
                                payload: doc_to_download
                            });
                        }
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                        enqueueSnackbar(t("menu.trip_list.failed_doc_download"), { variant: "warning", disableWindowBlurListener: true });
                    });
                }
            }
        });
        
    };
    const downloadAllVoucher = () => {
        let voucher_doc = trip_docs.filter((doc) => ['VOUCHER', 'VOUCHER_FLIGHTS', 'CUSTOM_VOUCHER'].includes(doc.type));
        setDownloadSpinner(true);
        setDownloadType('voucher');
        let voucher_to_download = [];
        let request_nb = 0;
        voucher_doc.map((doc) => {
            if (doc.received && doc.bucket_name !== null && doc.file_name !== null) {
                request_nb += 1;
                let object_name = `${doc.type}-TRIP%23${trip_info.id}-VER%23${trip_displayed_version}`;
                let traveler = null;
                let label = "";
                let accommodation = null;

                if (doc.traveler !== null) {
                    object_name += `-ID-${doc.traveler}`;
                    traveler = travelers.find(item => item.id === doc.traveler);
                } else if (doc.product !== null) {
                    object_name += `-ID-${doc.product}`;
                } else if (doc.hotel !== null) {
                    object_name += `-ID-${doc.hotel}`;
                    for (let room_index = 0; room_index < accommodation_cart.length; room_index++) {
                        if (accommodation_cart[room_index].booking_status !== null && accommodation_cart[room_index].booking_status.status_booking === "CONFIRMED" && doc.hotel === accommodation_cart[room_index].hotel[0].id) {
                            accommodation = accommodation_cart[room_index];
                        }
                    }
                }

                let voucher = null;
                if (doc.type === "VOUCHER") {
                    voucher = allVoucher.find(item => item.product_ids[0] === doc.product);
                }

                if (doc.type === "CONDITIONS") {
                    label = t("menu.trip_list.conditions");
                } else if (doc.type === "TRAVELBOOK") {
                    label = t("menu.trip_list.travelbook");
                } else if (doc.type === "HOTELCARD" && accommodation !== null) {
                    label = t("menu.trip_list.hotelcard") + " " + accommodation.hotel[0].name;
                } else if (doc.type === "CONTRACT") {
                    label = t("menu.trip_list.contract");
                }

                let file_name = getFileName(trip_info, trip_displayed_version, doc, voucher, traveler, label, t).slice(0, 250);
                let { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    const domain = window.location.host.replace("www.", "").split('.');
                    axios({
                        method: "GET",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/download_from_aws/?bucket_name=${doc.bucket_name}&object_name=${encodeURIComponent(doc.object_name)}&file_name=${encodeURIComponent(file_name)}`,
                        headers: headers,
                        responseType: 'arraybuffer'
                    }).then(function (response) {
                        let blob = new Blob([response.data], { type: "application/pdf" });
                        voucher_to_download.push({
                            file_name: decodeURIComponent(file_name),
                            blob: blob
                        })
                        console.log('voucher_to_download:', voucher_to_download);
                        if (voucher_to_download.length === request_nb) {
                            dispatch({
                                type: 'MENU_FILE_TO_DL',
                                payload: voucher_to_download
                            });
                        }
                        // dispatch(AddFileToDl(file_name, blob));
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                        enqueueSnackbar(t("menu.trip_list.failed_doc_download"), { variant: "warning", disableWindowBlurListener: true });
                    });
                }
            }
            
        });
    }
    const refreshAllVoucher = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let file_request = [];
        const api = axios.create({});
        setRefreshSpinner(true);
        let room_voucher_id = roomVoucher.filter(item => {
            return trip_docs.find(doc => doc.type === 'VOUCHER' && doc.product === item.product_ids[0]);
        }).reduce((acc, cur) => [...acc, ...cur.product_ids], []);
        if (pass_check && room_voucher_id.length !== 0) {
            /**
             * Axios Request Interceptor
             */
            api.interceptors.request.use(function (config) {
                return new Promise((resolve, reject) => {
                    let interval = setInterval(() => {
                        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                            PENDING_REQUESTS++;
                            clearInterval(interval);
                            resolve(config);
                        } 
                    }, INTERVAL_MS);
                });
            });

            /**
             * Axios Response Interceptor
             */
            api.interceptors.response.use(function (response) {
                PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
                return Promise.resolve(response);
            }, function (error) {
                PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
                return Promise.reject(error);
            });
            let url_all_room = `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/voucher/?preview=f`;
            room_voucher_id.map(id => url_all_room += `&product_id=${id}`);
            file_request.push(
                api({
                    method: "GET",
                    url: url_all_room,
                    headers: {
                        ...headers,
                        'Accept-Language': voucherLocale,
                    },
                    responseType: 'arraybuffer'
                })
            );
            allVoucher.map((voucher) => {
                let doc_url = `${API_HREF}${voucher.url_pdf}`;
                file_request.push(
                    api({
                        method: "GET",
                        url: doc_url,
                        headers: {
                            ...headers,
                            'Accept-Language': voucherLocale,
                        },
                        data: { id: voucher.product_ids },
                        responseType: 'arraybuffer'
                    })
                );
            });
            axios.all([...file_request]).then(axios.spread((...responses) => {
                let upload_request = [];
                responses.forEach((response) => {
                    let data = response.config.data !== undefined ? JSON.parse(response.config.data) : null;
                    let doc = trip_docs.find(item => {
                        if (data === null) {
                            return item.type === "VOUCHER_HOTELS";
                        } 
                        return item.product === data.id[0] && item.type === "VOUCHER";
                    });
                    let doc_voucher = null;
                    if (doc !== undefined && doc.type === "VOUCHER") {
                        doc_voucher = allVoucher.find(item => item.product_ids[0] === doc.product);
                    }
                    if (doc !== undefined) {
                        let blob = new Blob([response.data], { type: "application/pdf" });
                        let formData = new FormData();
                        const domain = window.location.host.replace("www.", "").split('.');
                        formData.append("file_object", blob);
                        formData.append("bucket_name", 'facilitatrip-documents');
                        formData.append("prefix", `${domain[0]}-${domain[2] === 'com' ? 'prod' : 'pre-prod'}`);
                        formData.append("type", doc.type);
                        formData.append("tripdocument_id", doc.id);
                        if (doc_voucher !== null) {
                            formData.append("file_name", `${doc_voucher.name}.pdf`);
                        }
                        if (data !== null) {
                            formData.append("product", data.id[0]);
                        }
                        let { pass_check, headers } = CheckBeforeRequest();
                        headers["Content-Type"] = "multipart/form-data";
                        if (pass_check) {
                            upload_request.push(
                                api({
                                    method: "POST",
                                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/file/upload_to_aws/`,
                                    headers: headers,
                                    data: formData
                                }));
                        }
                    }
                });
                axios.all([...upload_request]).then(axios.spread((...responses_upload) => {
                    let trip_docs = [];
                    responses_upload.forEach((response_upload) => {
                        trip_docs.push(response_upload.data);
                    });
                    dispatch(SetUpdateDocsDoc(trip_docs));
                    setRefreshSpinner(false);
                    dispatch({type: 'MENU_SET_NEEDS_UPDATE_DOCS', payload: false});
                    enqueueSnackbar(t("menu.trip_list.success_doc_upload"), { variant: "success", disableWindowBlurListener: true });
                })).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                    setRefreshSpinner(false);
                });
            })).catch(function (error) {
                console.log(error);
                enqueueSnackbar(t("menu.trip_list.failed_doc_upload"), { variant: "error", disableWindowBlurListener: true });
                setRefreshSpinner(false);
            });
        } else {
            dispatch({type: 'MENU_SET_NEEDS_UPDATE_DOCS', payload: false});
            setRefreshSpinner(false);
        }
    };
    const handleDisplayVoucherAll = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let all_voucher = trip_docs.filter((doc) => doc.type === "VOUCHER" || doc.type === "VOUCHER_HOTELS");
        let file_request = [];
        if (pass_check) {
            let request = {
                display_voucher: !displayVoucherAll
            };
            all_voucher.map((doc) => {
                file_request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${doc.id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    })
                );
            });
            axios.all([...file_request]).then(axios.spread((...responses) => {
                let tmp_doc = [...trip_docs];
                responses.map((response) => {
                    tmp_doc[tmp_doc.findIndex((doc) => doc.id === response.data.id)] = response.data;
                });
                dispatch(SetTripDocs(tmp_doc));
                // setDisplayVoucherAll(!displayVoucherAll);
            })).catch(function (error) {
                console.log(error);
            });
        }
    };
    const handleDisplayManualVoucherAll = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let all_voucher = trip_docs.filter((doc) => doc.type === "VOUCHER_FLIGHT" || doc.type === "CUSTOM_VOUCHER");
        let file_request = [];
        if (pass_check) {
            let request = {
                display_voucher: !displayManualVoucherAll
            };
            all_voucher.map((doc) => {
                file_request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${doc.id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    })
                );
            });
            axios.all([...file_request]).then(axios.spread((...responses) => {
                let tmp_doc = [...trip_docs];
                responses.map((response) => {
                    tmp_doc[tmp_doc.findIndex((doc) => doc.id === response.data.id)] = response.data;
                });
                dispatch(SetTripDocs(tmp_doc));
                // setDisplayManualVoucherAll(!displayManualVoucherAll);
            })).catch(function (error) {
                console.log(error);
            });
        }
    };
    const handleDisplayMinisiteAll = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let all_voucher = trip_docs.filter((doc) => doc.type === "VOUCHER" || doc.type === "VOUCHER_HOTELS");
        let file_request = [];
        if (pass_check) {
            let request = {
                display_minisite: !displayMiniSiteAll
            };
            all_voucher.map((doc) => {
                file_request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${doc.id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    })
                );
            });
            axios.all([...file_request]).then(axios.spread((...responses) => {
                let tmp_doc = [...trip_docs];
                responses.map((response) => {
                    tmp_doc[tmp_doc.findIndex((doc) => doc.id === response.data.id)] = response.data;
                });
                dispatch(SetTripDocs(tmp_doc));
                // setDisplayMiniSiteAll(!displayMiniSiteAll);
            })).catch(function (error) {
                console.log(error);
            });
        }
    };
    const handleDisplayManualMinisiteAll = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let all_voucher = trip_docs.filter((doc) => doc.type === "VOUCHER_FLIGHT" || doc.type === "CUSTOM_VOUCHER");
        let file_request = [];
        if (pass_check) {
            let request = {
                display_minisite: !displayManualMiniSiteAll
            };
            all_voucher.map((doc) => {
                file_request.push(
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/file/${doc.id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    })
                );
            });
            axios.all([...file_request]).then(axios.spread((...responses) => {
                let tmp_doc = [...trip_docs];
                responses.map((response) => {
                    tmp_doc[tmp_doc.findIndex((doc) => doc.id === response.data.id)] = response.data;
                });
                dispatch(SetTripDocs(tmp_doc));
                // setDisplayManualMiniSiteAll(!displayManualMiniSiteAll);
            })).catch(function (error) {
                console.log(error);
            });
        }
    };
    return (
        <Grid style={{ padding: "20px 0px" }}>
            <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize24 : classes.fontSize20} ${classes.upperCase} ${classes.letterSpacing}`}>
                { t("menu.trip_list.documents") }
            </div>
            <FormControl size="small" className={ classes.selector } variant={ 'outlined' } style={{width:'250px'}}>
                <InputLabel id="voucher-language-selector">
                    { t("menu.trip_list.voucher-language") }
                </InputLabel>
                <Select labelId="voucher-language-selector" label={ t("menu.trip_list.voucher-language") } value={ voucherLocale } onChange={ onVoucherLocaleChange }>
                    { locales.map(locale => <MenuItem key={ `quotation-target-language-${locale.language_code}` } value={ locale.language_code }>{ locale.full_name }</MenuItem>) }
                </Select>
            </FormControl>
            <Grid container alignItems="center" justify="flex-end">
                <Grid item>
                    <Tooltip title={t("menu.trip_list.refresh_doc_retrieval")}>
                        <IconButton size="small" className={classes.colorBlue} onClick={() => {
                            createAllDocs();
                            refreshDocs();
                        }}>
                            <SyncOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip title={t("menu.trip_list.download_all_docs")}>
                        <IconButton size="small" className={classes.colorBlue} onClick={downloadAllDocs}>
                            <SystemUpdateAltIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid>
                <Alert severity={ 'info' }>
                    { t('menu.trip_list.doc_format_information_identity') }
                </Alert>
            </Grid>
            {
                (['cercledesvoyages', 'continentsinsolites', 'verdie', 'americavoyages', 'connections', 'terreslointaines', 'tropicalementvotre', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code)) && travelers.length !== 0 && (
                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                        <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("menu.trip_list.identity") }
                        </Grid>
                        {
                            displayIDCard && (
                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                        { t("menu.trip_list.id_cards") }
                                    </div>
                                    <TableContainer>
                                        <Table id={'id-cards'} padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={`${classes.borderTableCell}`}/>
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        {t('menu.trip_list.upload_date')}
                                                    </TableCell>
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        { !mobile ? t("menu.trip_list.requested_doc") : t("global.requested")}
                                                    </TableCell>
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    trip_docs.filter(item => item.type === "CARD").map((doc) => {
                                                        if (doc.type === "CARD") {
                                                            let traveler = travelers.find(item => item.id === doc.traveler);
                                                            if (traveler !== undefined) {
                                                                return (
                                                                    <DocumentActionBar key={`id-card-${doc.traveler}`} mode={"traveler"} traveler={traveler} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                                );
                                                            }
                                                        }
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )
                        }
                        {
                            displayPassport && (
                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                        { t("menu.trip_list.passports") }
                                    </div>
                                    <TableContainer>
                                        <Table id={'passports'} padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={`${classes.borderTableCell}`}/>
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        {t('menu.trip_list.upload_date')}
                                                    </TableCell>
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        { !mobile ? t("menu.trip_list.requested_doc") : t("global.requested")}
                                                    </TableCell>
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    trip_docs.filter(item => item.type === "PASSPORT").map((doc) => {
                                                        let traveler = travelers.find(item => item.id === doc.traveler);
                                                        if (traveler !== undefined) {
                                                            return (
                                                                <DocumentActionBar key={`passport-${doc.traveler}`} mode={"traveler"} traveler={traveler} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                            );
                                                        }
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )
                        }
                        <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                            <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                e-Visa
                            </div>
                            <TableContainer>
                                <Table id={'e-Visa'} padding="none">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={`${classes.borderTableCell}`}/>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                {t('menu.trip_list.upload_date')}
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                { !mobile ? t("menu.trip_list.requested_doc") : t("global.requested")}
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            trip_docs.filter(item => item.type === "E-VISA").map((doc) => {
                                                let traveler = travelers.find(item => item.id === doc.traveler);
                                                if (traveler !== undefined) {
                                                    return (
                                                        <DocumentActionBar key={`e-visa-${doc.traveler}`} mode={"traveler"} traveler={traveler} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                    );
                                                }
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )
            }
            {
                (quotation_code !== 'visiteurs' || user.client_full.type !== 2) && (flightVoucher.length !== 0 || carVoucher.length !== 0 || roomVoucher.length !== 0 || activityVoucher.length !== 0 || transferVoucher.length !== 0) && (
                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                        <Grid container spacing={ 8 }>
                            <Grid item sm={7} xs={12} className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter}  ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                                { t("menu.trip_list.vouchers") }
                            </Grid>
                            <Grid item container sm={5} xs={12} className={`${classes.paddingLeft60}`} justify={mobile ? 'space-between' : 'flex-start'}>
                                    <Grid item container direction={'column'} className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                    {
                                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                        <>
                                            <Grid item>{ t('menu.trip_list.display_voucher') }</Grid>
                                            <Grid item>
                                                <Switch color={"primary"} checked={displayVoucherAll} onChange={handleDisplayVoucherAll}/>
                                            </Grid>
                                        </>
                                    }
                                    </Grid>
                                    <Grid item container direction={'column'} className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                    {
                                        ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                        <>
                                            <Grid item>{ t('menu.trip_list.traveler_website') }</Grid>
                                            <Grid item>
                                                <Switch color={"primary"} checked={displayMiniSiteAll} onChange={handleDisplayMinisiteAll}/>
                                            </Grid>
                                        </>
                                    }
                                    </Grid>
                                <Grid item>
                                    <Tooltip title={t("menu.trip_list.update_docs")}>
                                        <span style={{ verticalAlign: 'center' }}>
                                            <IconButton size="small" className={classes.colorBlue} onClick={refreshAllVoucher}>
                                                { refreshSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <RefreshOutlinedIcon /> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={t("menu.trip_list.download_all_vouchers")}>
                                        <span style={{ verticalAlign: 'center' }}>
                                            <IconButton size="small" className={classes.colorBlue} onClick={downloadAllVoucher}>
                                                { downloadSpinner ? <CircularProgress size={20} className={classes.colorBlue} /> : <Download /> }
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            flightVoucher.length !== 0 && (
                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                        { t("global.flights") }
                                    </div>
                                    <TableContainer>
                                        <Table id={'flight'} padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.borderTableCell} />
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        {t('menu.trip_list.upload_date')}
                                                    </TableCell>
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.display_voucher') }
                                                        </TableCell>
                                                    }
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.traveler_website') }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    flightVoucher.map((voucher, voucher_index) => {
                                                        return (
                                                            <Fragment key={`voucher-transfer-${voucher_index}`}>
                                                                {
                                                                    trip_docs.map((doc) => {
                                                                        if (doc.type === "VOUCHER" && doc.product === voucher.product_ids[0]) {
                                                                            return (
                                                                                <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )
                        }
                        {
                            carVoucher.length !== 0 && (
                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                        { t("global.cars") }
                                    </div>
                                    <TableContainer>
                                        <Table id={'cars'} padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.borderTableCell} />
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        {t('menu.trip_list.upload_date')}
                                                    </TableCell>
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.display_voucher') }
                                                        </TableCell>
                                                    }
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.traveler_website') }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    carVoucher.map((voucher, voucher_index) => {
                                                        return (
                                                            <Fragment key={`voucher-transfer-${voucher_index}`}>
                                                                {
                                                                    trip_docs.map((doc) => {
                                                                        if (doc.type === "VOUCHER" && doc.product === voucher.product_ids[0]) {
                                                                            return (
                                                                                <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {
                                        // carVoucher.map((voucher, voucher_index) => {
                                        //     let product_type = GetProductType(voucher.product_type);
                                        //     return (
                                        //         <Grid key={`voucher-car-${voucher_index}`}>
                                        //             {
                                        //                 voucher_index === 0 && (
                                        //                     <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                        //                         { t("global.cars") }
                                        //                     </div>
                                        //                 )
                                        //             }
                                        //             <Grid container alignItems="center" justify="space-between">
                                        //                 <Grid item xs={!mobile ? false : 9} className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                        //                     {
                                        //                         !mobile && (
                                        //                             <div>
                                        //                                 { t("global.voucher") + " " + t("providers." + product_type) + " : " + voucher.name }
                                        //                             </div>
                                        //                         )
                                        //                     }
                                        //                     {
                                        //                         mobile && (
                                        //                             <div>
                                        //                                 { voucher.name }
                                        //                             </div>
                                        //                         )
                                        //                     }
                                        //                 </Grid>
                                        //                 <Grid item xs={!mobile ? false : 3}>
                                        //                     {
                                        //                         trip_docs.map((doc) => {
                                        //                             if (doc.type === "VOUCHER" && doc.product === voucher.product_ids[0]) {
                                        //                                 return (
                                        //                                     <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate}/>
                                        //                                 );
                                        //                             }
                                        //                         })
                                        //                     }
                                        //                 </Grid>
                                        //             </Grid>
                                        //         </Grid>
                                        //     );
                                        // })
                                    }
                                </Grid>
                            )
                        }
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Warning sx={{color: "orange"}}/>
                            <Typography className={`${classes.genericText}`} sx={{ fontWeight: '700 !important'}}>{t('menu.trip_list.voucher_hotel_notice')}</Typography>
                        </Stack>
                        {
                            roomVoucher.length !== 0 && (
                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12} ${classes.capitalize}`}>
                                        { t("accommodation.hotels") }
                                    </div>
                                    <TableContainer>
                                        <Table id={'hotels'} padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.borderTableCell} />
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        {t('menu.trip_list.upload_date')}
                                                    </TableCell>
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.display_voucher') }
                                                        </TableCell>
                                                    }
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.traveler_website') }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    roomVoucher.map((voucher, voucher_index) => {
                                                        let manual_voucher = trip_docs.filter(doc => doc.type === "CUSTOM_VOUCHER");
                                                        return (
                                                            <Fragment key={`voucher-hotel-${voucher_index}`}>
                                                                {
                                                                    voucher_index === 0 && (
                                                                        <Fragment>
                                                                            {
                                                                                trip_docs.map((doc) => {
                                                                                    if (doc.type === "VOUCHER_HOTELS") {
                                                                                        let ids = [];
                                                                                        roomVoucher.map((voucher) => {
                                                                                            voucher.product_ids.map(id => {
                                                                                                if (manual_voucher.length === 0 || (manual_voucher.find(manual => manual.product === id) === undefined)) {
                                                                                                    ids.push(id)
                                                                                                }
                                                                                            });
                                                                                        });
                                                                                        if (ids.length > 0) {
                                                                                        return (
                                                                                            <DocumentActionBar key={`voucher-hotels-${doc.product}`} mode={"voucher_hotels"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate} ids={ ids } voucherLocale={ voucherLocale }/>
                                                                                        );
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                    )
                                                                }
                                                                {
                                                                    trip_docs.map((doc) => {
                                                                        if (doc.type === "VOUCHER" && doc.product === voucher.product_ids[0]) {
                                                                            return (
                                                                                <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )
                        }
                        {
                            activityVoucher.length !== 0 && (
                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                        { t("cart.activities") }
                                    </div>
                                    <TableContainer>
                                        <Table id={'activities'} padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.borderTableCell} />
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        {t('menu.trip_list.upload_date')}
                                                    </TableCell>
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.display_voucher') }
                                                        </TableCell>
                                                    }
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.traveler_website') }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    activityVoucher.map((voucher, voucher_index) => {
                                                        return (
                                                            <Fragment key={`voucher-activity-${voucher_index}`}>
                                                                {
                                                                    trip_docs.map((doc) => {
                                                                        if (doc.type === "VOUCHER" && doc.product === voucher.product_ids[0]) {
                                                                            return (
                                                                                <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )
                        }
                        {
                            transferVoucher.length !== 0 && (
                                <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                                    <div className={`${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${!mobile ? classes.fontSize16 : classes.fontSize12}`}>
                                        { t("accommodation.transfers") }
                                    </div>
                                    <TableContainer>
                                        <Table id={'transfers'} padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.borderTableCell} />
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        {t('menu.trip_list.upload_date')}
                                                    </TableCell>
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.display_voucher') }
                                                        </TableCell>
                                                    }
                                                    {
                                                        ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.traveler_website') }
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    transferVoucher.map((voucher, voucher_index) => {
                                                        return (
                                                            <Fragment key={`voucher-transfer-${voucher_index}`}>
                                                                {
                                                                    trip_docs.map((doc) => {
                                                                        if (doc.type === "VOUCHER" && doc.product === voucher.product_ids[0]) {
                                                                            return (
                                                                                <DocumentActionBar key={`voucher-${doc.product}`} mode={"voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={voucher} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            )
                        }
                    </Grid>
                )
            }
            {
                (quotation_code !== 'visiteurs' || user.client_full.type !== 2) && (
                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                        <Grid container spacing={ 8 }>
                            <Grid item sm={7} xs={12} className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter}  ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                                { t("menu.trip_list.manual_vouchers") }
                            </Grid>
                            <Grid item container sm={5} xs={12} className={`${classes.paddingLeft60}`} justify={mobile ? 'space-between' : 'flex-start'}>
                                {
                                    ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                        <Grid item container direction={'column'} className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                            <Grid item>{ t('menu.trip_list.display_voucher') }</Grid>
                                            <Grid item>
                                                <Switch color={"primary"} checked={displayManualVoucherAll} onChange={handleDisplayManualVoucherAll}/>
                                            </Grid>
                                        </Grid>
                                }
                                {
                                    ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                        <Grid item container direction={'column'} className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                            <Grid item>{ t('menu.trip_list.traveler_website') }</Grid>
                                            <Grid item>
                                                <Switch color={"primary"} checked={displayManualMiniSiteAll} onChange={handleDisplayManualMinisiteAll}/>
                                            </Grid>
                                        </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${!mobile ? classes.indentation : ""} ${classes.genericBorder}`}>
                            <TableContainer>
                                <Table id={'voucher-flight'} padding="none">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.borderTableCell} />
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                {t('menu.trip_list.upload_date')}
                                            </TableCell>
                                            {
                                                ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.display_voucher') }
                                                        </TableCell>
                                            }
                                            {
                                                ['cercledesvoyages', 'continentsinsolites', 'americavoyages', 'tropicalementvotre', 'connections', 'terreslointaines', 'touristforum', 'gattinoni', 'travelmuz'].includes(quotation_code) &&
                                                        <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                            { t('menu.trip_list.traveler_website') }
                                                        </TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            trip_docs.map((doc) => {
                                                if (doc.type === "VOUCHER_FLIGHT") {
                                                    let traveler = null;
                                                    for (traveler_index = 0; traveler_index < travelers.length; traveler_index++) {
                                                        if (travelers[traveler_index].id === doc.traveler) {
                                                            traveler = travelers[traveler_index];
                                                        }
                                                    }
                                                    if (traveler !== null) {
                                                        return (
                                                            <DocumentActionBar key={`voucher-flight-${doc.traveler}`} mode={"manual_voucher"} traveler={traveler} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                        );
                                                    }
                                                }
                                                // if (doc.type === "OTHER") {
                                                //     return (
                                                //         <DocumentActionBar key={`voucher-${doc.product}`} mode={"custom_file"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate}/>
                                                //     );
                                                // }
                                                if (doc.type === "CUSTOM_VOUCHER") {
                                                    return (
                                                        <DocumentActionBar key={`voucher-${doc.product}`} mode={"custom_voucher"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                    );
                                                }
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid container alignItems={ "center" } justify={ "space-between" } direction={ "row-reverse" }>
                            <Grid item>
                                <Fragment>
                                    {/* <input hidden ref={uploadVoucherInputRef} type="file" onChange={uploadVoucher}/> */}
                                    <Button startIcon={<Add />} color={ "primary" } size={ "small" } component={ "label" } onClick={() => setModalUploadVoucher(!modalUploadVoucher)}>
                                        {t("menu.trip_list.add_voucher")}
                                    </Button>
                                </Fragment>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {
                ['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code) && trip_docs.find((doc) => doc.type === "INSURANCE_BOOKLET") !== undefined && (
                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                        <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("menu.trip_list.insurance_booklet") }
                        </Grid>
                        <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${classes.genericBorder}`}>
                            <Grid container alignItems="center" justify="space-between">
                                <Grid item className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                    { t("menu.trip_list.insurance_booklet") }
                                </Grid>
                                <Grid item>
                                    {
                                        trip_docs.map((doc) => {
                                            if (doc.type === "INSURANCE_BOOKLET") {
                                                return (
                                                    <DocumentActionBar key={`insurance-booklet-${doc.id}`} mode={"condition"} traveler={null} label={t("menu.trip_list.insurance_booklet")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                );
                                            }
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {
                ['cercledesvoyages'].includes(quotation_code) && trip_docs.find((doc) => doc.type === "TRAVELBOOK") !== undefined && (
                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                        <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("menu.trip_list.my_roadbook") }
                        </Grid>
                        <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${classes.genericBorder}`}>
                            <TableContainer>
                                <Table id={'travelbook'} padding="none">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.borderTableCell} />
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                {t('menu.trip_list.upload_date')}
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                { t("menu.trip_list.sent_to_printer") }
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                { t("menu.trip_list.printed")}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            trip_docs.map((doc) => {
                                                if (doc.type === "TRAVELBOOK") {
                                                    return (
                                                        <DocumentActionBar key={`travelbook-${doc.id}`} mode={"row"} traveler={null} label={t("menu.trip_list.travelbook")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                    );
                                                }
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TripListRoadbookAddButton />
                        </Grid>
                    </Grid>
                )
            }
            {
                ['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code) && trip_docs.find((doc) => doc.type === "CONDITIONS") !== undefined && (
                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                        <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("accommodation.terms_n_condition") }
                        </Grid>
                        <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${classes.genericBorder}`}>
                            <Grid container alignItems="center" justify="space-between">
                                <Grid item className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                    { t("accommodation.terms_n_condition") }
                                </Grid>
                                <Grid item>
                                    {
                                        trip_docs.map((doc) => {
                                            if (doc.type === "CONDITIONS") {
                                                return (
                                                    <DocumentActionBar key={`condition-${doc.id}`} mode={"condition"} traveler={null} label={t("accommodation.terms_n_condition")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                );
                                            }
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {
                trip_docs.find((doc) => doc.type === "CUSTOM_FILE") !== undefined && (
                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                        <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                            {/*{ t("menu.user_management.trip") } */}
                        </Grid>
                        <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${classes.genericBorder}`}>
                            <TableContainer>
                                <Table id={'custom-file'} padding="none">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.borderTableCell}/>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                {t('menu.trip_list.upload_date')}
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                { !mobile ? t("menu.trip_list.requested_doc") : t("global.requested")}
                                            </TableCell>
                                            <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            trip_docs.map((doc) => {
                                                if (doc.type === "CUSTOM_FILE") {
                                                    return (
                                                        <DocumentActionBar key={`custom-file-${doc.id}`} mode={"custom_file"} traveler={null} label={""} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                    );
                                                }
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )
            }
            {
                quotation_code !== "marcovasco" && (
                    <Grid className={`${classes.genericBorder} ${!mobile ? classes.genericPadding : classes.genericPaddingMobile} ${!mobile ? classes.spaceTop : classes.spaceTopMobile}`}>
                        <Grid className={`${!mobile ? classes.spaceTop : classes.spaceTopMobile} ${!mobile ? "" : classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize20 : classes.fontSize16} ${classes.upperCase} ${classes.letterSpacing}`}>
                            { t("menu.user_management.trip") }
                        </Grid>
                        <Grid className={`${classes.categoryPadding} ${!mobile ? classes.spacer : classes.spacerMobile} ${classes.genericBorder}`}>
                            {
                                ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum', 'travelmuz'].includes(quotation_code) && trip_info.data.map((data, index) => {
                                    let label = `${t("menu.trip_list.quotation_title")}${trip_info.trip_reference} ${t("menu.trip_list.nb_version")} (${index + 1})`;
                                    if (data.status_contract_confirmed_date !== null && data.status_modification !== "AMENDMENT") {
                                        return (
                                            <Grid container alignItems="center" justify="space-between" key={data.id}>
                                                <Grid item className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                    { t("menu.trip_list.quotation_title") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + (index + 1) }
                                                    {
                                                        data.status_contract === "CONFIRMED" && data.status_modification === "FIXED_PV" && (
                                                            ` (${t("menu.trip_list.BOOKED")})`
                                                        )
                                                    }
                                                    {
                                                        data.status_contract === "CONFIRMED" && data.status_modification === "AMENDMENT" && (
                                                            ` (${t("menu.trip_list.AMENDMENT")})`
                                                        )
                                                    }
                                                    {
                                                        data.status_contract === "CLOSED" && (
                                                            ` (${t("menu.trip_list.CLOSED")})`
                                                        )
                                                    }
                                                    {" - " + moment(data.status_contract_confirmed_date).format("L")}
                                                </Grid>
                                                <Grid item>
                                                    {
                                                        trip_docs.map((doc) => {
                                                            if (doc.type === "QUOTATION" && data.id === doc.trip_version) {
                                                                return (
                                                                    <DocumentActionBar key={`quotation-${doc.id}`} mode={"quotation"} traveler={null} label={label} documentObject={doc} trip_displayed_version={data.id} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                                );
                                                            }
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                })
                            }
                            {
                                !['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'travelmuz'].includes(quotation_code) && (
                                    <Grid container alignItems="center" justify="space-between">
                                        <Grid item className={`${!mobile ? classes.indentation : ""} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                            { t("menu.trip_list.quotation_title") + trip_info.trip_reference + " " + t("menu.trip_list.nb_version") + getIndexNbForVersion(trip_info.data, trip_displayed_version) }
                                        </Grid>
                                        <Grid item>
                                            {
                                                trip_docs.map((doc) => {
                                                    if (doc.type === "QUOTATION") {
                                                        let label = `${t("menu.trip_list.quotation_title")}${trip_info.trip_reference} ${t("menu.trip_list.nb_version")}${getIndexNbForVersion(trip_info.data, trip_displayed_version)}`
                                                        return (
                                                            <DocumentActionBar key={`quotation-${doc.id}`} mode={"quotation"} traveler={null} label={label} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                        );
                                                    }
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                                (quotation_code === "verdie" || quotation_code === "continentsinsolites" || quotation_code === "touristforum") && (
                                    <TableContainer>
                                        <Table id={'other_docs'} padding="none">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.borderTableCell} />
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        { !mobile ? t("menu.trip_list.requested_doc") : t("global.requested")}
                                                    </TableCell>
                                                    <TableCell className={`${classes.borderTableCell} ${!mobile ? classes.checkboxCellWidth : ""} ${classes.textCenter} ${classes.genericText} ${!mobile ? classes.fontSize12 : classes.fontSize10} ${!mobile ? classes.fontWeight700 : classes.fontWeight400}`}>
                                                        { !mobile ? t("menu.trip_list.received_doc") : t("global.received")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    trip_docs.map((doc) => {
                                                        if (doc.type === "CONDITIONS") {
                                                            return (
                                                                <DocumentActionBar key={`conditions-${doc.id}`} mode={"row"} traveler={null} label={t("menu.trip_list.conditions")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                            );
                                                        }
                                                    })
                                                }
                                                {
                                                    trip_docs.map((doc) => {
                                                        if (doc.type === "TRAVELBOOK") {
                                                            return (
                                                                <DocumentActionBar key={`travelbook-${doc.id}`} mode={"row"} traveler={null} label={t("menu.trip_list.travelbook")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                            );
                                                        }
                                                    })
                                                }
                                                {
                                                    trip_docs.map((doc) => {
                                                        if (doc.type === "HOTELCARD") {
                                                            let accommodation = null;
                                                            for (room_index = 0; room_index < accommodation_cart.length; room_index++) {
                                                                if (accommodation_cart[room_index].booking_status !== null && accommodation_cart[room_index].booking_status.status_booking === "CONFIRMED" && doc.hotel === accommodation_cart[room_index].hotel[0].id) {
                                                                    accommodation = accommodation_cart[room_index];
                                                                }
                                                            }
                                                            if (accommodation !== null) {
                                                                return (
                                                                    <DocumentActionBar key={`hotelcard-${doc.hotel}`} mode={"row"} traveler={null} label={t("menu.trip_list.hotelcard") + " " + accommodation.hotel[0].name} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                                );
                                                            }
                                                        }
                                                    })
                                                }
                                                {
                                                    trip_docs.map((doc) => {
                                                        if (doc.type === "CONTRACT") {
                                                            return (
                                                                <DocumentActionBar key={`contract-${doc.id}`} mode={"row"} traveler={null} label={t("menu.trip_list.contract")} documentObject={doc} trip_displayed_version={trip_displayed_version} voucher={null} update={update} setUpdate={setUpdate} voucherLocale={ voucherLocale }/>
                                                            );
                                                        }
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            }
                        </Grid>
                    </Grid>
                )
            }
            <TripListOtherDocuments trip_displayed_version={trip_displayed_version}/>
            <TripListUploadVoucher trip_displayed_version={trip_displayed_version} modalUploadVoucher={modalUploadVoucher} setModalUploadVoucher={setModalUploadVoucher} allVoucher={allVoucher}/>
        </Grid>
    );
});

export default React.memo(TripListDocument);
