import { createMuiTheme } from "@material-ui/core";
import { createTheme as createThemeV5 } from '@mui/material/styles';
import { createGenerateClassName } from '@material-ui/core/styles';

export const generateClassName = createGenerateClassName({
    //By enabling this option, if you have non-MUI elements (e.g. `<div />`)
    //using MUI classes (e.g. `.mui-jss-MuiButton`) they will lose styles.
    //Make sure to convert them to use `styled()` or `<Box />` first.
    /*disableGlobal: true, */
    //Class names will receive this seed to avoid name collisions.
    seed: 'mui-jss'
});

export const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    }
});

export const themeV5 = createThemeV5({
    palette: {
        secondary: {
            main: '#5C9BF0'
        }
    }
});
