import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DateTimePicker, LocalizationProvider } from "@material-ui/pickers";

import i18n from "../../../i18n.jsx";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import { SetTripInfo, SetTripInfoVersion, SetTripList, ToShowVersion, toggleMCTOModal, setMCTOData, SetArrayTab } from "../../../Actions/Menu";
import { SetTripData } from "../../../Actions/Trip";

import GetStatusColorHexa from "./Functions/GetStatusColorHexa";
import MCTOModal from "./MCTOModal.js";
import TripListMobileInfo from "./TripListMobileInfo.js";

import "../../../Style/Menu/TripList.css";

import axios from "axios";
import clsx from "clsx";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    dueDatePast: {
        "&& .mui-jss-MuiInput-input": {
            color: "red",
            marginLeft: -10,
            textAlign: "right",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: 10,
            letterSpacing: 1.25,
            fontWeight: 400
        }
    },
    dueDateFuture: {
        "&& .mui-jss-MuiInput-input": {
            color: "#71DD13",
            marginLeft: -10,
            textAlign: "right",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: 10,
            letterSpacing: 1.25,
            fontWeight: 400
        }
    },
    genericBorder: {
        background: "#FFFFFF",
        border: "1px solid #DDDDDD",
        boxSizing: "border-box"
    },
    mctoBorder: {
        background: "#FFFFFF",
        border: "0.772358px solid #E6592F",
        boxSizing: "border-box",
        borderRadius: 23
    },
    iconBorder: {
        paddingRight: 4,
        marginLeft: 8,
        borderRadius: 20
    },
    checkBorder: {
        border: "1px solid #71DB14"
    },
    editBorder: {
        border: "1px solid #808080"
    },
    cancelBorder: {
        border: "1px solid #FF0000"
    },
    smallIcon: {
        width: 16,
        height: 16
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    upperCase: {
        textTransform: "uppercase"
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontSize10: {
        fontSize: 10
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 12
    },
    fontSize16: {
        fontSize: 16
    },
    fontSize20: {
        fontSize: 20
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    colorBlue: {
        color: "#2C98F0"
    },
    colorGrey: {
        color: "#808080"
    },
    colorOrange: {
        color: "#E6592F"
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    itemsCenter: {
        alignItems: "center"
    },
    selectBorder: {
        borderRadius: 30
    },
    datesFormat: {
        fontSize: 10,
        fontWeight: 500,
        fontStyle: "italic"
    },
    paddingTop: {
        paddingTop: "2.5%"
    },
    paddingLeft: {
        paddingLeft: 70
    },
    marginLeft: {
        marginLeft: 39
    },
    editableFields: {
        maxWidth: "80%"
    }
}));


const TripListInfoMobileHeader = withRouter(({ router, contact_lead, setContactLead }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const trip_info = useSelector(store => store.menu.trip_info);
    const trip_list = useSelector(store => store.menu.trip_list);
    const start_date = useSelector(store => store.trip.start_date);
    const end_date = useSelector(store => store.trip.end_date);
    const travelers = useSelector(store => store.trip.travelers);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const trip_docs = useSelector(store => store.menu.trip_docs);
    const user = useSelector(store => store.user.user);
    const itinerary_steps = useSelector(store => store.itinerary.itinerary_list);
    const accommodation_cart = useSelector(store => store.accommodation.cart);
    const car_cart = useSelector(store => store.cars_search.cart);
    const flight_cart = useSelector(store => store.flight.cart);
    const transfer_cart = useSelector(store => store.transfers.cart);
    const poi_cart = useSelector(store => store.poi.cart);
    const manual_cart = useSelector(store => store.cart.manual_item_list);
    const applicant = useSelector(store => store.trip.contact_traveler_detail);

    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const [trip_status, setTripStatus] = useState("ONGOING");
    const [status_choices, setStatusChoices] = useState([]);
    const [trip_name, setTripName] = useState("");
    const [all_versions, setAllVersions] = useState([]);
    const [edit_trip_name, setEditTripName] = useState(false);
    const [trip_displayed_version, setVersion] = useState(to_show_version !== null ? to_show_version : trip_info.current_version);
    const [displayed_version_num, setVersionNum] = useState(0);
    const [displayed_version_name, setVersionName] = useState("");
    const [destination, setDestination] = useState(null);
    const [edit_desti, setEditDesti] = useState(false);
    const [desti_input, setDestiInput] = useState("");
    const [desti_results, setDestiResults] = useState([]);
    const [due_date, setDueDate] = useState(new Date());
    const [nights, setNights] = useState(0);
    const [is_current, setIsCurrent] = useState(false);
    const [current_version_id, setCurrentId] = useState(trip_info.current_version); 
    const [trip_completion, setCompletion] = useState(0);
    //number of [adults, teens, children, babies]
    const [travelers_ages, setTravelersAge] = useState([0, 0, 0, 0]);
    const [is_traveler_default, setIsTravelerDefault] = useState(true);
    const [rooms, setRooms] = useState(0);
    const [openUserInfo, setOpenUserInfo] = useState(false);
    const [cercleStatus, setCercleStatus] = useState([{ group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" }]);

    moment.locale(i18n.language);

    const days = moment.utc(moment.utc(end_date).format("YYYY-MM-DD")).diff(moment.utc(moment.utc(start_date).format("YYYY-MM-DD")), 'd') + 1;

    useEffect(() => {
        setTripName(trip_info.name);
        let sort_versions = trip_info.data.slice();
        sort_versions.sort((a, b) => {
            return a.id - b.id;
        });
        setAllVersions(sort_versions);
    }, []);

    useEffect(() => {
        let nb_adults = 0;
        let nb_teens = 0;
        let nb_children = 0;
        let nb_babies = 0;
        for (traveler_index = 0; traveler_index < travelers.length; traveler_index++) {
            first_name = travelers[traveler_index].first_name;
            last_name = travelers[traveler_index].last_name;
            age = moment.utc(end_date).diff(moment.utc(travelers[traveler_index].birth_date), "y");
            if (age > 12 && age < 18) {
                nb_teens += 1;
            } else if (age > 2 && age <= 12) {
                nb_children += 1;
            } else if (age <= 2) {
                nb_babies += 1;
            } else if (age >= 18) {
                nb_adults += 1;
            }
            if ((first_name !== t("global.first_name") && first_name !== t("trip.traveler")) || (last_name !== t("global.last_name") && !last_name.includes(t("global.adult")) && !last_name.includes(t("global.child")))) {
                setIsTravelerDefault(false);
            }
        }
        setTravelersAge([nb_adults, nb_teens, nb_children, nb_babies]);
    }, [travelers]);

    useEffect(() => {
        setIsCurrent(trip_displayed_version === trip_info.current_version);

        for (version_index = 0; version_index < trip_info.data.length; version_index++) {
            if (trip_info.data[version_index].id === trip_displayed_version) {
                let new_version_index = version_index + 1;
                setVersionNum(new_version_index);

                let displayed_version = trip_info.data[version_index];
                setDestination(displayed_version.destination);
                setDestiResults([displayed_version.destination]);

                if (displayed_version.name !== null) {
                    setVersionName(displayed_version.name);
                }

                if (displayed_version.contact_traveler_detail !== null && contact_lead === null) {
                    setContactLead(displayed_version.contact_traveler_detail);
                }

                if (!['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code)) {
                    setTripStatus(displayed_version.status);
                } else {
                    console.log('displayed_version:', displayed_version);
                    let status = [];
                    if (displayed_version.status_contract !== undefined && displayed_version.status_contract !== null) {
                        status.push({ group: "TRIP_STATUS_CONTRACT", label: displayed_version.status_contract });
                    }
                    if (displayed_version.status_contract === "CONFIRMED" && displayed_version.status_modification !== undefined && displayed_version.status_modification !== null) {
                        status.push({ group: "TRIP_STATUS_MODIFICATION", label: displayed_version.status_modification });
                    }
                    if (displayed_version.status_contract === "CONFIRMED" && displayed_version.status_modification === "FIXED_PV" && displayed_version.status_booking_progress !== undefined && displayed_version.status_booking_progress !== null) {
                        status.push({ group: "TRIP_STATUS_BOOKING_PROGRESS", label: displayed_version.status_booking_progress });
                    }
                    if (status.length !== 0) {
                        setCercleStatus(status);
                    }
                }
                if (displayed_version.due_date !== null) {
                    setDueDate(displayed_version.due_date);
                } else {
                    let new_date = moment(displayed_version.created_date).add(3, 'd').format();
                    if (moment(new_date).day() === 0) {
                        new_date = moment(new_date).add(1, "d").format();
                    }
                    setDueDate(new Date(new_date));
                    let { pass_check, headers } = CheckBeforeRequest();
                    let request = {
                        due_date: new_date
                    };
                    if (pass_check) {
                        axios({
                            method: "PATCH",
                            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                            headers: headers,
                            data: JSON.stringify(request)
                        }).then(function (response) {
                            for (let i = 0; i < response.data.prices.length; i++) {
                                if (response.data.prices[i].selling_price === undefined) {
                                    response.data.prices.splice(i, 1);
                                    i--;
                                }
                            }
                            for (let i = 0; i < response.data.prices_flight.length; i++) {
                                if (response.data.prices_flight[i].selling_price === undefined) {
                                    response.data.prices_flight.splice(i, 1);
                                    i--;
                                }
                            }
                            for (let i = 0; i < response.data.prices_stack_product.length; i++) {
                                if (response.data.prices_stack_product[i].selling_price === undefined) {
                                    response.data.prices_stack_product.splice(i, 1);
                                    i--;
                                }
                            }
                            for (let i = 0; i < response.data.prices_terrestrial.length; i++) {
                                if (response.data.prices_terrestrial[i].selling_price === undefined) {
                                    response.data.prices_terrestrial.splice(i, 1);
                                    i--;
                                }
                            }
                            dispatch(SetTripInfoVersion(response.data));
                            dispatch(SetTripData(response.data, response.data.travelers, (response.data.budget === null ? 0 : parseInt(response.data.budget)), response.data.trip.currency, response.data.contact_traveler, response.data.contact_traveler_detail, response.data.trip.trip_reference));
                        }).catch(function (error) {
                            console.log(error.responseJSON);
                        });
                    }
                }

                let temp_status_choices = [];
                if (quotation_code === "verdie"){
                    temp_status_choices = ["ONGOING", "TOBOOK_VERDIE", "AGENCY_MOD", "ADDITEM", "ONREQUEST", "BOOKED", "CLOSED", "CANCELLED", "ARCHIVED"]
                }else if(quotation_code === "visiteurs"){
                    temp_status_choices = ["ONGOING", "VALIDATED", "USER_VALID", "TOBOOK", "ADDITEM", "FLIGHT_OPTION", "ONREQUEST", "BOOKED", "CLOSED", "CANCELLED"]
                } else if (quotation_code === "beachcomber") {
                    temp_status_choices = ["ONGOING", "OPTION_OK", "BOOKED"];
                }else if(quotation_code === "marcovasco" || quotation_code === "americavoyages" || quotation_code === "tripadekua"){
                    temp_status_choices = ["ONGOING", "VALIDATED", "ADDITEM", "ONREQUEST", "BOOKED", "CLOSED", "CANCELLED"]
                } else if (quotation_code === "volonline" || quotation_code === "gattinoni") {
                    temp_status_choices = ["ONGOING", "VALIDATED", "USER_VALID", "ADDITEM", "TOBOOK", "BOOKED", "CANCELLED"]
                }else if(['cercledesvoyages', 'terreslointaines'].includes(quotation_code)) {
                    temp_status_choices = [
                        { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CDV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" }
                    ];
                } else if (['connections', 'travelmuz'].includes(quotation_code)) {
                    temp_status_choices = [
                        { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" }
                    ];
                }else if(['tropicalementvotre'].includes(quotation_code)) {
                    temp_status_choices = [
                        { group: "TRIP_STATUS_CONTRACT", label: "TV_QUOTATION" },
                        { group: "TRIP_STATUS_CONTRACT", label: "TV_QUOTATION_SENT" },
                        { group: "TRIP_STATUS_CONTRACT", label: "TV_ACCEPTED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "TV_CANCELLED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "TV_COUNTER_OFFER" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "TV_MODIFICATION" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TV_IN_PROGRESS" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TV_CONFIRMED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TV_TO_EMIT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TV_PROCESSED" }
                    ];
                } else if (['touristforum'].includes(quotation_code)) {
                    temp_status_choices = [
                        { group: "TRIP_STATUS_CONTRACT", label: "QUOTATION" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CANCELLED_CGV" },
                        { group: "TRIP_STATUS_CONTRACT", label: "CLOSED" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" },
                        { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "IN_PROGRESS" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "TRANSMITTED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "PROCESSED" },
                        { group: "TRIP_STATUS_BOOKING_PROGRESS", label: "NOT_AVAILABLE" }
                    ]
                }

                if (!['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code) && (displayed_version.status === "INPROGRESS" || displayed_version.status === "FINISHED")) {
                    temp_status_choices.push(displayed_version.status);
                }
                setStatusChoices(temp_status_choices);
            }
        }

        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${trip_displayed_version}/travelers-group/?token=${trip_info.token}`
            }).then(function (response) {
                let temp_rooms = 0;
                let all_groups = response.data.results;
                all_groups.map((group) => {
                    if (group.default === true) {
                        temp_rooms += 1;
                    }
                });
                setRooms(temp_rooms);
            });
        }
    }, [trip_info, trip_displayed_version]);

    useEffect(() => {
        if (days !== undefined && days !== null) {
            let temp_nights = days;
            for (itinerary_index = 1; itinerary_index < itinerary_steps.length; itinerary_index++) {
                if (!moment.utc(itinerary_steps[itinerary_index - 1].end_date).isSame(moment.utc(itinerary_steps[itinerary_index].start_date), "day")) {
                    temp_nights -= 1;
                }
            }
            setNights(temp_nights);
        }
    }, [itinerary_steps]);

    useEffect(() => {
        if (destination !== null && desti_input.length >= 3) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/destinations/?limit=5&reduced=true&ordering=current_version__type&search=${desti_input}&visibility__in=PUBLIC,PRIVATE_TO`
                }).then(function (response) {
                    let data = response.data.results;
                    if (data.length > 0) {
                        setDestiResults(Object.keys(data).map((key) => data[key]));
                    }
                }).catch(function (error) {
                    console.log(error.responseJSON);
                });
            }
        }
    }, [desti_input]);

    useEffect(() => {
        if (trip_info !== null && trip_list !== null) {
            temp_trip_list = trip_list.slice();
            for (trip_index = 0; trip_index < temp_trip_list.length; trip_index++) {
                if (temp_trip_list[trip_index].id == trip_info.id) {
                    temp_trip_list[trip_index] = trip_info;
                }
            }
            dispatch(SetTripList(temp_trip_list, false, temp_trip_list.length));
        }
    }, [trip_info]);

    useEffect(() => {
        let non_default_names = 0;
        let total_requested = 0;
        let total_received = 0;
        let total_products = 0;
        let total_booked = 0;
        let temp_completion = 0;

        travelers.map((traveler) => {
            //count first_name and last_name separetely for more accuracy
            if (traveler.first_name !== t("global.first_name") && traveler.first_name !== t("trip.traveler")) {
                non_default_names += 1;
            } if (traveler.last_name !== t("global.last_name") && !traveler.last_name.includes(t("global.adult")) && !traveler.last_name.includes(t("global.child"))) {
                non_default_names += 1;
            }
        });

        accommodation_cart.map((accommodation) => {
            if (accommodation.is_displayed === true) {
                total_products += 1;
            }
            if (accommodation.is_displayed === true && accommodation.booking_status !== null && accommodation.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        car_cart.map((car) => {
            if (car.is_displayed === true) {
                total_products += 1;
            }
            if (car.is_displayed === true && car.booking_status !== null && car.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        flight_cart.map((flight) => {
            if (flight.is_displayed === true) {
                total_products += 1;
            }
            if (flight.is_displayed === true && flight.booking_status !== null && flight.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        transfer_cart.map((transfer) => {
            if (transfer.is_displayed === true) {
                total_products += 1;
            }
            if (transfer.is_displayed === true && transfer.booking_status !== null && transfer.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        poi_cart.map((poi) => {
            if (poi.is_displayed === true) {
                total_products += 1;
            }
            if (poi.is_displayed === true && poi.booking_status !== null && poi.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        manual_cart.map((product) => {
            if (product.is_displayed === true) {
                total_products += 1;
            }
            if (product.is_displayed === true && product.booking_status !== null && product.booking_status.status_booking === "CONFIRMED") {
                total_booked += 1;
            }
        });

        if (travelers.length !== 0) {
            temp_completion += 25 * (non_default_names / (travelers.length * 2));
        }

        if (quotation_code === "verdie") {
            trip_docs.map((document) => {
                if (document.requested === true) {
                    total_requested += 1;
                    if (document.received === true) {
                        total_received += 1;
                    }
                }
            });
            //div by 0
            if (total_requested !== 0) {
                temp_completion += 25 * (total_received / total_requested);
            }

            //div by 0
            if (total_products !== 0) {
                temp_completion += 50 * (total_booked / total_products);
            }
        } else if (total_products !== 0) {
            temp_completion += 75 * (total_booked / total_products);
        }
        temp_completion = parseFloat((Math.round(temp_completion * 10) / 10).toFixed(1));
        setCompletion(temp_completion);
    }, [travelers, trip_docs, accommodation_cart, car_cart, flight_cart, manual_cart, transfer_cart, poi_cart]);

    const handleTripName = () => {
        if (edit_trip_name === true) {
            let request = {
                name: trip_name
            };
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "PATCH",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                    data: JSON.stringify(request)
                }).then(function (response) {
                    dispatch(SetTripInfo(response.data));
                    enqueueSnackbar(t("menu.trip_list.success_change_name"), { variant: "success", disableWindowBlurListener: true });
                }).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_change_name"), { variant: "error", disableWindowBlurListener: true });
                });
            }
        }
        setEditTripName(!edit_trip_name);
    };

    const handleChangeDestination = () => {
        if (is_current === false) {
            enqueueSnackbar(t("menu.trip_list.warning_change_desti"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 7000 });
        } else if (edit_desti === true) {
            let request = {
                destination: destination.id
            };
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "PATCH",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                    data: JSON.stringify(request)
                }).then(function (response) {
                    enqueueSnackbar(t("menu.trip_list.success_change_desti"), { variant: "success", disableWindowBlurListener: true });
                }).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_change_desti"), { variant: "error", disableWindowBlurListener: true });
                });

                //update trip info
                axios({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                    headers: headers
                }).then(function (response) {
                    dispatch(SetTripInfo(response.data));
                });
            }
        }
        setEditDesti(!edit_desti);
    };

    const checkLeadCart = () => {
        let block_status_change = false;
        for (accommodation_index = 0; accommodation_index < accommodation_cart.length; accommodation_index++) {
            let accommodation = accommodation_cart[accommodation_index];
            if (accommodation.is_displayed === true && accommodation.booking_status === null && (accommodation.group_passenger === null || (accommodation.group_passenger !== null && accommodation.group_passenger.lead_traveler === null))) {
                block_status_change = true;
            }
        }

        if (block_status_change === false) {
            for (car_index = 0; car_index < car_cart.length; car_index++) {
                let car = car_cart[car_index];
                if (car.is_displayed === true && car.booking_status === null && car.driver === null) {
                    block_status_change = true;
                }
            }
        }

        if (block_status_change === false) {
            for (let poi_index = 0; poi_index < poi_cart.length; poi_index++) {
                let poi = poi_cart[poi_index];
                if (poi.is_displayed === true && poi.booking_status === null && (poi.group_passenger === null || (poi.group_passenger !== null && poi.group_passenger.lead_traveler === null))) {
                    block_status_change = true;
                }
            }
        }

        if (block_status_change === false) {
            for (transfer_index = 0; transfer_index < transfer_cart.length; transfer_index++) {
                let transfer = transfer_cart[transfer_index];
                if (transfer.is_displayed === true && transfer.booking_status === null && (transfer.group_passenger === null || (transfer.group_passenger !== null && transfer.group_passenger.lead_traveler === null))) {
                    block_status_change = true;
                }
            }
        }

        if (block_status_change === false) {
            for (manual_index = 0; manual_index < manual_cart.length; manual_index++) {
                let manual_product = manual_cart[manual_index];
                if (manual_product.is_displayed === true && manual_product.booking_status === null && manual_product.group_passenger !== null && manual_product.group_passenger.lead_traveler === null) {
                    block_status_change = true;
                }
            }
        }

        return (block_status_change);
    };

    const handleStatus = (event) => {
        let new_status = event.target.value;
        if (new_status !== trip_status) {
            if (user.client_full.type === 2 && (new_status === "ADDITEM" || new_status === "ONREQUEST" || new_status === "BOOKED" || new_status === "INPROGRESS" || new_status === "FINISHED")) {
                enqueueSnackbar(t("menu.trip_list.agency_cant_change_status"), { variant: "warning", disableWindowBlurListener: true });
            } else if (user.client_full.type === 2 && (trip_status === "ONREQUEST" || trip_status === "BOOKED" || trip_status === "INPROGRESS" || trip_status === "FINISHED" || trip_status === "CANCELLED" || trip_status === "AGENCY_MOD")) {
                enqueueSnackbar(t("menu.trip_list.agency_cant_change_status"), { variant: "warning", disableWindowBlurListener: true });
            } else if (user.client_full.type === 2 && new_status === "AGENCY_MOD" && (is_traveler_default === true || checkLeadCart() === true)) {
                enqueueSnackbar(t("menu.trip_list.default_travelers_cant_change_status"), { variant: "warning", autoHideDuration: 10000, disableWindowBlurListener: true });
            } else {
                let { pass_check, headers } = CheckBeforeRequest();
                let new_due_date = null;
                if (new_status === "ONGOING" || new_status === "AGENCY_MOD") {
                    new_due_date = moment().add(3, "d").format();
                } else if (new_status === "TOBOOK" && quotation_code === "verdie") {
                    new_due_date = moment().add(6, "d").format();
                }
                if (new_due_date !== null && moment(new_due_date).day() === 0) {
                    new_due_date = moment(new_due_date).add(1, "d").format();
                }

                let request = {
                    status: new_status,
                    due_date: new_due_date
                };
                if (pass_check) {
                    axios({
                        method: "PATCH",
                        url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                        headers: headers,
                        data: JSON.stringify(request)
                    }).then(function (response) {
                        for (let i = 0; i < response.data.prices.length; i++) {
                            if (response.data.prices[i].selling_price === undefined) {
                                response.data.prices.splice(i, 1);
                                i--;
                            }
                        }
                        for (let i = 0; i < response.data.prices_flight.length; i++) {
                            if (response.data.prices_flight[i].selling_price === undefined) {
                                response.data.prices_flight.splice(i, 1);
                                i--;
                            }
                        }
                        for (let i = 0; i < response.data.prices_stack_product.length; i++) {
                            if (response.data.prices_stack_product[i].selling_price === undefined) {
                                response.data.prices_stack_product.splice(i, 1);
                                i--;
                            }
                        }
                        for (let i = 0; i < response.data.prices_terrestrial.length; i++) {
                            if (response.data.prices_terrestrial[i].selling_price === undefined) {
                                response.data.prices_terrestrial.splice(i, 1);
                                i--;
                            }
                        }
                        dispatch(SetTripData(response.data, response.data.travelers, (response.data.budget === null ? 0 : parseInt(response.data.budget)), response.data.trip.currency, response.data.contact_traveler, response.data.contact_traveler_detail, response.data.trip.trip_reference));
                        //dispatch(SetTripInfoStatus(new_status, GetDataFromCurrentVersion(current_version_id, trip_info.data, to_show_version)));
                        dispatch(SetTripInfoVersion(response.data));
                        enqueueSnackbar(t("menu.trip_list.success_change_status"), { variant: "success", disableWindowBlurListener: true });
                        setTripStatus(new_status);
                        setDueDate(new_due_date);
                    }).catch(function (error) {
                        console.log(error.responseJSON);
                        enqueueSnackbar(t("menu.trip_list.failed_change_status"), { variant: "error", disableWindowBlurListener: true });
                    });
                }
            }
        }
    };

    const handleVersion = (event) => {
        let new_version_id = event.target.value;
        setVersion(new_version_id);
        dispatch(ToShowVersion(new_version_id));

        //change version number and name
        for (version_index = 0; version_index < all_versions.length; version_index++) {
            if (all_versions[version_index].id === new_version_id) {
                let new_version_index = version_index + 1;
                setVersionNum(new_version_index);
                setVersionName(all_versions[version_index].name);
            }
        }

        let { pass_check, headers } = CheckBeforeRequest();
        //change current version bool
        setIsCurrent(new_version_id === current_version_id ? true : false);

        //change status & due_date
        if (pass_check) {
            axios({
                method: "GET",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${new_version_id}/`,
                headers: headers
            }).then(function (response) {
                setTripStatus(response.data.status);
                setDueDate(response.data.due_date);
            }).catch(function (error) {
                console.log(error.responseJSON);
            });
        }
    };

    const handleChangeCurrent = () => {
        if (is_current === false) {
            let request = {
                current_version: trip_displayed_version
            };

            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "PATCH",
                    url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/`,
                    headers: headers,
                    data: request
                }).then(function (response) {
                    let data = response.data;
                    dispatch(SetTripInfo(data));
                    setCurrentId(trip_displayed_version);
                    enqueueSnackbar(t("menu.trip_list.success_change_current"), { variant: "success", disableWindowBlurListener: true });
                }).catch(function (error) {
                    console.log(error.responseJSON);
                    enqueueSnackbar(t("menu.trip_list.failed_change_current"), { variant: "error", disableWindowBlurListener: true });
                });
            }
            setIsCurrent(!is_current);
        } else {
            enqueueSnackbar(t("menu.trip_list.warning_change_current"), { variant: "warning", disableWindowBlurListener: true, autoHideDuration: 10000 });
        }
    };

    const handleDueDate = () => {
        let { pass_check, headers } = CheckBeforeRequest();
        let request = {
            due_date: due_date
        };
        if (pass_check) {
            axios({
                method: "PATCH",
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
                headers: headers,
                data: JSON.stringify(request)
            }).then(function (response) {
                for (let i = 0; i < response.data.prices.length; i++) {
                    if (response.data.prices[i].selling_price === undefined) {
                        response.data.prices.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_flight.length; i++) {
                    if (response.data.prices_flight[i].selling_price === undefined) {
                        response.data.prices_flight.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_stack_product.length; i++) {
                    if (response.data.prices_stack_product[i].selling_price === undefined) {
                        response.data.prices_stack_product.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < response.data.prices_terrestrial.length; i++) {
                    if (response.data.prices_terrestrial[i].selling_price === undefined) {
                        response.data.prices_terrestrial.splice(i, 1);
                        i--;
                    }
                }
                dispatch(SetTripData(response.data, response.data.travelers, (response.data.budget === null ? 0 : parseInt(response.data.budget)), response.data.trip.currency, response.data.contact_traveler, response.data.contact_traveler_detail, response.data.trip.trip_reference));
                dispatch(SetTripInfoVersion(response.data));
                enqueueSnackbar(t("menu.trip_list.success_change_due_date"), { variant: "success", disableWindowBlurListener: true });
            }).catch(function (error) {
                console.log(error.responseJSON);
                enqueueSnackbar(t("menu.trip_list.failed_change_due_date"), { variant: "error", disableWindowBlurListener: true });
            });
        }
    };

    const createMCTO = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/${quotation_code === "verdie" ? 'mcto' : 'pgi'}/generate/`,
            headers: headers
        }).then(function (response) {
            enqueueSnackbar(t("menu.trip_list.success_create_mcto"), { variant: "success", disableWindowBlurListener: true });
        }).catch(function (error) {
            console.log(error.responseJSON);
            enqueueSnackbar(t("menu.trip_list.failed_create_mcto"), { variant: "error", disableWindowBlurListener: true });
        });
    };
    const getMCTO = () => {
        const { headers } = CheckBeforeRequest();
        dispatch(toggleMCTOModal());
        axios({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/${quotation_code === "verdie" ? 'mcto' : 'pgi'}/`,
            headers: headers
        }).then(function (response) {
            dispatch(setMCTOData(response.data.results));
        }).catch(function (error) {
            console.log(error.responseJSON);
        });
    };

    const returnToList = () => {
        router.push(`/${window.url_name}/menu/trip-list`);
        dispatch({ type: "FLUSH_REMOVE_STATE" });
        dispatch(SetTripInfo(null));
        dispatch(SetArrayTab(0));
    };
    const handleCercleStatus = (status) => {
        const { headers } = CheckBeforeRequest();
        let status_booking_progress = null;
        let status_contract = null;
        let status_modification = null;
        status.map((el) => {
            if (el.group === "TRIP_STATUS_CONTRACT") {
                status_contract = el.label;
            } else if (el.group === "TRIP_STATUS_MODIFICATION") {
                status_modification = el.label;
            } else if (el.group === "TRIP_STATUS_BOOKING_PROGRESS") {
                status_booking_progress = el.label;
            }
        });
        axios({
            method: "PATCH",
            url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${current_version_id}/`,
            headers: headers,
            data: {
                status_contract: status_contract !== null ? status_contract : undefined,
                status_modification: status_modification !== null ? status_modification : undefined,
                status_booking_progress: status_booking_progress !== null ? status_booking_progress : undefined,
                total_cost: status_contract !== undefined ? total_cost.toFixed(2) : undefined
            }
        }).then((response) => {
            enqueueSnackbar(t("menu.trip_list.success_change_status"), { variant: "success", disableWindowBlurListener: true });
            dispatch({
                type: "TRIP_SET_DATA_TRIP",
                payload: {
                    data_trip: response.data
                }
            });
            setCercleStatus(status);
        }).catch(function (error) {
            console.log(error.responseJSON);
        });
        setCercleStatus(status);
    };
    const onCercleStatusChange = (selectedOptions) => {
        let sort_group = ["TRIP_STATUS_CONTRACT", "TRIP_STATUS_MODIFICATION", "TRIP_STATUS_BOOKING_PROGRESS"];
        let tmp = selectedOptions.slice();
        let last_elem = tmp[tmp.length - 1];
        if (last_elem.label === "CANCELLED_CDV" || last_elem.label === "CANCELLED_CGV") {
            tmp = [last_elem];
        } else if (last_elem.group === "TRIP_STATUS_CONTRACT" && last_elem.label === "CONFIRMED") {
            tmp = tmp.filter((el) => el.group !== last_elem.group);
            tmp = [last_elem];
            tmp.push({ group: "TRIP_STATUS_MODIFICATION", label: "FIXED_PV" });
        } else if (last_elem.group === "TRIP_STATUS_CONTRACT" && last_elem.label === "QUOTATION") {
            tmp = [last_elem];
        } else if (last_elem.group === "TRIP_STATUS_MODIFICATION" && last_elem.label === "AMENDMENT") {
            tmp = [{ group: "TRIP_STATUS_CONTRACT", label: "CONFIRMED" }, { group: "TRIP_STATUS_MODIFICATION", label: "AMENDMENT" }];
        } else {
            tmp = tmp.filter((el) => el.group !== last_elem.group);
            tmp.push(last_elem);
            tmp = tmp.sort((a, b) => {
                return sort_group.indexOf(a.group) - sort_group.indexOf(b.group);
            });
        }
        handleCercleStatus(tmp);
    };
    return (
        <Grid>
            <Grid container justify="space-evenly" alignItems="center">
                <Grid item xs={1}>
                    <IconButton className={classes.genericBorder} size="small" onClick={returnToList}>
                        <NavigateBeforeIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={9} className={classes.textLeft} style={{ paddingLeft: 5 }}>
                    <Button onClick={returnToList}>
                        <span className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize10} ${classes.letterSpacing}`}> {t("menu.trip_list.return_to_list")} </span>
                    </Button>
                </Grid>
                <Grid item xs={2} />
            </Grid>
            <Grid container className={`${classes.paddingTop}`} justify="space-between" alignItems="center">
                <Grid item>
                    {
                        !['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code) && (
                            <Select
                                labelId="change_status_label"
                                id="change_trip_status"
                                value={trip_status}
                                onChange={handleStatus}
                                renderValue={(option) => {
                                    return (<FiberManualRecordIcon style={{ color: GetStatusColorHexa(option), fontSize: 20 }}/>);
                                } }
                                className={`${classes.genericBorder} ${classes.selectBorder}`}
                                style={{ paddingLeft: 15 }}
                                MenuProps={{ disableScrollLock: true }}
                                disableUnderline>
                                {
                                    status_choices.map((status, status_index) =>{
                                        let new_value = status === "TOBOOK_VERDIE" ? "TOBOOK" : status;
                                        return (
                                            <MenuItem value={new_value} key={`${status_index}`}>
                                                <Grid container className={classes.itemsCenter}>
                                                    <FiberManualRecordIcon style={{ color: GetStatusColorHexa(status), marginRight: 3 }}/>
                                                    <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                        {t(`menu.trip_list.${status}`)}
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        )
                    }
                    {
                        ['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code) && (
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                options={status_choices}
                                value={cercleStatus}
                                groupBy={(option) => t("menu.trip_list." + option.group)}
                                getOptionLabel={(option) => t("menu.trip_list." + option.label)}
                                getOptionSelected={(option, value) => option.label === value.label && option.group === value.group}
                                renderOption={(option, { selected }) => {
                                    return (
                                        <Grid container className={classes.itemsCenter}>
                                            <FiberManualRecordIcon style={{ color: GetStatusColorHexa(option.label), marginRight: 3 }}/>
                                            <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                                {t("menu.trip_list." + option.label)}
                                            </Grid>
                                        </Grid>
                                    );
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            icon={<FiberManualRecordIcon style={{ color: GetStatusColorHexa(option.label), marginRight: 3 }}/>}
                                            variant={"outlined"}
                                            label={t("menu.trip_list." + option.label)}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                getOptionDisabled={(option) => {
                                //if (option.label === "AMENDMENT" && cercleStatus.find((el) => el.group === "TRIP_STATUS_CONTRACT" && el.label !== "CONFIRMED") !== undefined) {
                                //return true;
                                //}
                                    if ((option.group === "TRIP_STATUS_MODIFICATION" || option.group === "TRIP_STATUS_BOOKING_PROGRESS") && cercleStatus.find((el) => el.group === "TRIP_STATUS_CONTRACT" && el.label === "CONFIRMED") === undefined) {
                                        return true;
                                    }
                                    if ((option.group === "TRIP_STATUS_MODIFICATION" || option.group === "TRIP_STATUS_BOOKING_PROGRESS") && cercleStatus.find((el) => el.group === "TRIP_STATUS_CONTRACT") === undefined) {
                                        return true;
                                    }
                                }}
                                onChange={(event, selectedOptions, reason) => {
                                    if (reason === "remove-option") {
                                        setCercleStatus(selectedOptions);
                                    } else if (reason === "select-option") {
                                        onCercleStatusChange(selectedOptions);
                                    } else if (reason === "clear") {
                                        setCercleStatus([]);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //InputProps={{
                                        //...params.InputProps,
                                        //className: `${classes.genericBorder} ${classes.selectBorder}`,
                                        //}}
                                        variant="outlined"
                                        size="small"
                                        InputLabelProps={{ style: { fontWeight: "bold", color: "#000000" } }}
                                        fullWidth
                                    />
                                )}
                            />
                        )
                    }
                </Grid>
                <Grid item className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize16} ${classes.letterSpacing}`}>
                    {t("menu.file_nb") + trip_info.trip_reference}
                </Grid>
                <Grid item>
                    <Select
                        autoWidth={true}
                        labelId="change_version_label"
                        id="change_trip_version"
                        value={trip_displayed_version}
                        onChange={handleVersion}
                        className={`${classes.genericBorder} ${classes.selectBorder}`}
                        style={{ paddingLeft: 22, paddingTop: 2, paddingBottom: 2 }}
                        MenuProps={{ disableScrollLock: true }}
                        disableUnderline>
                        {
                            all_versions.map((version, version_index) => {
                                let version_new_index = version_index + 1;
                                return (
                                    <MenuItem value={version.id} key={"version-id-" + version.id}>
                                        <Grid className={`${classes.genericText} ${classes.fontWeight500} ${classes.fontSize12}`}>
                                            { "v" + version_new_index}
                                        </Grid>
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </Grid>
            </Grid>
            <Grid className={ mobile === true ? classes.textLeft : classes.textCenter}>
                {
                    displayed_version_name !== "" && (
                        <Grid className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize14} ${classes.letterSpacing}`} style={ mobile === true ? { paddingLeft: 70 } : {}}>
                            { "(" + displayed_version_name + ")"}
                        </Grid>
                    )
                }
            </Grid>
            <Grid container alignItems="center" justify="space-between">
                <Grid item className={`${classes.textLeft} ${classes.paddingTop}`}>
                    {
                        quotation_code !== "marcovasco" && (
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize10} ${classes.letterSpacing} ${classes.paddingTop}`}>
                                {t("menu.trip_list.due_date")}
                            </div>
                        )
                    }
                    {
                        quotation_code !== "marcovasco" && due_date !== null && (
                            <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale={i18n.language}>
                                <DateTimePicker
                                    value={due_date}
                                    onChange={(newValue) => setDueDate(moment(newValue).format())}
                                    onClose={handleDueDate}
                                    InputProps={{ disableUnderline: true }}
                                    InputAdornmentProps={{ style: { paddingRight: 12, paddingBottom: 5 } }}
                                    OpenPickerButtonProps={{ style: { padding: "none" } }}
                                    renderInput={(props) =>
                                        (<TextField
                                            {...props}
                                            className={clsx({ [classes.dueDateFuture]: moment(due_date).diff(moment(), "d") > 0, [classes.dueDatePast]: moment(due_date).diff(moment(), "d") <= 0 })}
                                            helperText=""
                                            margin="none"
                                            size="small"
                                        />)
                                    }
                                />
                            </LocalizationProvider>
                        )
                    }
                </Grid>
                <Grid item className={`${classes.textRight} ${classes.paddingTop}`}>
                    <Box className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize10} ${classes.letterSpacing} ${is_current === true ? classes.colorBlue : classes.colorGrey}`} maxWidth={"sm"}>
                        {t("menu.trip_list.current_version")}
                        <Tooltip title={t("menu.trip_list.change_current_ver")}>
                            <Switch checked={is_current} color="primary" onChange={handleChangeCurrent}/>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
            {
                contact_lead !== null && (
                    <Grid className={classes.paddingTop}>
                        <Grid container direction ="row" alignItems="center" justify="flex-start">
                            {
                                edit_trip_name === false && destination !== null && (
                                    <Grid item className={`${classes.editableFields} ${classes.textLeft} ${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize20} ${classes.letterSpacing}`}>
                                        {trip_name !== null && trip_name !== destination.data.international_name ? trip_name : t("menu.trip_list.trip_of") + contact_lead.title + " " + contact_lead.last_name}
                                    </Grid>
                                )
                            }
                            {
                                edit_trip_name === true && destination !== null && (
                                    <Grid item xs={edit_trip_name === true ? 10 : 11}>
                                        <TextField onChange={ ((event) => {
                                            setTripName(event.target.value);
                                        }) }
                                        fullWidth
                                        label={t("menu.trip_list.trip_name")}
                                        InputLabelProps={{ style: { fontWeight: "bold", color: "#000000" } }}
                                        defaultValue={trip_name !== null && trip_name !== destination.data.international_name ? trip_name : t("menu.trip_list.trip_of") + contact_lead.title + " " + contact_lead.last_name}
                                        variant="outlined"
                                        size="small" />
                                    </Grid>
                                )
                            }
                            <Grid item xs={edit_trip_name === true ? 2 : 1}>
                                <Tooltip title={edit_trip_name === true ? t("menu.trip_list.confirm_trip_name") : t("menu.trip_list.edit_trip_name")}>
                                    <IconButton size="small" onClick={handleTripName} className={`${edit_trip_name === true ? classes.checkBorder : classes.editBorder} ${classes.iconBorder}`}>
                                        {edit_trip_name === true ? <CheckIcon style={{ color: "#71DD13" }} className={classes.smallIcon} /> : <CreateOutlinedIcon className={classes.smallIcon} />}
                                    </IconButton>
                                </Tooltip>
                                {
                                    edit_trip_name === true && (
                                        <Tooltip title={t("global.cancel")}>
                                            <IconButton size="small" onClick={() => {
                                                setEditTripName(false);
                                            }} style={{ color: "#FF0000" }} className={`${classes.cancelBorder} ${classes.iconBorder}`}>
                                                <ClearIcon className={classes.smallIcon} />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>)
            }
            <Grid container direction ="row" alignItems="center" justify="flex-start" className={`${classes.paddingTop}`}>
                {
                    edit_desti === false && destination !== null && (
                        <Grid item className={`${classes.editableFields} ${classes.textLeft} ${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize20} ${classes.letterSpacing}`}>
                            {destination.data.international_name}
                        </Grid>
                    )
                }
                {
                    edit_desti === true && (
                        <Grid item xs={edit_desti === true ? 10 : 11}>
                            <Autocomplete
                                getOptionSelected={(option, value) => option.data.international_name === value.data.international_name}
                                getOptionLabel={(option) => option.data.international_name}
                                onInputChange={((event, value) => {
                                    setDestiInput(value); 
                                }) }
                                onChange={((event, value) => {
                                    if (value !== null) {
                                        setDestination(value);
                                    }
                                })}
                                noOptionsText={t("menu.trip_list.no_desti_found")}
                                value={destination}
                                options={desti_results}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("header.destination")}
                                        variant="outlined"
                                        size="small"
                                        InputLabelProps={{ style: { fontWeight: "bold", color: "#000000" } }}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    )
                }
                <Grid item xs={edit_desti === true ? 2 : 1}>
                    <Tooltip title={edit_desti === true ? t("menu.trip_list.confirm_desti") : t("menu.trip_list.edit_desti")}>
                        <IconButton size="small" onClick={handleChangeDestination} className={`${edit_desti === true ? classes.checkBorder : classes.editBorder} ${classes.iconBorder}`}>
                            {edit_desti === true ? <CheckIcon style={{ color: "#71DD13" }} className={classes.smallIcon} /> : <CreateOutlinedIcon className={classes.smallIcon} />}
                        </IconButton>
                    </Tooltip>
                    {
                        edit_desti === true && (
                            <Tooltip title={t("global.cancel")}>
                                <IconButton size="small" className={`${classes.cancelBorder} ${classes.iconBorder}`} onClick={() => {
                                    setEditDesti(false);
                                    setDestination(all_versions[displayed_version_num - 1].destination);
                                }} style={{ color: "#FF0000" }}> <ClearIcon className={classes.smallIcon} /> </IconButton>
                            </Tooltip>
                        )
                    }
                </Grid>
            </Grid>
            {
                start_date !== null && end_date !== null && (
                    <Grid>
                        <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight500} ${classes.textLeft} ${classes.datesFormat} ${classes.letterSpacing}`}>
                            {t("global.from") + "  " + moment.utc(start_date).format("L") + "  " + t("global.to") + "  " + moment.utc(end_date).format("L")}
                            { days > 0 ? (days > 1 ? "(" + days + t("global.days") : "(" + days + t("global.day")) : ""}
                            { nights > 0 ? (nights > 1 ? "/" + nights + " " + t("itinerary.nights") + ")" : "/" + nights + " " + t("itinerary.night") + ")") : ")"}
                        </div>
                    </Grid>
                )
            }
            <Grid container justify="space-between" alignItems="center" className={`${classes.paddingTop}`}>
                <Grid item>
                    {
                        travelers_ages.length !== 0 && (
                            <div className={`${classes.paddingTop} ${classes.textLeft} ${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize10} ${classes.letterSpacing}`} >
                                { rooms > 0 ? (rooms > 1 ? rooms + " " + t("global.rooms") + " " : rooms + " " + t("global.room")) + " " : " "}
                                { travelers_ages[0] > 0 ? (travelers_ages[0] > 1 ? travelers_ages[0] + " " + t("flight_search.adults") : travelers_ages[0] + " " + t("flight_search.adult")) : "" }{ travelers_ages[1] > 0 ? ", " : "" }
                                { travelers_ages[1] > 0 ? (travelers_ages[1] > 1 ? travelers_ages[1] + " " + t("flight_search.ados") : travelers_ages[1] + " " + t("flight_search.ado")) : "" }{ travelers_ages[2] > 0 ? ", " : "" }
                                { travelers_ages[2] > 0 ? (travelers_ages[2] > 1 ? travelers_ages[2] + " " + t("flight_search.children") : travelers_ages[2] + " " + t("flight_search.child")) : "" }{ travelers_ages[3] > 0 ? ", " : "" }
                                { travelers_ages[3] > 0 ? (travelers_ages[3] > 1 ? travelers_ages[3] + " " + t("flight_search.babies") : travelers_ages[3] + " " + t("flight_search.baby")) : "" }
                            </div>
                        )
                    }
                </Grid>
                <Grid item>
                    <IconButton size="small" onClick={() => {
                        setOpenUserInfo(true);
                    }}>
                        <PermContactCalendarOutlinedIcon/>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container alignItems="center" justify="space-between">
                <Grid className={`${classes.textLeft} ${classes.paddingTop}`}>
                    <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12} ${classes.colorBlue} ${classes.letterSpacing}`}>
                        {t("menu.trip_list.completion")}
                    </div>
                    <Box display="flex" className={classes.itemsCenter} justifyContent="left">
                        <Box width="100%">
                            <LinearProgress variant="determinate" value={trip_completion} style={{ paddingTop: "2%", marginTop: "2%", backgroundColor: "rgba(44, 152, 240, 0.36)" }} />
                        </Box>
                        <Box style={{ paddingLeft: "2%", paddingTop: "1.5%" }}>
                            <div className={`${classes.genericText} ${classes.upperCase} ${classes.fontWeight900} ${classes.fontSize12} ${classes.colorBlue} ${classes.letterSpacing}`}>
                                {trip_completion + "%"}
                            </div>
                        </Box>
                    </Box>
                </Grid>
                <Grid className={classes.textRight}>
                    {
                        (quotation_code === "verdie" || (quotation_code === "visiteurs" && user.client_full.type !== 2)) && (
                            trip_status === "ONREQUEST" || trip_status === "TOBOOK" || trip_status === "DEPOSIT" || trip_status === "BOOKED" || trip_status === "ADDITEM" || trip_status === "AGENCY_MOD") && (
                            <div style={{ paddingTop: 15 }}>
                                <Tooltip title={applicant.first_name !== t("global.first_name")
                                                && applicant.first_name !== t("trip.traveler")
                                                && applicant.last_name !== t("global.last_name")
                                                && !applicant.last_name.includes(t("global.adult"))
                                    ? "" : (quotation_code === "verdie" ? t("menu.trip_list.enable_mcto") : t("menu.trip_list.enable_pgi"))}>
                                    <span>
                                        <Button
                                            className={`${classes.mctoBorder} ${classes.colorOrange}`}
                                            onClick={createMCTO}
                                            disabled={applicant.first_name !== t("global.first_name")
                                                            && applicant.first_name !== t("trip.traveler")
                                                            && applicant.last_name !== t("global.last_name")
                                                            && !applicant.last_name.includes(t("global.adult")) ? false : true}>
                                            <div style={{ marginTop: -3, marginBottom: -3 }}>
                                                {quotation_code === "verdie" ? "MCTO" : "PGI"}
                                            </div>
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title={t("global.display") + " " + (quotation_code === "verdie" ? "MCTO" : "PGI")}>
                                    <IconButton size="small" onClick={getMCTO} style={{ marginLeft: 5 }} edge="start">
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )
                    }
                </Grid>
            </Grid>
            <MCTOModal/>
            { all_versions.length > 0 && <TripListMobileInfo openUserInfo={openUserInfo} setOpenUserInfo={setOpenUserInfo} last_editor={all_versions[displayed_version_num - 1].last_author} /> }
        </Grid>
    );
});

export default React.memo(TripListInfoMobileHeader);
