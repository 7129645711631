import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isNumber } from "lodash";
import { getCircuit } from "../../Itinerary/utils/getCircuit";
import { Circuit } from "../../Itinerary/objects/circuit";
import { AppState } from "../../../Reducers/Reducers";

export function useFlightShouldBeForArrivalDates(): boolean {
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const [circuit, setCircuit] = useState<Circuit | null>(null);

    useEffect(() => {
        if (
            isNumber(trip?.from_circuit_id) &&
            trip.from_circuit &&
            !['TRAVEL_EXCHANGE', 'ADALTE'].includes(trip.from_circuit.source)
        ) {
            (async () => {
                try {
                    const circuit = await getCircuit(trip.from_circuit_id!);
                    setCircuit(circuit);
                } catch (error) {
                    console.error(error);
                }
            })();
        }
    }, [trip]);

    return (
        !!trip?.from_circuit?.source &&
        ['TRAVEL_EXCHANGE', 'ADALTE'].includes(trip.from_circuit?.source)
    ) || (
        !!circuit?.transport_in_arrival_date
    );
}