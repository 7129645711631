import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    Divider,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import { findLast, flatten, flattenDeep, isNumber } from "lodash";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { isProductSamePackage } from "./utils/isProductSamePackage";
import { isProductPackaged } from "./utils/isProductPackaged";
import { useCartPackageUpdatePrices } from "./network/cartPackageUpdatePrices";
import { useShowError } from "../Utils/showError";
import { usePackagedProducts } from "./utils/packagedProducts";
import { useGetPrice } from "./utils/getPrice";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import { useGetTaxesPrice } from "./utils/getTaxesPrice";
import GetCookie from "../Common/Functions/GetCookie";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    stackInfoId: number | null,
    stackNumber: number | null
}

export function CartConstructionPackageMargin(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector((state: AppState) => state.user.user);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const [loading, setLoading] = useState(false);
    const [editPrices, setEditPrices] = useState(false);
    const [priceInputs, setPriceInputs] = useState<typeof prices>({
        flightPrice: {
            id: undefined,
            cost: 0,
            costConverted: 0,
            sellingCost: 0,
            percentage: 1,
            currency: undefined,
            sellingCurrency: undefined,
            vatAmount: 0,
            vatCurrency: undefined,
            margin: 0,
            marginType: undefined
        },
        terrestrialPrice: {
            id: undefined,
            cost: 0,
            costConverted: 0,
            sellingCost: 0,
            percentage: 1,
            currency: undefined,
            sellingCurrency: undefined,
            vatAmount: 0,
            vatCurrency: undefined,
            margin: 0,
            marginType: 'PER'
        },
        terrestrialProductsPrices: [],
        flightProductsPrices: [],
        terrestrialSurcom: {
            cost: 0,
            percent: 0,
            currency: undefined
        },
        flightSurcom: {
            cost: 0,
            percent: 0,
            currency: undefined
        }
    });
    const packagedProducts = usePackagedProducts({ returnAll: true });
    const getPrice = useGetPrice();
    const products = useMemo(() => {
        return packagedProducts[`${props.stackNumber ?? ''}-${props.stackInfoId ?? ''}`];
    }, [
        packagedProducts,
        props.stackNumber,
        props.stackInfoId
    ]);
    const counterOfferIds = useMemo(() => {
        return flattenDeep(
            Object.values(products ?? {}).map((products) => {
                return products.map((product) => {
                    if ('type' in product) {
                        const { type, ...rest } = product;
                        return Object.values(rest).map((product) => {
                            return product.counter_offer_of as number | null;
                        });
                    }
                    return [product.counter_offer_of];
                });
            })
        ).filter((item) => isNumber(item));
    }, [products]);
    const pricesAccommodations = useMemo(() => {
        return products?.accommodations.filter((item) => {
            return isProductPackaged({
                product: item.accommodation,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.accommodation,
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            ) &&
                !item.accommodation.is_optional &&
                !item.accommodation.provider_refused &&
                !counterOfferIds.includes(item.accommodation.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.accommodations,
        props.stackNumber,
        props.stackInfoId
    ]);
    const pricesTransfers = useMemo(() => {
        return products?.transfers.filter((item) => {
            return isProductPackaged({
                product: item.transfer,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.transfer,
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            ) &&
                !item.transfer.is_optional &&
                !item.transfer.provider_refused &&
                !counterOfferIds.includes(item.transfer.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.transfers,
        props.stackNumber,
        props.stackInfoId
    ]);
    const pricesPois = useMemo(() => {
        return products?.pois.filter((item) => {
            return isProductPackaged({
                product: item.poi,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.poi,
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            ) &&
                !item.poi.is_optional &&
                !item.poi.provider_refused &&
                !counterOfferIds.includes(item.poi.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.pois,
        props.stackNumber,
        props.stackInfoId
    ]);
    const pricesCars = useMemo(() => {
        return products?.cars.filter((item) => {
            return isProductPackaged({
                product: item.car,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.car,
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            ) &&
                !item.car.is_optional &&
                !item.car.provider_refused &&
                !counterOfferIds.includes(item.car.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.cars,
        props.stackNumber,
        props.stackInfoId
    ]);
    const pricesFlights = useMemo(() => {
        return products?.flights.filter((item) => {
            return isProductPackaged({
                product: item.flight,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item.flight,
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            ) &&
                !item.flight.is_optional &&
                !item.flight.provider_refused &&
                !counterOfferIds.includes(item.flight.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.flights,
        props.stackNumber,
        props.stackInfoId
    ]);
    const pricesManualProducts = useMemo(() => {
        return products?.manualProducts.filter((item) => {
            return isProductPackaged({
                product: item,
                stackInfos: trip?.stack_info ?? null,
                for_price: true
            }) && isProductSamePackage(
                item,
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            ) &&
                !item.is_optional &&
                !item.provider_refused &&
                !counterOfferIds.includes(item.id);
        });
    }, [
        trip,
        counterOfferIds,
        products?.manualProducts,
        props.stackNumber,
        props.stackInfoId
    ]);
    const getTaxesPrice = useGetTaxesPrice();
    const prices = useMemo(() => {
        const flightPrices = trip?.prices_flight?.filter((item) => {
            return isProductSamePackage(
                item,
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        }) ?? [];
        const terrestrialPrices = trip?.prices_terrestrial?.filter((item) => {
            return isProductSamePackage(
                item,
                {
                    stack_number: props.stackNumber,
                    stack_info_id: props.stackInfoId
                }
            );
        }) ?? [];
        const flightPrice = findLast(
            flightPrices,
            (item) => item.master_price
        ) ?? findLast(
            flightPrices,
            (item) => item.is_network
        ) ?? findLast(
            flightPrices,
            (item) => item.owner === user?.client
        );
        const terrestrialPrice = findLast(
            terrestrialPrices,
            (item) => item.master_price
        ) ?? findLast(
            terrestrialPrices,
            (item) => item.is_network
        ) ?? findLast(
            terrestrialPrices,
            (item) => item.owner === user?.client
        );
        const stackPrice = findLast(
            trip?.prices_stack_product ?? [],
            (item) => item.master_price
        ) ?? findLast(
            trip?.prices_stack_product ?? [],
            (item) => item.owner === user?.client
        );
        const flightVatPrice = flightPrices.find((item) => item.is_tva);
        const terrestrialVatPrice = terrestrialPrices.find((item) => item.is_tva);
        const stackVatPrice = trip?.prices_stack_product?.find((item) => item.is_tva);
        let marginType: 'FIX' | 'PER' | undefined;

        const terrestrialProductsPrices = flatten(
            pricesAccommodations?.map((item) => {
                if (item.type === 'normal') {
                    return item.accommodation.rooms?.map((room) => {
                        return getPrice(room.prices);
                    });
                }
                return getPrice(item.accommodation.prices);
            }) ?? []
        ).concat(
            pricesTransfers?.map((item) => {
                return getPrice(item.transfer.prices);
            }) ?? []
        ).concat(
            pricesPois?.map((item) => {
                return getPrice(item.poi.prices);
            }) ?? []
        ).concat(
            pricesCars?.map((item) => {
                return getPrice(item.car.prices);
            }) ?? []
        ).concat(
            pricesManualProducts?.map((item) => {
                return getPrice(item.prices);
            }) ?? []
        );
        const flightProductsPrices = pricesFlights?.map((item) => {
            return {
                ...getPrice(item.flight.prices),
                taxes: getTaxesPrice(item.flight.taxes ?? '0')
            };
        }) ?? [];

        if (!terrestrialPrice) {
            marginType = !stackPrice?.custom_rate ?
                'PER' :
                stackPrice.custom_rate_type;
        } else {
            marginType = !terrestrialPrice.custom_rate ?
                'PER' :
                terrestrialPrice.custom_rate_type;
        }

        let margin = 0;

        if (!terrestrialPrice) {
            margin = !stackPrice?.custom_rate ?
                parseFloat(stackPrice?.percentage_between ?? '0') :
                parseFloat(stackPrice.custom_value);
        } else {
            margin = !terrestrialPrice?.custom_rate ?
                parseFloat(terrestrialPrice.percentage_between) :
                parseFloat(terrestrialPrice.custom_value);
        }

        let flightMarginType: 'FIX' | 'PER' | undefined;

        if (flightPrice) {
            flightMarginType = flightPrice?.custom_rate_type;
        }

        if (flightProductsPrices.length > 0) {
            flightMarginType = 'PER';
        }

        if (terrestrialProductsPrices.length > 0) {
            marginType = 'PER';
        }

        const surcomTerrestrial = terrestrialPrices.find((item) => {
            return item.is_surcom;
        }) ?? trip?.prices_stack_product?.find((item) => {
            return item.is_surcom;
        });
        const surcomFlight = flightPrices.find((item) => {
            return item.is_surcom;
        });

        return {
            terrestrialPrice: {
                id: terrestrialPrice?.id ?? stackPrice?.id,
                cost: terrestrialPrice ?
                    parseFloat(terrestrialPrice.purchase_price ?? '0') :
                    parseFloat(stackPrice?.purchase_price ?? '0'),
                costConverted: terrestrialPrice ?
                    parseFloat(terrestrialPrice.purchase_price ?? '0') * parseFloat(terrestrialPrice.factor_used) :
                    parseFloat(stackPrice?.purchase_price ?? '0') * parseFloat(stackPrice?.factor_used ?? '1'),
                sellingCost: terrestrialPrice ?
                    parseFloat(terrestrialPrice.selling_price ?? '0') :
                    parseFloat(stackPrice?.selling_price ?? '0'),
                margin,
                marginType,
                percentage: terrestrialPrice ?
                    parseFloat(parseFloat(terrestrialPrice.percentage_between ?? '1').toFixed(2)) :
                    parseFloat(parseFloat(stackPrice?.percentage_between ?? '1').toFixed(2)),
                vatAmount: terrestrialVatPrice ?
                    parseFloat(terrestrialVatPrice.selling_price) -
                    parseFloat(terrestrialVatPrice.purchase_price) :
                    parseFloat(stackVatPrice?.selling_price ?? '0') -
                    parseFloat(stackVatPrice?.purchase_price ?? '0'),
                vatCurrency: currency_list.find(currency => {
                    return currency.id === terrestrialVatPrice?.selling_currency;
                }) ?? currency_list.find((currency) => {
                    return currency.id === stackVatPrice?.selling_currency;
                }),
                currency: currency_list.find(currency => {
                    return currency.id === terrestrialPrice?.purchase_currency;
                }) ?? currency_list.find((currency) => {
                    return currency.id === stackPrice?.purchase_currency;
                }),
                sellingCurrency: currency_list.find(currency => {
                    return currency.id === terrestrialPrice?.selling_currency;
                }) ?? currency_list.find((currency) => {
                    return currency.id === stackPrice?.selling_currency;
                })
            },
            flightPrice: {
                id: flightPrice?.id,
                cost: parseFloat(flightPrice?.purchase_price ?? '0'),
                costConverted: parseFloat(flightPrice?.purchase_price ?? '0') * parseFloat(flightPrice?.factor_used ?? '1'),
                sellingCost: parseFloat(flightPrice?.selling_price ?? '0'),
                margin: parseFloat(flightPrice?.custom_value ?? '0'),
                marginType: flightMarginType,
                percentage: parseFloat(parseFloat(flightPrice?.percentage_between ?? '1').toFixed(2)),
                vatAmount: flightVatPrice ?
                    parseFloat(flightVatPrice.selling_price) -
                    parseFloat(flightVatPrice.purchase_price) :
                    0,
                vatCurrency: currency_list.find(currency => currency.id === flightVatPrice?.selling_currency),
                currency: currency_list.find(currency => {
                    return currency.id === flightPrice?.purchase_currency;
                }),
                sellingCurrency: currency_list.find(currency => {
                    return currency.id === flightPrice?.selling_currency;
                })
            },
            terrestrialProductsPrices,
            flightProductsPrices,
            terrestrialSurcom: {
                cost: parseFloat(surcomTerrestrial?.selling_price ?? '0') - parseFloat(surcomTerrestrial?.purchase_price ?? '0'),
                percent: parseFloat(surcomTerrestrial?.percentage_between ?? '0'),
                currency: currency_list.find(currency => {
                    return currency.id === surcomTerrestrial?.selling_currency;
                })
            },
            flightSurcom: {
                cost: parseFloat(surcomFlight?.selling_price ?? '0') - parseFloat(surcomFlight?.purchase_price ?? '0'),
                percent: parseFloat(surcomTerrestrial?.percentage_between ?? '0'),
                currency: currency_list.find(currency => {
                    return currency.id === surcomFlight?.selling_currency;
                })
            }
        };
    }, [
        trip,
        currency_list,
        props.stackNumber,
        props.stackInfoId,
        pricesAccommodations,
        pricesCars,
        pricesFlights,
        pricesManualProducts,
        pricesPois,
        pricesTransfers,
        user,
        getTaxesPrice
    ]);
    const providerId = useMemo(() => {
        return findLast(
            trip?.prices_flight?.filter((item) => {
                return isProductSamePackage(
                    item,
                    {
                        stack_number: props.stackNumber,
                        stack_info_id: props.stackInfoId
                    }
                );
            }) ?? [],
            (item) => !!item.provider
        )?.provider ?? findLast(
            trip?.prices_terrestrial?.filter((item) => {
                return isProductSamePackage(
                    item,
                    {
                        stack_number: props.stackNumber,
                        stack_info_id: props.stackInfoId
                    }
                );
            }) ?? [],
            (item) => !!item.provider
        )?.provider ?? findLast(
            trip?.prices?.filter((item) => {
                return isProductSamePackage(
                    {
                        stack_number: item.stack_number,
                        stack_info_id: null
                    },
                    {
                        stack_number: props.stackNumber,
                        stack_info_id: props.stackInfoId
                    }
                );
            }) ?? [],
            (item) => !!item.provider
        )?.provider;
    }, [trip]);
    const showError = useShowError();
    const updatePrices = useCartPackageUpdatePrices({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(trip) {
            enqueueSnackbar(
                t('cart-material.cart-construction-package-prices-updated'),
                { variant: 'success' }
            );
            dispatch({
                type: 'TRIP_SET_DATA_TRIP',
                payload: {
                    data_trip: trip
                }
            });
            setEditPrices(false);
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const updateProduct = useCartProductUpdate({
        onTrigger() {
            setLoading(true);
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const transformPrice = (
        target: 'FIX' | 'PER',
        price: typeof prices.terrestrialPrice | typeof prices.flightPrice
    ) => {
        const markupMargin = parseFloat((((price.sellingCost - price.costConverted) / price.sellingCost) * 100).toFixed(2));
        const isMarkup = price.percentage === markupMargin;
        if (target === 'FIX') {
            if (isMarkup) {
                return (price.percentage * price.costConverted) /
                    (100 - price.percentage);
            }
            return price.costConverted * (price.percentage / 100);
        }
        return price.percentage;
    };

    const onChangeTerrestrialPrice = (value: string) => {
        setPriceInputs((state) => {
            return {
                ...state,
                terrestrialPrice: {
                    ...state.terrestrialPrice,
                    cost: parseFloat(value)
                }
            };
        });
    };

    const onChangeTerrestrialPriceMarginType = (event: SelectChangeEvent<"FIX" | "PER">) => {
        setPriceInputs((state) => {
            let value = 0;
            value = event.target.value !== prices.terrestrialPrice.marginType ?
                transformPrice(event.target.value as 'FIX' | 'PER', prices.terrestrialPrice) :
                prices.terrestrialPrice.margin;
            return {
                ...state,
                terrestrialPrice: {
                    ...state.terrestrialPrice,
                    margin: parseFloat(value.toFixed(2)),
                    marginType: event.target.value as typeof prices.terrestrialPrice.marginType
                }
            };
        });
    };

    const onChangeTerrestrialPriceMargin = (value: string) => {
        setPriceInputs((state) => {
            return {
                ...state,
                terrestrialPrice: {
                    ...state.terrestrialPrice,
                    margin: parseFloat(value)
                }
            };
        });
    };

    const onChangeFlightPrice = (value: string) => {
        setPriceInputs((state) => {
            return {
                ...state,
                flightPrice: {
                    ...state.flightPrice,
                    cost: parseFloat(value)
                }
            };
        });
    };

    const onChangeFlightPriceMarginType = (event: SelectChangeEvent<"FIX" | "PER">) => {
        setPriceInputs((state) => {
            let value = 0;
            value = event.target.value !== prices.flightPrice.marginType ?
                transformPrice(event.target.value as 'FIX' | 'PER', prices.flightPrice) :
                prices.flightPrice.margin;
            return {
                ...state,
                flightPrice: {
                    ...state.flightPrice,
                    margin: parseFloat(value.toFixed(2)),
                    marginType: event.target.value as typeof prices.flightPrice.marginType
                }
            };
        });
    };

    const onChangeFlightPriceMargin = (value: string) => {
        setPriceInputs((state) => {
            return {
                ...state,
                flightPrice: {
                    ...state.flightPrice,
                    margin: parseFloat(value)
                }
            };
        });
    };

    const onSavePrices = () => {
        if (isNumber(props.stackNumber)) {
            if (providerId) {
                updatePrices({
                    tripVersion: parseInt(GetCookie('trip_id_version') ?? '-1'),
                    stackNumber: props.stackNumber,
                    stackInfoId: props.stackInfoId,
                    packageContent: {
                        providerId,
                        flightPrice: {
                            id: user?.client_full?.type === 2 ? priceInputs.flightPrice.id : undefined,
                            price: priceInputs.flightPrice.cost,
                            currency: priceInputs.flightPrice.currency?.id ?? 47,
                            margin: priceInputs.flightPrice.margin,
                            marginType: priceInputs.flightPrice.marginType ?? 'FIX'
                        },
                        terrestrialPrice: {
                            id: user?.client_full?.type === 2 ? priceInputs.terrestrialPrice.id : undefined,
                            price: priceInputs.terrestrialPrice.cost,
                            currency: priceInputs.terrestrialPrice.currency?.id ?? 47,
                            margin: priceInputs.terrestrialPrice.margin,
                            marginType: priceInputs.terrestrialPrice.marginType ?? 'FIX'
                        }
                    }
                });
            } else {
                updatePrices({
                    tripVersion: parseInt(GetCookie('trip_id_version') ?? '-1'),
                    stackNumber: props.stackNumber,
                    stackInfoId: props.stackInfoId,
                    packageContent: {
                        terrestrialPrice: {
                            id: priceInputs.terrestrialPrice.id,
                            price: priceInputs.terrestrialPrice.cost,
                            currency: priceInputs.terrestrialPrice.currency?.id ?? 47,
                            margin: priceInputs.terrestrialPrice.margin,
                            marginType: priceInputs.terrestrialPrice.marginType ?? 'FIX'
                        }
                    }
                });
            }

            const versionString = GetCookie("trip_id_version");
            const version = versionString ? parseInt(versionString) : -1;

            for (const item of pricesAccommodations ?? []) {
                if (item.type === 'normal') {
                    for (const room of item.accommodation.rooms) {
                        const price = getPrice(room.prices, 'master-price');
                        updateProduct(
                            version,
                            {
                                id: room.id,
                                type: 'accommodation',
                                isCustom: room.is_custom
                            },
                            {
                                prices: [
                                    {
                                        id: price.id,
                                        custom_rate: true,
                                        custom_rate_type: 'PER',
                                        custom_value: priceInputs.terrestrialPrice.margin,
                                        purchase_price: price.purchaseCost
                                    }
                                ]
                            }
                        );
                    }
                } else {
                    const price = getPrice(item.accommodation.prices, 'master-price');
                    updateProduct(
                        version,
                        {
                            id: item.accommodation.id,
                            type: 'manual-accommodation',
                            isCustom: item.accommodation.is_custom
                        },
                        {
                            prices: [
                                {
                                    id: price.id,
                                    custom_rate: true,
                                    custom_rate_type: 'PER',
                                    custom_value: priceInputs.terrestrialPrice.margin,
                                    purchase_price: price.purchaseCost
                                }
                            ]
                        }
                    );
                }
            }

            for (const item of pricesCars ?? []) {
                const price = getPrice(item.car.prices, 'master-price');
                updateProduct(
                    version,
                    {
                        id: item.car.id,
                        type: item.type === 'normal' ? 'car' : 'manual',
                        isCustom: item.car.is_custom
                    },
                    {
                        prices: [
                            {
                                id: price.id,
                                custom_rate: true,
                                custom_rate_type: 'PER',
                                custom_value: priceInputs.terrestrialPrice.margin,
                                purchase_price: price.purchaseCost
                            }
                        ]
                    }
                );
            }

            for (const item of pricesManualProducts ?? []) {
                const price = getPrice(item.prices, 'master-price');
                updateProduct(
                    version,
                    {
                        id: item.id,
                        type: 'manual',
                        isCustom: item.is_custom
                    },
                    {
                        prices: [
                            {
                                id: price.id,
                                custom_rate: true,
                                custom_rate_type: 'PER',
                                custom_value: priceInputs.terrestrialPrice.margin,
                                purchase_price: price.purchaseCost
                            }
                        ]
                    }
                );
            }

            for (const item of pricesPois ?? []) {
                const price = getPrice(item.poi.prices, 'master-price');
                updateProduct(
                    version,
                    {
                        id: item.poi.id,
                        type: item.type === 'normal' ? 'poi' : 'manual',
                        isCustom: item.poi.is_custom
                    },
                    {
                        prices: [
                            {
                                id: price.id,
                                custom_rate: true,
                                custom_rate_type: 'PER',
                                custom_value: priceInputs.terrestrialPrice.margin,
                                purchase_price: price.purchaseCost
                            }
                        ]
                    }
                );
            }

            for (const item of pricesTransfers ?? []) {
                const price = getPrice(item.transfer.prices, 'master-price');
                updateProduct(
                    version,
                    {
                        id: item.transfer.id,
                        type: item.type === 'normal' ? 'transfer' : 'manual',
                        isCustom: item.transfer.is_custom
                    },
                    {
                        prices: [
                            {
                                id: price.id,
                                custom_rate: true,
                                custom_rate_type: 'PER',
                                custom_value: priceInputs.terrestrialPrice.margin,
                                purchase_price: price.purchaseCost
                            }
                        ]
                    }
                );
            }

            for (const item of pricesFlights ?? []) {
                const price = getPrice(item.flight.prices, 'master-price');
                updateProduct(
                    version,
                    {
                        id: item.flight.id,
                        type: item.type === 'normal' ? 'flight' : 'manual',
                        isCustom: item.flight.is_custom
                    },
                    {
                        prices: [
                            {
                                id: price.id,
                                custom_rate: true,
                                custom_rate_type: 'PER',
                                custom_value: priceInputs.flightPrice.margin,
                                purchase_price: price.purchaseCost
                            }
                        ]
                    }
                );
            }
        }
    };

    useEffect(() => {
        setPriceInputs(prices);
    }, [prices]);

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                columnSpacing={1.5}
                rowSpacing={1}
            >
                <Grid item>
                    {
                        editPrices &&
                            user?.client_full?.type !== 2 ?
                            <FormControl
                                size="small"
                                variant={'outlined'}
                                disabled={!priceInputs.terrestrialPrice.currency}
                                fullWidth
                            >
                                <InputLabel>
                                    {t('cart-material.cart-construction-package-purchase-price-terrestrial')}
                                </InputLabel>
                                <OutlinedInput
                                    type={
                                        priceInputs.terrestrialPrice.currency ?
                                            'number' :
                                            'text'
                                    }
                                    label={t('cart-material.cart-construction-package-purchase-price-terrestrial')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {prices.terrestrialPrice.currency?.symbol}
                                        </InputAdornment>
                                    }
                                    value={
                                        priceInputs.terrestrialPrice.currency ?
                                            priceInputs.terrestrialPrice.cost :
                                            transformPricesToPrintableString(
                                                [
                                                    {
                                                        cost: prices.terrestrialPrice.cost,
                                                        currency: prices.terrestrialPrice.currency?.iso_code ?? 'EUR'
                                                    }
                                                ].concat(
                                                    prices.terrestrialProductsPrices.map((price) => {
                                                        return {
                                                            cost: price.purchaseCost,
                                                            currency: price.purchaseCurrency?.iso_code ?? 'EUR'
                                                        };
                                                    })
                                                ),
                                                i18n.language,
                                                'symbol'
                                            )
                                    }
                                    onChange={(event) => onChangeTerrestrialPrice(event.target.value)}
                                />
                            </FormControl> :
                            <Typography variant="caption">
                                <Trans
                                    i18nKey="cart-material.cart-construction-package-purchase-price-terrestrial-label"
                                    values={{
                                        price: transformPricesToPrintableString(
                                            [
                                                {
                                                    cost: prices.terrestrialPrice.cost,
                                                    currency: prices.terrestrialPrice.currency?.iso_code ?? 'EUR'
                                                }
                                            ].concat(
                                                prices.terrestrialProductsPrices.map((price) => {
                                                    return {
                                                        cost: price.purchaseCost,
                                                        currency: price.purchaseCurrency?.iso_code ?? 'EUR'
                                                    };
                                                })
                                            ),
                                            i18n.language
                                        ),
                                        priceConverted: new Intl.NumberFormat(
                                            i18n.language,
                                            {
                                                style: 'currency',
                                                currencyDisplay: 'code',
                                                currency: prices.terrestrialPrice.sellingCurrency?.iso_code ?? 'EUR'
                                            }
                                        ).format(
                                            prices.terrestrialPrice.costConverted +
                                            prices.terrestrialProductsPrices.reduce((prev, current) => {
                                                if (current.purchaseCurrency?.id !== current.currency?.id) {
                                                    return prev + current.purchaseCost * current.factorUsed;
                                                }
                                                return prev + current.purchaseCost;
                                            }, 0)
                                        )
                                    }}
                                    components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                                />
                            </Typography>
                    }
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ marginLeft: 1.5 }} />
                <Grid item>
                    {
                        editPrices ?
                            <FormControl
                                size="small"
                                variant={'outlined'}
                                fullWidth
                            >
                                <InputLabel>
                                    {t('cart-material.cart-construction-package-purchase-price-terrestrial-margin')}
                                </InputLabel>
                                <OutlinedInput
                                    type={'number'}
                                    label={t('cart-material.cart-construction-package-purchase-price-terrestrial-margin')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <FormControl
                                                disabled={prices.terrestrialProductsPrices.length > 0}
                                                fullWidth
                                            >
                                                <Select
                                                    size="small"
                                                    value={priceInputs.terrestrialPrice.marginType ?? 'FIX'}
                                                    onChange={(event) => onChangeTerrestrialPriceMarginType(event)}
                                                >
                                                    <MenuItem value="FIX">{priceInputs.terrestrialPrice.sellingCurrency?.symbol}</MenuItem>
                                                    <MenuItem value="PER">%</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </InputAdornment>
                                    }
                                    value={priceInputs.terrestrialPrice.margin}
                                    onChange={(event) => onChangeTerrestrialPriceMargin(event.target.value)}
                                    sx={{ paddingRight: 0 }}
                                />
                            </FormControl> :
                            <Typography variant="caption">
                                {
                                    (() => {
                                        let price = '';
                                        let priceValue = 0;

                                        if (prices.terrestrialProductsPrices.length > 0) {
                                            const total = transformPrice('FIX', prices.terrestrialPrice) +
                                                prices.terrestrialProductsPrices.reduce((prev, current) => {
                                                    return prev + transformPrice(
                                                        'FIX',
                                                        {
                                                            id: current.id ?? undefined,
                                                            cost: current.purchaseCost,
                                                            costConverted: current.purchaseCost * current.factorUsed,
                                                            currency: current.purchaseCurrency,
                                                            margin: current.margin,
                                                            marginType: current.marginType ?? undefined,
                                                            percentage: current.percentageBetween,
                                                            sellingCost: current.cost,
                                                            sellingCurrency: current.currency,
                                                            vatAmount: current.vatAmount,
                                                            vatCurrency: current.vatCurrency
                                                        }
                                                    );
                                                }, 0);
                                            price = new Intl.NumberFormat(
                                                i18n.language,
                                                {
                                                    style: 'currency',
                                                    currencyDisplay: 'code',
                                                    currency: prices.terrestrialProductsPrices[0]?.currency?.iso_code ??
                                                        prices.terrestrialPrice.sellingCurrency?.iso_code ?? 'EUR'
                                                }
                                            ).format(total);
                                            priceValue = total;
                                        } else if (prices.terrestrialPrice.marginType === 'FIX') {
                                            price = new Intl.NumberFormat(
                                                i18n.language,
                                                {
                                                    style: 'currency',
                                                    currencyDisplay: 'code',
                                                    currency: prices.terrestrialPrice.sellingCurrency?.iso_code ?? 'EUR'
                                                }
                                            ).format(prices.terrestrialPrice.margin);
                                            priceValue = prices.terrestrialPrice.margin;
                                        } else {
                                            price = new Intl.NumberFormat(
                                                i18n.language,
                                                {
                                                    style: 'currency',
                                                    currencyDisplay: 'code',
                                                    currency: prices.terrestrialPrice.sellingCurrency?.iso_code ?? 'EUR'
                                                }
                                            ).format(
                                                transformPrice('FIX', prices.terrestrialPrice)
                                            );
                                            priceValue = transformPrice('FIX', prices.terrestrialPrice);
                                        }

                                        let totalPrice = prices.terrestrialPrice.costConverted +
                                            prices.terrestrialProductsPrices.reduce((prev, current) => {
                                                if (current.purchaseCurrency?.id !== current.currency?.id) {
                                                    return prev + current.purchaseCost * current.factorUsed;
                                                }
                                                return prev + current.purchaseCost;
                                            }, 0);
                                        totalPrice = totalPrice > 0 ? totalPrice : 1;
                                        return (
                                            <Trans
                                                i18nKey="cart-material.cart-construction-package-purchase-price-terrestrial-margin-label"
                                                values={{
                                                    price,
                                                    percent: (
                                                        (
                                                            priceValue / totalPrice
                                                        ) * 100
                                                    ).toFixed(2)
                                                }}
                                                components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                                            />
                                        );
                                    })()
                                }
                            </Typography>
                    }
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ marginLeft: 1.5 }} />
                <Grid item>
                    {
                        editPrices &&
                            user?.client_full?.type !== 2 &&
                            prices.flightPrice.currency ?
                            <FormControl
                                size="small"
                                variant={'outlined'}
                                fullWidth
                            >
                                <InputLabel>
                                    {t('cart-material.cart-construction-package-purchase-price-flight')}
                                </InputLabel>
                                <OutlinedInput
                                    type={'number'}
                                    label={t('cart-material.cart-construction-package-purchase-price-flight')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {prices.flightPrice.currency?.symbol}
                                        </InputAdornment>
                                    }
                                    value={priceInputs.flightPrice.cost}
                                    onChange={(event) => onChangeFlightPrice(event.target.value)}
                                />
                            </FormControl> :
                            <Typography variant="caption">
                                <Trans
                                    i18nKey="cart-material.cart-construction-package-purchase-price-flight-label"
                                    values={{
                                        price: transformPricesToPrintableString(
                                            [
                                                {
                                                    cost: prices.flightPrice.cost,
                                                    currency: prices.flightPrice.currency?.iso_code ?? 'EUR'
                                                }
                                            ].concat(
                                                prices.flightProductsPrices.map((price) => {
                                                    return {
                                                        cost: price.purchaseCost,
                                                        currency: price.purchaseCurrency?.iso_code ?? 'EUR'
                                                    };
                                                })
                                            ),
                                            i18n.language
                                        ),
                                        priceConverted: new Intl.NumberFormat(
                                            i18n.language,
                                            {
                                                style: 'currency',
                                                currencyDisplay: 'code',
                                                currency: prices.flightPrice.sellingCurrency?.iso_code ?? 'EUR'
                                            }
                                        ).format(
                                            prices.flightPrice.costConverted +
                                            prices.flightProductsPrices.reduce((prev, current) => {
                                                if (current.purchaseCurrency?.id !== current.currency?.id) {
                                                    return prev + current.purchaseCost * current.factorUsed;
                                                }
                                                return prev + current.purchaseCost;
                                            }, 0)
                                        )
                                    }}
                                    components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                                />
                            </Typography>
                    }
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ marginLeft: 1.5 }} />
                <Grid item>
                    {
                        editPrices &&
                            (
                                prices.flightPrice.currency ||
                                prices.flightProductsPrices.length > 0
                            ) ?
                            <FormControl
                                size="small"
                                variant={'outlined'}
                                fullWidth
                            >
                                <InputLabel>
                                    {t('cart-material.cart-construction-package-purchase-price-flight-margin')}
                                </InputLabel>
                                <OutlinedInput
                                    type={'number'}
                                    label={t('cart-material.cart-construction-package-purchase-price-flight-margin')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <FormControl
                                                disabled={prices.flightProductsPrices.length > 0}
                                                fullWidth
                                            >
                                                <Select
                                                    size="small"
                                                    value={priceInputs.flightPrice.marginType ?? 'FIX'}
                                                    onChange={(event) => onChangeFlightPriceMarginType(event)}
                                                >
                                                    <MenuItem value="FIX">{priceInputs.flightPrice.sellingCurrency?.symbol}</MenuItem>
                                                    <MenuItem value="PER">%</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </InputAdornment>
                                    }
                                    value={priceInputs.flightPrice.margin}
                                    onChange={(event) => onChangeFlightPriceMargin(event.target.value)}
                                    sx={{ paddingRight: 0 }}
                                />
                            </FormControl> :
                            <Typography variant="caption">
                                {
                                    (() => {
                                        let price = '';
                                        let priceValue = 0;

                                        if (prices.flightProductsPrices.length > 0) {
                                            const total = transformPrice('FIX', prices.flightPrice) +
                                                prices.flightProductsPrices.reduce((prev, current) => {
                                                    return prev + transformPrice(
                                                        'FIX',
                                                        {
                                                            id: current.id ?? undefined,
                                                            cost: current.purchaseCost,
                                                            costConverted: current.purchaseCost * current.factorUsed,
                                                            currency: current.purchaseCurrency,
                                                            margin: current.margin,
                                                            marginType: current.marginType ?? undefined,
                                                            percentage: current.percentageBetween,
                                                            sellingCost: current.cost,
                                                            sellingCurrency: current.currency,
                                                            vatAmount: current.vatAmount,
                                                            vatCurrency: current.vatCurrency
                                                        }
                                                    );
                                                }, 0);
                                            price = new Intl.NumberFormat(
                                                i18n.language,
                                                {
                                                    style: 'currency',
                                                    currencyDisplay: 'code',
                                                    currency: prices.flightProductsPrices[0]?.currency?.iso_code ??
                                                        prices.flightPrice.sellingCurrency?.iso_code ?? 'EUR'
                                                }
                                            ).format(total);
                                            priceValue = total;
                                        } else if (prices.flightPrice.marginType === 'FIX') {
                                            price = new Intl.NumberFormat(
                                                i18n.language,
                                                {
                                                    style: 'currency',
                                                    currencyDisplay: 'code',
                                                    currency: prices.flightPrice.sellingCurrency?.iso_code ?? 'EUR'
                                                }
                                            ).format(prices.flightPrice.margin);
                                            priceValue = prices.flightPrice.margin;
                                        } else {
                                            price = new Intl.NumberFormat(
                                                i18n.language,
                                                {
                                                    style: 'currency',
                                                    currencyDisplay: 'code',
                                                    currency: prices.flightPrice.sellingCurrency?.iso_code ?? 'EUR'
                                                }
                                            ).format(
                                                transformPrice('FIX', prices.flightPrice)
                                            );
                                            priceValue = transformPrice('FIX', prices.flightPrice);
                                        }

                                        let totalPrice = prices.flightPrice.costConverted +
                                            prices.flightProductsPrices.reduce((prev, current) => {
                                                if (current.purchaseCurrency?.id !== current.currency?.id) {
                                                    return prev + current.purchaseCost * current.factorUsed;
                                                }
                                                return prev + current.purchaseCost;
                                            }, 0);
                                        totalPrice = totalPrice > 0 ? totalPrice : 1;
                                        return (
                                            <Trans
                                                i18nKey="cart-material.cart-construction-package-purchase-price-flight-margin-label"
                                                values={{
                                                    price,
                                                    percent: (
                                                        (
                                                            priceValue / totalPrice
                                                        ) * 100
                                                    ).toFixed(2)
                                                }}
                                                components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                                            />
                                        );
                                    })()
                                }
                            </Typography>
                    }
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ marginLeft: 1.5 }} />
                <Grid item>
                    {
                        (() => {
                            const taxes = prices.flightProductsPrices.reduce((prev, current) => {
                                const correspondingPrice = prev.find((item) => {
                                    return item.purchaseCurrency?.id === current.purchaseCurrency?.id;
                                });
                                if (correspondingPrice) {
                                    return prev.filter((item) => {
                                        return item.purchaseCurrency?.id !== current.purchaseCurrency?.id;
                                    }).concat([
                                        {
                                            ...current,
                                            taxes: correspondingPrice.taxes + current.taxes
                                        }
                                    ]);
                                }
                                return prev.concat([current]);
                            }, [] as typeof prices.flightProductsPrices);
                            const price = taxes.map((item) => {
                                return new Intl.NumberFormat(
                                    i18n.language,
                                    {
                                        style: 'currency',
                                        currencyDisplay: 'code',
                                        currency: item.purchaseCurrency?.iso_code ?? 'EUR'
                                    }
                                ).format(item.taxes);
                            }).join(', ');
                            return (
                                <Typography variant="caption">
                                    {t('flight_search.taxe_price')} :{' '}
                                    <strong>{price}</strong>
                                </Typography>
                            );
                        })()
                    }
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ marginLeft: 1.5 }} />
                <Grid item>
                    <Typography variant="caption">
                        <Trans
                            i18nKey="cart-material.cart-construction-package-terrestrial-vat-label"
                            values={{
                                price: new Intl.NumberFormat(
                                    i18n.language,
                                    {
                                        style: 'currency',
                                        currencyDisplay: 'code',
                                        currency: prices.terrestrialPrice.vatCurrency?.iso_code ?? 'EUR'
                                    }
                                ).format(prices.terrestrialPrice.vatAmount)
                            }}
                            components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                        />
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ marginLeft: 1.5 }} />
                <Grid item>
                    <Typography variant="caption">
                        <Trans
                            i18nKey="cart-material.cart-construction-package-flight-vat-label"
                            values={{
                                price: new Intl.NumberFormat(
                                    i18n.language,
                                    {
                                        style: 'currency',
                                        currencyDisplay: 'code',
                                        currency: prices.flightPrice.vatCurrency?.iso_code ?? 'EUR'
                                    }
                                ).format(prices.flightPrice.vatAmount)
                            }}
                            components={{ span: <span style={{ fontWeight: 'bold' }} /> }}
                        />
                    </Typography>
                </Grid>
                {
                    user?.client_full?.type !== 2 &&
                    prices.terrestrialSurcom.currency &&
                    !['cercledesvoyages', 'continentsinsolites', 'touristforum', 'terreslointaines', 'travelmuz'].includes(quotation_code) && (
                        <>
                            <Divider orientation="vertical" flexItem />
                            <Grid item>
                                <Typography variant="caption">
                                    {
                                        quotation_code === 'verdie' ?
                                            t('cart-material.assistance-terrestrial-to-in-value') :
                                            t('cart-material.surcom-terrestrial-to-in-value')
                                    } :{' '}
                                    <strong>
                                        {
                                            prices.terrestrialSurcom.cost.toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }{' '}
                                        {prices.terrestrialSurcom.currency.iso_code}
                                        {' '}(
                                        {
                                            prices.terrestrialSurcom.percent.toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }
                                        ) %
                                    </strong>
                                </Typography>
                            </Grid>
                        </>
                    )
                }
                {
                    user?.client_full?.type !== 2 &&
                    prices.flightSurcom.currency &&
                    !['cercledesvoyages', 'continentsinsolites', 'touristforum', 'terreslointaines', 'travelmuz'].includes(quotation_code) && (
                        <>
                            <Divider orientation="vertical" flexItem />
                            <Grid item>
                                <Typography variant="caption">
                                    {
                                        quotation_code === 'verdie' ?
                                            t('cart-material.assistance-flight-to-in-value') :
                                            t('cart-material.surcom-flight-to-in-value')
                                    } :{' '}
                                    <strong>
                                        {
                                            prices.flightSurcom.cost.toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }{' '}
                                        {prices.flightSurcom.currency.iso_code}
                                        {' '}(
                                        {
                                            prices.flightSurcom.percent.toLocaleString(
                                                language,
                                                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                            )
                                        }
                                        ) %
                                    </strong>
                                </Typography>
                            </Grid>
                        </>
                    )
                }
                {
                    (
                        isNumber(providerId) ||
                        prices.terrestrialPrice.currency ||
                        prices.terrestrialProductsPrices.length > 0 ||
                        prices.flightProductsPrices.length > 0
                    ) &&
                    <>
                        {
                            editPrices ? (
                                <>
                                    <Grid item>
                                        <Button
                                            size="small"
                                            sx={{ marginRight: 1 }}
                                            onClick={() => setEditPrices(false)}
                                        >
                                            {t('global.cancel')}
                                        </Button>
                                        <Button
                                            size="small"
                                            variant={'outlined'}
                                            onClick={onSavePrices}
                                        >
                                            {t('global.validate')}
                                        </Button>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item>
                                    <Button
                                        size="small"
                                        variant={'outlined'}
                                        onClick={() => setEditPrices(true)}
                                    >
                                        {t('global.edit')}
                                    </Button>
                                </Grid>
                            )
                        }
                    </>
                }
                {
                    !isNumber(providerId) &&
                    !prices.terrestrialPrice.currency &&
                    prices.terrestrialProductsPrices.length === 0 &&
                    prices.flightProductsPrices.length === 0 &&
                    <Grid item xs={12}>
                        <Alert severity="warning" sx={{ display: 'inline-flex', marginTop: 2 }}>
                            {t('cart-material.cart-construction-package-no-provider')}
                        </Alert>
                    </Grid>
                }
            </Grid>
            <LoadingBackDrop open={loading} />
        </>
    );
}

function transformPricesToPrintableString(
    prices: { cost: number, currency: string }[],
    language: string,
    currencyDisplay: NonNullable<Parameters<typeof Intl.NumberFormat>[1]>['currencyDisplay'] = 'code'
): string {
    return prices.reduce((prev, current) => {
        const correspondingIndex = prev.findIndex((item) => {
            return item.currency === current.currency;
        });

        if (correspondingIndex >= 0) {
            return [
                ...prev.slice(0, correspondingIndex),
                {
                    currency: prev[correspondingIndex]!.currency,
                    cost: prev[correspondingIndex]!.cost + current.cost
                },
                ...prev.slice(correspondingIndex + 1)
            ];
        }

        return prev.concat([
            {
                cost: current.cost,
                currency: current.currency
            }
        ]);
    }, [] as { cost: number, currency: string }[]).map((item) => {
        return new Intl.NumberFormat(
            language,
            {
                style: 'currency',
                currencyDisplay,
                currency: item.currency
            }
        ).format(item.cost);
    }).join(' + ');
}
