import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    formControlRootAutoComplete: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15,
            paddingTop: 10
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 15,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    formControlRootAutoCompleteMobile: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 10
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    formControlRootAutoCompleteSmall: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 10
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, -1px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    formControlRootAutoCompleteRed: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 15,
            paddingTop: 10
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 15,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    formControlRootAutoCompleteRedMobile: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 10
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    formControlRootAutoCompleteRedSmall: {
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 10
            //zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(14px, -1px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    },
        "& .mui-jss-MuiInputLabel-shrink": {
            fontWeight: 400,
            fontSize: 12,
            paddingTop: 0
        },
        "& .mui-jss-MuiAutocomplete-endAdornment": {
            paddingRight: 15
        }
    },
    noWrap: {
        whiteSpace: "nowrap"
    },
    outlinedInput: {
        padding: "7px 30px 7px 20px;"
    },
    circleBorder: {
        borderRadius: 8
    }
}));


const CountrySearch = (({ label, traveler, tempTravelers, setTempTravelers, tempContactLead, setTempContactLead, contact_lead_option, mode, redFormControl, size, isFrom }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const data_trip = useSelector((store) => store.trip.data_trip);

    const [countryInput, setCountryInput] = useState("");
    const [countryResults, setCountryResults] = useState([]);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const isDisabled = ["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'travelmuz'].includes(quotation_code) ? (data_trip !== null && data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV" && isFrom === "tripList") : false;

    useEffect(() =>{
        if (countryInput.length >= 3) {
            let { pass_check, headers } = CheckBeforeRequest();
            if (pass_check) {
                axios({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}iata-countries/?search=${countryInput}`
                }).then(function (response) {
                    let data = response.data.results;
                    if (data.length > 0) {
                        setCountryResults(data);
                    }
                }).catch(function (error) {
                    console.log(error.responseJSON);
                });
            }
        }
    }, [countryInput]);

    const handleIdentityDocCountry = (value, traveler) => {
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                traveler.identity_doc_country_emission = value;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }

        if (traveler.id === tempContactLead.id) {
            tempContactLead.identity_doc_country_emission = value;
        }
    };

    const handleCountry = (value, traveler) => {
        if (contact_lead_option || traveler.id === tempContactLead.id) {
            let new_contact_lead = Object.assign({}, traveler);
            new_contact_lead.country = value;
            new_contact_lead.address = tempContactLead.address;
            new_contact_lead.address_details = tempContactLead.address_details;
            new_contact_lead.city = tempContactLead.city;
            new_contact_lead.postal_code = tempContactLead.postal_code;
            setTempContactLead(new_contact_lead);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let age_label = tempTravelers[traveler_index].age_label;
                let room_label = tempTravelers[traveler_index].room_label;
                let identity_doc_country_emission = tempTravelers[traveler_index].identity_doc_country_emission;
                let identity_doc_number = tempTravelers[traveler_index].identity_doc_number;
                let identity_doc_expiration = tempTravelers[traveler_index].identity_doc_expiration;
                let nationality = tempTravelers[traveler_index].nationality;
                let newTravelers = tempTravelers.slice();
                traveler.country = value;
                traveler.age_label = age_label;
                traveler.room_label = room_label;
                traveler.identity_doc_country_emission = identity_doc_country_emission;
                traveler.identity_doc_number = identity_doc_number;
                traveler.identity_doc_expiration = identity_doc_expiration;
                traveler.nationality = nationality;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    const handleDemonym = (value, traveler) => {
        if (contact_lead_option || traveler.id === tempContactLead.id) {
            let new_contact_lead = Object.assign({}, traveler);
            new_contact_lead.nationality = value;
            new_contact_lead.address = tempContactLead.address;
            new_contact_lead.address_details = tempContactLead.address_details;
            new_contact_lead.city = tempContactLead.city;
            new_contact_lead.postal_code = tempContactLead.postal_code;
            setTempContactLead(new_contact_lead);
        }
        for (traveler_index = 0; traveler_index < tempTravelers.length; traveler_index++) {
            if (tempTravelers[traveler_index].id === traveler.id) {
                let newTravelers = tempTravelers.slice();
                traveler.nationality = value;
                newTravelers[traveler_index] = traveler;
                setTempTravelers(newTravelers);
            }
        }
    };

    if (downDesktop) {
        if (mode === "identity-doc") {
            return (
                <Autocomplete
                    disabled={isDisabled}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => {
                        if (option === "") {
                            return "";
                        }
                        return (option.name !== undefined && option.name !== null ? option.name : option.international_name);
                    }}
                    onInputChange={((event, value) => {
                        setCountryInput(value); 
                    }) }
                    onChange={((event, value) => {
                        handleIdentityDocCountry(value, traveler);
                    })}
                    value={traveler.identity_doc_country_emission}
                    noOptionsText={t("menu.profile.no_country_found")}
                    options={countryResults}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label={label}
                            className={ size === "small" ? (redFormControl ? classes.formControlRootAutoCompleteRedSmall : classes.formControlRootAutoCompleteSmall) : (redFormControl ? classes.formControlRootAutoCompleteRedMobile : classes.formControlRootAutoCompleteMobile) }
                            InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                            inputProps={{
                                ...props.inputProps,
                                autoComplete: 'off' //disable autocomplete and autofill
                            }}
                            variant="outlined"
                            size="small"
                        />
                    )}
                />
            );
        } else if (mode === "country") {
            return (
                <Autocomplete
                    disabled={isDisabled}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => {
                        if (option === "") {
                            return "";
                        }
                        return (option.name !== undefined && option.name !== null ? option.name : option.international_name);
                    }}
                    onInputChange={((event, value) => {
                        setCountryInput(value); 
                    }) }
                    onChange={((event, value) => {
                        handleCountry(value, traveler);
                    })}
                    value={traveler.country}
                    noOptionsText={t("menu.profile.no_country_found")}
                    options={countryResults}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label={label}
                            className={size === "small" ? classes.formControlRootAutoCompleteSmall : classes.formControlRootAutoCompleteMobile}
                            InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                            inputProps={{
                                ...props.inputProps,
                                autoComplete: 'off' //disable autocomplete and autofill
                            }}
                            variant="outlined"
                            size="small"
                        />
                    )}
                />
            );
        } else if (mode === "demonym") {
            return (
                <Autocomplete
                    disabled={isDisabled}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => {
                        if (option === "") {
                            return "";
                        }
                        return (option.nationality !== undefined && option.nationality !== null ? option.nationality : option.international_nationality);
                    }}
                    onInputChange={((event, value) => {
                        setCountryInput(value); 
                    }) }
                    onChange={((event, value) => {
                        handleDemonym(value, traveler);
                    })}
                    value={traveler.nationality}
                    noOptionsText={t("menu.profile.no_country_found")}
                    options={countryResults}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label={label}
                            className={size === "small" ? (redFormControl ? classes.formControlRootAutoCompleteRedSmall : classes.formControlRootAutoCompleteSmall) : (redFormControl ? classes.formControlRootAutoCompleteRedMobile : classes.formControlRootAutoCompleteMobile)}
                            InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                            inputProps={{ ...props.inputProps,
                                autoComplete: 'off' //disable autocomplete and autofill
                            }}
                            variant="outlined"
                            size="small"
                        />
                    )}
                />
            );
        }
    } else if (mode === "identity-doc") {
        return (
            <Autocomplete
                disabled={isDisabled}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => {
                    if (option === "") {
                        return "";
                    }
                    return (option.name !== undefined && option.name !== null ? option.name : option.international_name);
                }}
                onInputChange={((event, value) => {
                    setCountryInput(value); 
                }) }
                onChange={((event, value) => {
                    handleIdentityDocCountry(value, traveler);
                })}
                value={traveler.identity_doc_country_emission}
                noOptionsText={t("menu.profile.no_country_found")}
                options={countryResults}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        label={label}
                        className={ size === "small" ? (redFormControl ? classes.formControlRootAutoCompleteRedSmall : classes.formControlRootAutoCompleteSmall) : (redFormControl ? classes.formControlRootAutoCompleteRed : classes.formControlRootAutoComplete) }
                        InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                        inputProps={{
                            ...props.inputProps,
                            autoComplete: 'off' //disable autocomplete and autofill
                        }}
                        variant="outlined"
                        size="small"
                    />
                )}
            />
        );
    } else if (mode === "country") {
        return (
            <Autocomplete
                disabled={isDisabled}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => {
                    if (option === "") {
                        return "";
                    }
                    return (option.name !== undefined && option.name !== null ? option.name : option.international_name);
                }}
                onInputChange={((event, value) => {
                    setCountryInput(value); 
                }) }
                onChange={((event, value) => {
                    handleCountry(value, traveler);
                })}
                value={traveler.country}
                noOptionsText={t("menu.profile.no_country_found")}
                options={countryResults}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        label={label}
                        className={size === "small" ? classes.formControlRootAutoCompleteSmall : classes.formControlRootAutoComplete}
                        InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                        inputProps={{
                            ...props.inputProps,
                            autoComplete: 'off' //disable autocomplete and autofill
                        }}
                        variant="outlined"
                        size="small"
                    />
                )}
            />
        );
    } else if (mode === "demonym") {
        return (
            <Autocomplete
                disabled={isDisabled}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => {
                    if (option === "") {
                        return "";
                    }
                    return (option.nationality !== undefined && option.nationality !== null ? option.nationality : option.international_nationality);
                }}
                onInputChange={((event, value) => {
                    setCountryInput(value); 
                }) }
                onChange={((event, value) => {
                    handleDemonym(value, traveler);
                })}
                value={traveler.nationality}
                noOptionsText={t("menu.profile.no_country_found")}
                options={countryResults}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        label={label}
                        className={size === "small" ? (redFormControl ? classes.formControlRootAutoCompleteRedSmall : classes.formControlRootAutoCompleteSmall) : (redFormControl ? classes.formControlRootAutoCompleteRed : classes.formControlRootAutoComplete)}
                        InputProps={{ ...props.InputProps, className: classes.circleBorder }}
                        inputProps={{ ...props.inputProps,
                            autoComplete: 'off' //disable autocomplete and autofill
                        }}
                        variant="outlined"
                        size="small"
                    />
                )}
            />
        );
    }
});

export default React.memo(CountrySearch);
