import React from "react";
import { useSelector } from "react-redux";
import { MailTemplateVisualEditor } from "../Menu/MaterialTripList/MailVisualEditor/mailTemplateVisualEditor";
import { MailTemplateVisualEditorDragArea } from "../Menu/MaterialTripList/MailVisualEditor/mailTemplateVisualEditorDragArea";
import { ItineraryContentItem } from "./objects/itineraryContentItem";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    id: number,
    mode: ItineraryContentItem['mode'],
    locale: number,
    edit: boolean,
    customActions?: Parameters<typeof MailTemplateVisualEditor>[number]['customActions'],
    onContentClick?: Parameters<typeof MailTemplateVisualEditor>[number]['onContentClick'],
}

export const CartConstructionContentsFormLongDescriptionInput = React.memo(
    function (props: Props): JSX.Element | null {
        const step = useSelector((state: AppState) => state.cart.step);

        return (
            <MailTemplateVisualEditor
                instanceId={
                    props.mode === 'by-day' ?
                        `cart-construction-by-day-long-description-${props.locale}-${props.id}` :
                        `cart-construction-by-step-long-description-${props.locale}-${props.id}`
                }
                locale={props.locale}
                renderContentArea={() => (
                    <MailTemplateVisualEditorDragArea /> 
                )}
                customActions={props.customActions}
                onContentClick={props.onContentClick}
                forceSelectedState={props.edit}
                isIdle={step !== 2}
                enableLeftDrag
                disableAutoCreation
                disablePreview
                disableHeader
                disableHoverInteraction
                useCustomDndContext
            />
        );
    }
);
