import React from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Divider from "@material-ui/core/Divider";
import ScheduleIcon from '@material-ui/icons/Schedule';
import FlightIcon from '@material-ui/icons/Flight';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';


import moment from "moment";
import ConvertTime from "../../FlightMaterial/Functions/ConvertTime";

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: '#0000008A',
        letterSpacing: 1.25
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight900: {
        fontWeight: 900
    },
    bold: {
        fontWeight: "bold"
    },
    fontSize8: {
        fontSize: 8
    },
    fontSize9: {
        fontSize: 9
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    lightGrey: {
        color: '#00000040'
    },
    black: {
        color: "#000000"
    },
    red: {
        color: "#FF0000"
    },
    uppercase: {
        textTransform: "uppercase"
    },
    textCenter: {
        textAlign: "center"
    },
    spacer: {
        padding: "8px 0px"
    },
    spacerSmall:{
        padding: 4
    },
    marginIcon: {
        marginTop: 6
    },
    borderTableCell:{
        borderBottom: "none"
    },
    durationDaysPosition: {
        position: "relative",
        bottom: 28,
        left: 47
    },
    durationDaysPositionSmall: {
        position: "relative",
        bottom: 23,
        left: 32
    }
}))

const RenderLegDetailMobile = ({ leg, leg_index, max_index, stopover_duration, duration_days, small }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const language = useSelector(store => store.header.language);

    const airlineObj = leg.operating_airline;

    moment.locale(language);

    return(
        <Grid className={classes.spacer}>
            <Grid container alignItems="baseline" justify='flex-start' spacing={1} className={classes.spacerSmall}>
                <Grid item>
                    <img src={ "https://facilitatrip-staging-picture.s3.eu-west-3.amazonaws.com/iata-company/" + airlineObj.airline_code + ".png" } alt={ "provider logo" } style={{ maxWidth: small ? 50 : 90, maxHeight: 25 }} />
                </Grid>
                <Grid item className={`${classes.genericText} ${classes.bold} ${classes.fontSize8} ${classes.uppercase}`}>
                    { t("flight_search.operate_by") + airlineObj.commercial_name + " - " + airlineObj.airline_code + " " }
                    { leg.operating_flight_number !== undefined && leg.operating_flight_number }
                    { leg.operating_flight_number === undefined && leg.flight_number !== undefined && leg.flight_number}
                </Grid>
            </Grid>
            <TableContainer>
                <Table style={{ overflow: "hidden"}}>
                    <TableBody>
                        <TableRow>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid item className={`${classes.genericText} ${classes.bold} ${classes.fontSize12} ${classes.uppercase}`}>
                                    { t("flight_search.departure") }
                                </Grid>
                            </TableCell>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.red}`}>
                                    { leg.origin!== undefined && leg.origin !== null ? leg.origin.airport_code : leg.origin_station.station_code }
                                    <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                                    {
                                        leg.origin !== undefined && leg.origin !== null
                                        ? leg.origin.name !== undefined ? leg.origin.name : leg.origin.international_name
                                        : leg.origin_station.name !== undefined ? leg.origin_station.name : leg.origin_station.international_name
                                    }
                                    { leg.origin_terminal !== null && leg.origin_terminal !== "No data" ? " " + t("flight_search.terminal") + " " + leg.origin_terminal : "" }
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid className={`${classes.genericText} ${classes.fontWeight500} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.black} ${classes.uppercase}`} style={small ? { paddingLeft: 9 } :{ paddingLeft: 15 }}>
                                    { moment.utc(leg.departure_time).format("DD MMM") }
                                </Grid>
                            </TableCell>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.black} ${classes.uppercase}`}>
                                    { language === "fr" ? moment.utc(leg.departure_time).format("HH[h]mm") : moment.utc(leg.departure_time).format("HH[:]mm")}
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                                    { t("global.flight") + " " + (leg_index + 1) }
                                </Grid>
                            </TableCell>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid container alignItems="center" justify="space-between" direction="column">
                                    <Grid item>
                                        <Grid container alignItems="center" justify="flex-start" spacing={small ? 1 : 2}>
                                            <Grid item style={{ paddingRight: 0 }}>
                                                <ScheduleIcon className={`${classes.marginIcon} ${classes.genericText} ${small ? classes.fontSize12 : classes.fontSize14}`} />
                                            </Grid>
                                            <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                { ConvertTime(leg.flight_time, "minutes", language, t) }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        leg.aircraft !== undefined && leg.aircraft !== null && (
                                            <Grid item>
                                                <Grid container alignItems="center" justify="flex-start" spacing={small ? 1 : 2}>
                                                    <Grid item style={{ paddingRight: 0 }}>
                                                        <FlightIcon className={`${classes.marginIcon} ${classes.genericText} ${small ? classes.fontSize12 : classes.fontSize14}`} />
                                                    </Grid>
                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                        { leg.aircraft !== undefined && leg.aircraft.commercial_name }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    {
                                        leg.cabin_class !== undefined && leg.cabin_class !== null && (
                                            <Grid item>
                                                <Grid container alignItems="center" justify="flex-start" spacing={small ? 1 : 2}>
                                                    <Grid item style={{ paddingRight: 0 }}>
                                                        <AirlineSeatReclineExtraIcon className={`${classes.marginIcon} ${classes.genericText} ${small ? classes.fontSize12 : classes.fontSize14}`} />
                                                    </Grid>
                                                    <Grid item className={`${classes.genericText} ${classes.fontWeight900} ${classes.fontSize9} ${classes.uppercase}`}>
                                                        { t(`flight_search.cabin_class.${leg.cabin_class.toLowerCase()}`) }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                                    { t("flight_search.arrival") }
                                </Grid>
                            </TableCell>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.red}`}>
                                    { leg.destination !== undefined && leg.destination !== null ?  leg.destination.airport_code : leg.destination_station.station_code }
                                    <Grid className={`${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase}`}>
                                    {
                                        leg.destination !== undefined && leg.destination !== null
                                        ?  leg.destination.name !== undefined ?  leg.destination.name :  leg.destination.international_name
                                        : leg.destination_station.name !== undefined ? leg.destination_station.name : leg.destination_station.international_name
                                    }
                                    { leg.destination_terminal !== null && leg.destination_terminal !== "No data" ? " " + t("flight_search.terminal") + " " + leg.destination_terminal : "" }
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid
                                    className={`${classes.genericText} ${classes.fontWeight500} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.black} ${classes.uppercase}`}
                                    style={ (leg_index === max_index && duration_days !== undefined && duration_days !== 0) ? (small ? { marginBottom: -13} : { marginBottom: -12 }) : {}}>
                                    { moment.utc(leg.arrival_datetime_lt).format("DD MMM")}
                                    {
                                        leg_index === max_index && duration_days !== undefined && duration_days !== 0 && (
                                            <div className={`${classes.genericText} ${classes.fontSize9} ${classes.red} ${small ? classes.durationDaysPositionSmall : classes.durationDaysPosition}`}>
                                                { "+" + duration_days + t("global.abrev_day")}
                                            </div>
                                        )
                                    }
                                </Grid>
                            </TableCell>
                            <TableCell padding="none" className={`${classes.borderTableCell} ${classes.textCenter}`}>
                                <Grid className={`${classes.genericText} ${classes.fontWeight900} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.black} ${classes.uppercase}`}>
                                    { language === "fr" ? moment.utc(leg.arrival_datetime_lt).format("HH[h]mm") : moment.utc(leg.arrival_datetime_lt).format("HH[:]mm")}
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {
                leg_index !== max_index && (
                    <Grid className={small ? classes.spacerSmall : classes.spacer}>
                        <Divider />
                        <Grid className={`${small ? classes.spacerSmall : classes.spacer} ${classes.genericText} ${classes.bold} ${small ? classes.fontSize9 : classes.fontSize14} ${classes.uppercase} ${classes.textCenter}`}>
                            <div className={classes.genericText}>
                                {
                                    leg.destination !== undefined && leg.destination !== null
                                    ? t("flight_search.change_airport") + leg.destination.iata_city.international_name + " - "
                                    : t("flight_search.change_train") + leg.destination_station.iata_city.international_name + " - "
                                }
                                <span className={classes.black}>
                                {
                                    leg.destination !== undefined && leg.destination !== null
                                    ?  leg.destination.name !== undefined ?  leg.destination.name :  leg.destination.international_name
                                    : leg.destination_station.name !== undefined ? leg.destination_station.name : leg.destination_station.international_name
                                }
                                { leg.destination_terminal !== null && leg.destination_terminal !== "No data" ? " " + t("flight_search.terminal") + " " + leg.destination_terminal : "" }                                </span>
                            </div>
                            <div className={classes.genericText}>
                                { t("flight_search.stopover_duration")}
                                <span className={classes.black}>
                                    {moment.utc(stopover_duration).format('HH[h]mm')}
                                </span>
                            </div>
                            <div className={classes.genericText}>
                                { t("flight_search.stopover_change_airport")}
                            </div>
                        </Grid>
                        <Divider />
                    </Grid>
                )
            }
        </Grid>
    )
}

export default React.memo(RenderLegDetailMobile);