import { cloneDeep, isEmpty } from "lodash";

//---- Functions ----//
import OriginAirportSelected from "../../Flight/FlightGroups/Functions/OriginAirportSelected.js";
import { findOutbounds } from "../../Itinerary/utils/findOutbounds";

//---- Actions ----//
import {
    AddJourneyToflightFromItinerary,
    SetJourneyType,
    SetMultiDestStartDate,
    FillMultiDest
} from "../../../Actions/Flight";
import { SetAirportError } from "../../../Actions/Itinerary.js";

//eslint-disable-next-line max-params
export default async (itinerary, journey_type, start_date, dispatch, departure_destination, return_destination, t, shouldBeForArrivalDates) => {
    let itinerary_list = cloneDeep(itinerary);
    let current_journey_type = journey_type;
    //Create Journey tab && create a tmp transport list with only valid transport (type 2)
    //(First argument) 1: round trip, 2: multi destination, 3: one way
    let step_destination = itinerary_list.filter((iti) => iti.step_type === 'STEP');
    let start_end_destination = itinerary_list.filter((iti) => (iti.step_type === 'START' || iti.step_type === 'END') && iti.city_name !== null);
    if (start_end_destination.length === 0 && step_destination.length === 2) {
        dispatch(SetJourneyType(3, 0));
        current_journey_type = 3;
    } else if (start_end_destination.length === 0 && step_destination.length === 3 && step_destination[0].destination.id === step_destination[step_destination.length - 1].destination.id) {
        dispatch(SetJourneyType(1, 0));
    } else if (start_end_destination.length !== 0 && itinerary_list.length === 3 && itinerary_list[0].city_name === itinerary_list[itinerary_list.length - 1].city_name) {
        dispatch(SetJourneyType(1, 0));
    } else {
        dispatch(SetJourneyType(journey_type, 0)); 
    }

    let journey_tab = [];
    let journey_arr = {
	        start_date: null,
	        origin: null,
	        destination: null,
	        class_type: 0,
            check_destination: false
    };
    for (let k = 0; k < itinerary_list.length; k++) {
	    if (
            !isEmpty(itinerary_list[k].r2r_json) &&
            // !itinerary_list[k].r2r_json.noTransport &&
            itinerary_list[k].r2r_json.type === 2 &&
            itinerary_list[k].r2r_json.vehicle.kind === "plane"
        ) {
	        journey_tab.push(journey_arr);
	        itinerary_list[k].r2r_json.index = k;
	    }
    }
    //merge air segments
    itinerary_list = itinerary_list.map((step) => {
        return {
            ...step,
            r2r_json: {
                ...step.r2r_json,
                segments: step.r2r_json?.segments?.reduce((prev, current) => {
                    const airSegmentIndex = prev.findIndex((item) => {
                        return item.segmentKind === 'air';
                    });
    
                    if (current.segmentKind === 'air' && airSegmentIndex >= 0) {
                        const result = [...prev];
                        const airSegment = result[airSegmentIndex];
                        result.splice(
                            airSegmentIndex,
                            1,
                            {
                                ...current,
                                depPlace: airSegment.depPlace,
                                arrPlace: current.arrPlace
                            }
                        );
                        return result;
                    }
    
                    return prev.concat([current]);
                }, []) ?? []
            }
        };
    });
    if (journey_tab.length >= 2) {
	    dispatch(AddJourneyToflightFromItinerary(journey_tab, 0));
    }
    //Fill journey Tab
    const outbounds = await findOutbounds({
        itinerary: itinerary_list,
        departureStep: departure_destination,
        returnStep: return_destination,
        shouldBeForArrivalDates
    });
    if (outbounds.length === 1) {
        dispatch(SetJourneyType(3, 0)); 
    }
    for (const outbound of outbounds) {
        let airport1 = outbound.a;
        let airport2 = outbound.b;

        const index = outbound.index;

        if (airport1) {
            OriginAirportSelected(current_journey_type, airport1, 0, "origin", index, dispatch);
        } else {
            dispatch(
                SetAirportError(
                    true,
                    t("itinerary.error.error_iata_not_found") + outbound.departurePlace?.shortName + " " + outbound.departurePlace?.code
                )
            );
        }
        if (airport2) {
            OriginAirportSelected(journey_type, airport2, 0, "destination", index, dispatch);
        } else {
            dispatch(
                SetAirportError(
                    true,
                    t("itinerary.error.error_iata_not_found") + outbound.arrivalPlace?.shortName + " " + outbound.arrivalPlace?.code
                )
            );
        }
        dispatch(SetMultiDestStartDate(window.moment.utc(outbound.date), 0, index));
    }

    dispatch(FillMultiDest());
};
