import { useDispatch } from "react-redux";
import { flatten, isNumber } from "lodash";
import { getLocalizedValue } from "../../Itinerary/utils/getLocalizedValue";
import { getQuickDestination } from "../../Itinerary/utils/getQuickDestination";
import { htmlHasEmptyContent } from "../../Menu/MaterialTripList/utils/htmlHasEmptyContent";
import { findDestinationGuideDescription } from "../../Menu/MaterialTripList/utils/findDestinationGuideDescription";
import { setAutoloadingTexts } from "../redux/cartConstructionReducer";
import { Picture } from "../../Menu/MaterialTripList/picture/objects/picture";
import { ItineraryContents } from "../objects/itineraryContents";
import { ItineraryByDay } from "../../Itinerary/objects/itineraryByDay";
import { ItineraryByStep } from "../../Itinerary/objects/itineraryByStep";

type Options = {
    locale: number,
    onChangeItemTitle: (id: number, value: string) => void,
    onChangeItemText: (id: number, texts: string[]) => void,
    onChangeItemLongText: (id: number, texts: string[]) => void,
    onChangeItemPictures: (id: number, pictures: Picture[]) => void,
}

type Callback = (
    data: ItineraryContents,
    options: Options
) => Promise<boolean>

export function useAutoloadContents(): Callback {
    const dispatch = useDispatch();

    return async (data, options) => {
        dispatch(setAutoloadingTexts(true));

        let changed = false;

        //autoload titles
        for (const item of data.content) {
            let destination = '';
            if (data.mode === 'by-day') {
                destination = (item as ItineraryByDay).destinations?.map((destination) => {
                    let result = getLocalizedValue(
                        options.locale,
                        destination.data ?? {
                            name: '',
                            localization: []
                        },
                        'name'
                    ) ?? '';
                    result = result.length > 0 ?
                        result :
                        (
                            destination.data?.international_name ?? ''
                        );
                    return result;
                }).join(' - ') ?? '';
            } else {
                destination = getLocalizedValue(
                    options.locale,
                    (item as ItineraryByStep).destination?.data ?? {
                        name: '',
                        localization: []
                    },
                    'name'
                ) ?? '';
                destination = destination.length > 0 ?
                    destination :
                    (
                        (item as ItineraryByStep).destination?.data?.international_name ?? ''
                    );
            }
            const title = item.localization.find((item) => {
                return item.locale === options.locale;
            })?.title;
            if ((title?.trim().length ?? 0) === 0) {
                options.onChangeItemTitle(item.id, destination);
                changed = true;
            }
        }

        //autoload pictures
        for (const item of data.content) {
            if (!item.user_deleted_picture) {
                if (data.mode === 'by-day') {
                    if ((item.pictures?.length ?? 0) === 0) {
                        const destinations = await Promise.allSettled(
                            (item as ItineraryByDay).destinations?.map((item) => {
                                return getQuickDestination(item.id);
                            }) ?? []
                        );
                        //eslint-disable-next-line no-undef
                        const result = destinations.filter((destination): destination is PromiseFulfilledResult<Awaited<ReturnType<typeof getQuickDestination>>> => {
                            return destination.status === 'fulfilled';
                        }).map((destination) => {
                            return destination.value;
                        }).filter((destination): destination is NonNullable<typeof destination> => {
                            return !!destination;
                        });
                        const pictures = flatten(
                            result.map((destination) => {
                                return destination.pictures ?? [];
                            })
                        );
                        if (pictures.length > 0) {
                            options.onChangeItemPictures(item.id, pictures);
                            changed = true;
                        }
                    }
                } else if ((item.pictures?.length ?? 0) === 0) {
                    const destinationId = (item as ItineraryByStep).destination?.id;
                    try {
                        const destination = isNumber(destinationId) ?
                            await getQuickDestination(destinationId) :
                            null;
                        if (destination && (destination.pictures?.length ?? 0) > 0) {
                            options.onChangeItemPictures(item.id, destination.pictures ?? []);
                            changed = true;
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }

        //autoload texts
        for (const item of data.content) {
            const localization = item.localization.find((item) => {
                return item.locale === options.locale;
            });
            const longText = localization?.long_description ?? '';
            const shortText = localization?.short_description ?? '';

            const quotationGuides = await findDestinationGuideDescription(
                [(item as ItineraryByStep).destination?.id].filter((item): item is NonNullable<typeof item> => isNumber(item)),
                null,
                true
            );
            const quotationGuidesRubricIds = flatten(
                quotationGuides.map((guide) => {
                    return guide.rubrics.map((rubric) => {
                        return rubric.id;
                    });
                })
            );
            const quotationGuidesTexts = flatten(
                quotationGuides.map((guide) => {
                    return guide.rubrics.map((rubric) => {
                        const localization = rubric.localization.find((item) => {
                            return item.locale === options.locale;
                        });
                        return localization?.description;
                    }).filter((item): item is NonNullable<typeof item> => !!item);
                })
            ).filter((text) => {
                return !htmlHasEmptyContent(text);
            });

            if (
                htmlHasEmptyContent(longText) &&
                data.mode === 'by-step'
            ) {
                if (quotationGuidesTexts.length > 0) {
                    options.onChangeItemText(item.id, quotationGuidesTexts);
                    changed = true;
                }
            } else if (
                htmlHasEmptyContent(longText) &&
                !htmlHasEmptyContent(shortText)
            ) {
                options.onChangeItemText(item.id, [shortText]);
                options.onChangeItemLongText(item.id, ['']);
                changed = true;
            }

            if (
                htmlHasEmptyContent(shortText) &&
                data.mode === 'by-step'
            ) {
                const roadbookGuides = await findDestinationGuideDescription(
                    [(item as ItineraryByStep).destination?.id].filter((item): item is NonNullable<typeof item> => isNumber(item)),
                    true,
                    null
                );

                const roadbookTexts = flatten(
                    roadbookGuides.map((guide) => {
                        return guide.rubrics.filter((rubric) => {
                            return !quotationGuidesRubricIds.includes(rubric.id);
                        }).map((rubric) => {
                            const localization = rubric.localization.find((item) => {
                                return item.locale === options.locale;
                            });
                            return localization?.description;
                        }).filter((item): item is NonNullable<typeof item> => !!item);
                    })
                ).filter((text) => {
                    return !htmlHasEmptyContent(text);
                });

                if (roadbookTexts[1]) {
                    options.onChangeItemLongText(item.id, [roadbookTexts[1]]);
                    changed = true;
                }
            }
        }

        dispatch(setAutoloadingTexts(false));

        return changed;
    };
}
