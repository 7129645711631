import React, { useState, Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Close from '@material-ui/icons/Close';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import CheckBoxFilled from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { uniq } from "lodash";
import { useDynamicTags } from "../hooks/dynamicTags";

const useStyles = makeStyles({
    inline: {
        display: "inline-block"
    },
    bold: {
        fontWeight: "bold"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    starsButton: {
        minWidth: "initial",
        margin: 5,
        display: "inline-block"
    },
    orangeButton: {
        backgroundColor: "#E6592F",
        color: "white"
    },
    checkbox: {
        fontSize: "2rem"
    },
    active: {
        backgroundColor: "black",
        color: "white"
    },
    checked: {
        fill: "black"
    },
    dialogActions: {
        padding: 24
    },
    underline: {
        textDecoration: 'underline'
    }
});

const AccommodationListFilters = () => {
    console.log("filters render");
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const locale = useSelector((state) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const open = useSelector(state => state.accommodation.show_filters);
    const user = useSelector(state => state.user.user);
    const providers = useSelector(state => state.trip.providers);
    const manual_providers = useSelector(state => state.trip.manual_providers);
    const full_list = useSelector((state) => state.accommodation.full_list);
    const all_data = useSelector(state => state.trip.all_data);
    const [stars1, setStars1] = useState(useSelector(state => state.accommodation.star_1));
    const [stars2, setStars2] = useState(useSelector(state => state.accommodation.star_2));
    const [stars3, setStars3] = useState(useSelector(state => state.accommodation.star_3));
    const [stars4, setStars4] = useState(useSelector(state => state.accommodation.star_4));
    const [stars5, setStars5] = useState(useSelector(state => state.accommodation.star_5));
    const [checkIn, setCheckIn] = useState(useSelector(state => state.accommodation.check_in));
    const [bar, setBar] = useState(useSelector(state => state.accommodation.bar));
    const [restaurant, setRestaurant] = useState(useSelector(state => state.accommodation.restaurant));
    const [kitchen, setKitchen] = useState(useSelector(state => state.accommodation.kitchen));
    const [change, setChange] = useState(useSelector(state => state.accommodation.change));
    const [receptionist, setReceptionist] = useState(useSelector(state => state.accommodation.receptionist));
    const [elevator, setElevator] = useState(useSelector(state => state.accommodation.elevator));
    const [parking, setParking] = useState(useSelector(state => state.accommodation.parking));
    const [swimmingPool, setSwimmingPool] = useState(useSelector(state => state.accommodation.swimming_pool));
    const [roomService, setRoomService] = useState(useSelector(state => state.accommodation.room_service));
    const [privateBeach, setPrivateBeach] = useState(useSelector(state => state.accommodation.private_beach));
    const [television, setTelevision] = useState(useSelector(state => state.accommodation.television));
    const [safe, setSafe] = useState(useSelector(state => state.accommodation.safe));
    const [wifi, setWifi] = useState(useSelector(state => state.accommodation.wifi));
    const [airConditioning, setAirConditioning] = useState(useSelector(state => state.accommodation.air_conditioning));
    const [miniBar, setMiniBar] = useState(useSelector(state => state.accommodation.mini_bar));
    const [conferenceRoom, setConferenceRoom] = useState(useSelector(state => state.accommodation.conferencerooms));
    const [spa, setSpa] = useState(useSelector(state => state.accommodation.spa));
    const [selectedProviders, setSelectedProviders] = useState(useSelector(state => state.accommodation.selected_providers));
    const [mealPlan, setMealPlan] = useState(useSelector(state => state.accommodation.meal_plan));
    const [cancellable, setCancellable] = useState(useSelector(state => state.accommodation.cancellable));
    const [resortFees, setResortFees] = useState(useSelector(state => state.accommodation.resort_fees));
    const [dynamicTagsFilter, setDynamicTagsFilter] = useState(useSelector(state => state.accommodation.dynamicTagsFilter));
    const tags = useDynamicTags();

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const toggleFilters = () => {
        dispatch({ type: "MATERIAL_TOGGLE_FILTERS" });
    };
    const setFilters = () => {
        dispatch({
            type: "MATERIAL_FILTER_LIST",
            payload: {
                star_1: stars1,
                star_2: stars2,
                star_3: stars3,
                star_4: stars4,
                star_5: stars5,
                check_in: checkIn,
                bar: bar,
                restaurant: restaurant,
                kitchen: kitchen,
                change: change,
                receptionist: receptionist,
                elevator: elevator,
                parking: parking,
                swimming_pool: swimmingPool,
                room_service: roomService,
                private_beach: privateBeach,
                television: television,
                safe: safe,
                wifi: wifi,
                air_conditioning: airConditioning,
                mini_bar: miniBar,
                conferencerooms: conferenceRoom,
                spa: spa,
                selected_providers: selectedProviders,
                meal_plan: mealPlan,
                cancellable: cancellable,
                resort_fees: resortFees,
                dynamicTagsFilter: dynamicTagsFilter
            }
        });
    };
    const resetFilters = () => {
        setStars1(false);
        setStars2(false);
        setStars3(false);
        setStars4(false);
        setStars5(false);
        setCheckIn(false);
        setBar(false);
        setRestaurant(false);
        setKitchen(false);
        setChange(false);
        setReceptionist(false);
        setElevator(false);
        setParking(false);
        setSwimmingPool(false);
        setRoomService(false);
        setPrivateBeach(false);
        setTelevision(false);
        setSafe(false);
        setWifi(false);
        setAirConditioning(false);
        setMiniBar(false);
        setConferenceRoom(false);
        setSpa(false);
        setSelectedProviders([]);
        setMealPlan(null);
        setCancellable(false);
        setResortFees(false);
    };
    const closeFilters = () => {
        resetFilters();
        toggleFilters();
    };
    return (
        <Dialog open={open} PaperProps={{ style: { borderRadius: fullScreen ? 0 : 16 } }} onClose={toggleFilters} fullWidth fullScreen={fullScreen} maxWidth={"md"}>
            <DialogTitle onClose={toggleFilters}>
                <Grid container justify={"space-between"} alignItems={"center"}>
                    {
                        fullScreen ? (
                            <Grid item>
                                <IconButton edge={"start"} onClick={closeFilters}><NavigateBefore /></IconButton> {t("accommodation_list.filters")}
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    {t("accommodation_list.filters")}
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={closeFilters}><Close /></IconButton>
                                </Grid>
                            </Fragment>
                        )
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Typography className={classes.bold} variant={"h6"}>{t("accommodation_filters.title")}</Typography>
                <Grid container justify={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <Typography className={`${classes.capitalize} ${classes.inline}`}>{t("accommodation_list.stars")}</Typography>
                    </Grid>
                    <Grid item>
                        <Button className={`${classes.starsButton}${stars1 ? ` ${classes.active}` : ""}`} variant={"outlined"} onClick={() => { setStars1(!stars1); }}>1</Button>
                        <Button className={`${classes.starsButton}${stars2 ? ` ${classes.active}` : ""}`} variant={"outlined"} onClick={() => { setStars2(!stars2); }}>2</Button>
                        <Button className={`${classes.starsButton}${stars3 ? ` ${classes.active}` : ""}`} variant={"outlined"} onClick={() => { setStars3(!stars3); }}>3</Button>
                        <Button className={`${classes.starsButton}${stars4 ? ` ${classes.active}` : ""}`} variant={"outlined"} onClick={() => { setStars4(!stars4); }}>4</Button>
                        <Button className={`${classes.starsButton}${stars5 ? ` ${classes.active}` : ""}`} variant={"outlined"} onClick={() => { setStars5(!stars5); }}>5</Button>
                    </Grid>
                </Grid>
                {
                    (user.client_full.type !== 2 || (['verdie'].includes(quotation_code))) && !['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code) && (
                        <Fragment>
                            <br />
                            <Divider />
                            <br />
                            <Typography className={classes.bold} variant={"h6"}>{t("accommodation_filters.providers")}</Typography>
                            <br />
                            <Grid container>
                                {
                                    providers.map((provider, index_provider) => {
                                        if (provider.enabled_content_types.includes(0) && all_data.branch_code === provider.branch_code) {
                                            let has_price = false;
                                            if (full_list) {
                                                for (const accommodation of full_list) {
                                                    for (const price_provider of accommodation.providers) {
                                                        if (price_provider.provider.provider.id === provider.provider.id) {
                                                            has_price = true;
                                                            break;
                                                        }
                                                    }
                                                    if (has_price) {
                                                        break;
                                                    }
                                                }
                                            }
                                            if (has_price) {
                                                return (
                                                    <Grid key={index_provider} item xs={6}>
                                                        <FormControlLabel
                                                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={selectedProviders.includes(provider.provider.id)} onChange={(e) => {
                                                                let new_selectedProviders = [...selectedProviders];
                                                                if (e.target.checked) {
                                                                    new_selectedProviders.push(provider.provider.id);
                                                                } else {
                                                                    new_selectedProviders.splice(new_selectedProviders.indexOf(provider.provider.id), 1);
                                                                }
                                                                setSelectedProviders(new_selectedProviders);
                                                            }} />}
                                                            label={provider.provider.name}
                                                        />
                                                    </Grid>
                                                );
                                            }
                                            return null;
                                        }
                                    })
                                }
                                {
                                    !['verdie'].includes(quotation_code) && manual_providers.map((provider, index_provider) => {
                                        if (provider.available_content_types.includes(0) && provider.is_xml === undefined) {
                                            let has_price = false;
                                            if (full_list) {
                                                for (const accommodation of full_list) {
                                                    for (const price_provider of accommodation.providers) {
                                                        if (price_provider.provider.provider.id === provider.provider.id) {
                                                            has_price = true;
                                                            break;
                                                        }
                                                    }
                                                    if (has_price) {
                                                        break;
                                                    }
                                                }
                                            }
                                            if (has_price) {
                                                return (
                                                    <Grid key={index_provider} item xs={6}>
                                                        <FormControlLabel
                                                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={selectedProviders.includes(provider.provider.id)} onChange={(e) => {
                                                                let new_selectedProviders = [...selectedProviders];
                                                                if (e.target.checked) {
                                                                    new_selectedProviders.push(provider.provider.id);
                                                                } else {
                                                                    new_selectedProviders.splice(new_selectedProviders.indexOf(provider.provider.id), 1);
                                                                }
                                                                setSelectedProviders(new_selectedProviders);
                                                            }} />}
                                                            label={provider.provider.name}
                                                        />
                                                    </Grid>
                                                );
                                            }
                                            return null;
                                        }
                                    })
                                }
                            </Grid>
                        </Fragment>
                    )
                }
                <br />
                <Divider />
                <br />
                <Typography className={classes.bold} variant={"h6"}>{t("accommodation_filters.meal_plan")}</Typography>
                <br />
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={mealPlan === 0} onChange={() => { mealPlan === 0 ? setMealPlan(null) : setMealPlan(0) }} />}
                            label={t("accommodation_filters.breakfast_included")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={mealPlan === 1} onChange={() => { mealPlan === 1 ? setMealPlan(null) : setMealPlan(1) }} />}
                            label={t("accommodation_filters.half_board")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={mealPlan === 2} onChange={() => { mealPlan === 2 ? setMealPlan(null) : setMealPlan(2) }} />}
                            label={t("accommodation_filters.all_inclusive")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={mealPlan === 3} onChange={() => { mealPlan === 3 ? setMealPlan(null) : setMealPlan(3) }} />}
                            label={t("accommodation_filters.all_inclusive_meals")}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <br />
                <Typography className={classes.bold} variant={"h6"}>{t("accommodation_filters.cancellable")}</Typography>
                <br />
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={cancellable} onChange={() => { setCancellable(!cancellable); }} />}
                            label={t("accommodation_filters.cancellable")}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <br />
                <Typography className={classes.bold} variant={"h6"}>Tags</Typography>
                <br />
                <Grid spacing={1.5} container>
                    {
                        tags.map((tag) => (
                            <Grid id={tag.id} item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize={"large"} />}
                                            checkedIcon={
                                                <CheckBoxFilled
                                                    className={classes.checked}
                                                    fontSize={"large"}
                                                />
                                            }
                                            checked={
                                                dynamicTagsFilter.includes(tag.id)
                                            }
                                            onChange={(_, checked) => {
                                                if (checked) {
                                                    setDynamicTagsFilter((state) => {
                                                        return uniq(
                                                            state.concat([tag.id])
                                                        );
                                                    });
                                                } else {
                                                    setDynamicTagsFilter((state) => {
                                                        return uniq(
                                                            state.filter((id) => {
                                                                return id !== tag.id;
                                                            })
                                                        );
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        tag.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        tag.name
                                    }
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <br />
                <Divider />
                <br />
                <Typography className={classes.bold} variant={"h6"}>{t("accommodation_filters.resort_fees")}</Typography>
                <br />
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={resortFees} onChange={() => { setResortFees(!resortFees); }} />}
                            label={t("accommodation_filters.resort_fees_included")}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <br />
                <Typography className={classes.bold} variant={"h6"}>{t("accommodation_filters.benefit")}</Typography>
                <br />
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={checkIn} onChange={() => { setCheckIn(!checkIn); }} />}
                            label={t("accommodation.equipment.check_in")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={bar} onChange={() => { setBar(!bar); }} />}
                            label={t("accommodation.equipment.bar")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={restaurant} onChange={() => { setRestaurant(!restaurant); }} />}
                            label={t("accommodation.equipment.restaurant")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={kitchen} onChange={() => { setKitchen(!kitchen); }} />}
                            label={t("accommodation.equipment.kitchen")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={change} onChange={() => { setChange(!change); }} />}
                            label={t("accommodation.equipment.change")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={receptionist} onChange={() => { setReceptionist(!receptionist); }} />}
                            label={t("accommodation.equipment.receptionist")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={elevator} onChange={() => { setElevator(!elevator); }} />}
                            label={t("accommodation.equipment.elevator")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={parking} onChange={() => { setParking(!parking); }} />}
                            label={t("accommodation.equipment.parking")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={swimmingPool} onChange={() => { setSwimmingPool(!swimmingPool); }} />}
                            label={t("accommodation.equipment.swimming_pool")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={roomService} onChange={() => { setRoomService(!roomService); }} />}
                            label={t("accommodation.equipment.room_service")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={privateBeach} onChange={() => { setPrivateBeach(!privateBeach); }} />}
                            label={t("accommodation.equipment.beach")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={spa} onChange={() => { setSpa(!spa); }} />}
                            label={t("accommodation.equipment.spa")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={conferenceRoom} onChange={() => { setConferenceRoom(!conferenceRoom); }} />}
                            label={t("accommodation.equipment.conference_room")}
                        />
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <br />
                <Typography className={classes.bold} variant={"h6"}>Equipements</Typography>
                <br />
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={television} onChange={() => { setTelevision(!television); }} />}
                            label={t("accommodation.equipment.television")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={safe} onChange={() => { setSafe(!safe); }} />}
                            label={t("accommodation.equipment.safe")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={wifi} onChange={() => { setWifi(!wifi); }} />}
                            label={t("accommodation.equipment.wifi")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={airConditioning} onChange={() => { setAirConditioning(!airConditioning); }} />}
                            label={t("accommodation.equipment.air_conditioning")}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={miniBar} onChange={() => { setMiniBar(!miniBar); }} />}
                            label={t("accommodation.equipment.mini_bar")}
                        />
                    </Grid>
                </Grid>
                {
                    (['cercledesvoyages', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code)) && (
                        <Fragment>
                            <br />
                            <Divider />
                            <br />
                            <Typography className={classes.bold} variant={"h6"}>{t("accommodation_filters.providers")}</Typography>
                            <br />
                            <Grid container>
                                {
                                    providers.map((provider, index_provider) => {
                                        if (provider.enabled_content_types.includes(0)) {
                                            return (
                                                <Grid key={index_provider} item xs={6}>
                                                    <FormControlLabel
                                                        control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={selectedProviders.includes(provider.provider.id)} onChange={(e) => {
                                                            let new_selectedProviders = [...selectedProviders];
                                                            if (e.target.checked) {
                                                                new_selectedProviders.push(provider.provider.id);
                                                            } else {
                                                                new_selectedProviders.splice(new_selectedProviders.indexOf(provider.provider.id), 1);
                                                            }
                                                            setSelectedProviders(new_selectedProviders);
                                                        }} />}
                                                        label={provider.provider.name}
                                                    />
                                                </Grid>
                                            );
                                        }
                                    })
                                }
                                {
                                    manual_providers.map((provider, index_provider) => {
                                        if (provider.available_content_types.includes(0) && provider.is_xml === undefined) {
                                            return (
                                                <Grid key={index_provider} item xs={6}>
                                                    <FormControlLabel
                                                        control={<Checkbox icon={<CheckBoxOutlineBlank fontSize={"large"} />} checkedIcon={<CheckBoxFilled className={classes.checked} fontSize={"large"} />} checked={selectedProviders.includes(provider.provider.id)} onChange={(e) => {
                                                            let new_selectedProviders = [...selectedProviders];
                                                            if (e.target.checked) {
                                                                new_selectedProviders.push(provider.provider.id);
                                                            } else {
                                                                new_selectedProviders.splice(new_selectedProviders.indexOf(provider.provider.id), 1);
                                                            }
                                                            setSelectedProviders(new_selectedProviders);
                                                        }} />}
                                                        label={provider.provider.name}
                                                    />
                                                </Grid>
                                            );
                                        }
                                    })
                                }
                            </Grid>
                        </Fragment>
                    )
                }
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Grid container justify={"space-between"}>
                    <Grid item>
                        <Button className={classes.underline} onClick={resetFilters}>{t("accommodation_filters.reset_filters")}</Button>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"} className={classes.orangeButton} onClick={setFilters}>{t("accommodation_filters.apply_change")}</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};

export default React.memo(AccommodationListFilters);