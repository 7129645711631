import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import GoogleMapsLoader from "google-maps";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CurrencyFormat from "../Accommodation/FunctionalComponent/CurrencyFormat";
import Slider from "../Accommodation/FunctionalComponent/Slider";
import PoiSlider from "../PoiMaterial/PoiListComponents/PoiSlider";
import Wifi from "@material-ui/icons/Wifi";
import Pool from "@material-ui/icons/Pool";
import Star from "@material-ui/icons/Star";
import Hotel from "@material-ui/icons/Hotel";
import AcUnit from "@material-ui/icons/AcUnit";
import Refresh from '@material-ui/icons/Refresh';
import CheckBox from "@material-ui/icons/CheckBox";
import LocalParking from "@material-ui/icons/LocalParking";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import VisibilityIcon from '@material-ui/icons/Visibility';
import grey from "@material-ui/core/colors/grey";

import '../../Style/Map.css';

//---- Functions ----//
import AddDestination from "../Itinerary/Functions/AddDestination";
import DestinationSelected from "../Itinerary/Functions/DestinationSelected";
import Notify from '../Common/Notify.js';

//---- Fonctions ----//
import InitItineraryInput from "./Functions/InitItineraryInput";
import CustomOverlay from "../Common/CustomOverlay.jsx";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { DestinationListChangeOnZoom } from "../../Actions/Itinerary";
import { ToggleMoreInfoModal } from "../../Actions/Itinerary";
import { ResetUnmountCard } from "../../Actions/Poi";
import Favorite from "@material-ui/icons/Favorite";

let bounds_timeout;
let mouse_timeout;

const useStyles = makeStyles({
    "@global": {
        ".gm-style-iw-a .gm-style-iw.gm-style-iw-c": {
            padding: 0,
            width: 300
        },
        ".gm-style-iw-a .gm-ui-hover-effect": {
            // position: "absolute"
            color: "black !important",
            zIndex: "1 !important",
            top: "1px !important",
            right: "2px !important",
            background: "none !important"
        },
        ".gm-style-iw-a .gm-ui-hover-effect span": {
            // position: "absolute"
            backgroundColor: "black !important",
            marginLeft: "-2px !important",
            marginTop: "-2px !important"
        },
        ".gm-style-iw-a .gm-style-iw-d": {
            marginRight: -18
        }
    },
    "map": {
        width: "100%",
        height: "100%"
    },
    "mapButton": {
        backgroundColor: "white",
        position: "absolute",
        marginTop: 8,
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1
    },
    "marker": {
        backgroundColor: "white",
        color: "black",
        cursor: "pointer",
        height: '30px !important'
    },
    "markerBlack": {
        backgroundColor: "black",
        color: "white",
        cursor: "pointer"
    },
    "markerSeen": {
        backgroundColor: "white",
        color: grey[300],
        cursor: "pointer"
    },
    "infoWindowTitle": {
        padding: "8px 8px 0 8px",
        fontWeight: "bold"
    },
    "currencyFontSize": {
        fontSize: "0.9rem"
    },
    "marginRight": {
        marginRight: 8
    },
    "clickable": {
        cursor: 'pointer'
    },
    "sliderContainer": {
        position: "relative",
        height: 200,
        width: 300
    },
    "inline": {
        display: "inline"
    },
    "marginLeftSmall": {
        marginLeft: 4
    },
    "marginLeft": {
        marginLeft: 8
    },
    "recommended": {
        color: '#4caf50',
        marginLeft: 8
    },
    "marginBottom": {
        marginBottom: 8
    },
    "perNight": {
        marginLeft: 8,
        display: "inline-block"
    },
    "scrolled": {
        boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
    },
    "currencyFontSizeTotal": {
        fontSize: "0.9rem"
    },
    "grey": {
        color: grey[800]
    }
});

function CustomMarker(latlng, type, black, seen, index, price, map, currency, poi_type) {
    this.latlng = latlng;
    this.type = type;
    this.open = false;
    this.price = price;
    this.price_symbol = currency !== undefined && currency !== null ? currency.symbol : null;
    this.currency = currency;
    this.black = black;
    this.seen = seen;
    this.index = index;
    if (poi_type !== undefined && poi_type !== null) {
        this.poi_type = poi_type;
    }
    this.setMap(map);
}

function CustomDestinationMarker(latlng, map, url, title, type) {
    this.id_ = title + type;
    this.latlng_ = latlng;
    this.url_ = url;
    this.title_ = title;
    this.type_ = type;
    this.div_ = null;
    this.setMap(map);
}

let mouse_over_info_window = false;
let clicked = false;
let segments_drawn = [];
let itinerary_markers = [];
let departure_destination_marker = null;
let return_destination_marker = null;
let destination_markers = [];
let accommodation_marker = [];
let visit_markers = [];
let delay = 500;
let setTimeoutConst;
let global_type = 0;
let info_window = null;
let global_system_change = false;

const Map = ({ router, type }) => {
    console.log("Map render");
    global_type = type;
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const map = useSelector(state => state.base.map);
    const google = useSelector(state => state.base.google);
    const bounds = useSelector(state => state.base.bounds);
    const card_unmount_back = useSelector(state => state.base.card_unmount_back);
    const departure_destination = useSelector(state => state.itinerary.departure_destination);
    const return_destination = useSelector(state => state.itinerary.return_destination);
    const itinerary_list = useSelector(state => state.itinerary.itinerary_list);
    const destination_list = useSelector(state => state.itinerary.destination_list);
    const itinerary_loaded = useSelector(state => state.itinerary.itinerary_loaded);
    const itinerary_return_same = useSelector(state => state.itinerary.itinerary_return_same);
    const itinerary_edit_input_triggered = useSelector(state => state.itinerary.itinerary_edit_input_triggered);
    const zoom_on_destination_list = useSelector(state => state.itinerary.zoom_on_destination_list);
    const auto_reload = useSelector(state => state.base.auto_reload);
    const map_moved = useSelector(state => state.base.map_moved);
    const fit_bounds = useSelector(state => state.base.fit_bounds);
    const currency_list = useSelector(state => state.base.currency_list);
    const accommodation_list = useSelector(state => state.accommodation.page_list);
    const accommodation_seen = useSelector(state => state.accommodation.seen);
    const accommodation_destination = useSelector(state => state.accommodation.destination);
    const poi_list = useSelector(state => state.poi.page_list);
    const poi_hovered = useSelector(state => state.poi.hovered);
    const poi_destination = useSelector(state => state.poi.destination);
    const start_date = useSelector(state => state.trip.start_date);
    const end_date = useSelector(state => state.trip.end_date);
    const currency = useSelector(state => state.trip.currency);
    const data_trip = useSelector(state => state.trip.data_trip);
    const destination = useSelector(state => state.header.destination);
    const accommodation_hovered = useSelector(state => state.accommodation.hovered);
    const duration_days = useSelector(state => state.accommodation.duration_days);
    const room_number = useSelector(state => state.accommodation.room_number);
    const language = useSelector(state => state.header.tmp_language);
    const user = useSelector(state => state.user.user);

    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;

    const updateMap = (map) => {
        clearTimeout(bounds_timeout);
        bounds_timeout = setTimeout(function () {
            let zoom = map.getZoom();
            if (global_type === 0 && !global_system_change) {
                const { headers } = CheckBeforeRequest();
                $.ajax({
                    method: "GET",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/destinations/?limit=500&latitude_range=${map.getBounds().getNorthEast().lat()},${map.getBounds().getSouthWest().lat()}&longitude_range=${map.getBounds().getNorthEast().lng()},${map.getBounds().getSouthWest().lng()}&visibility__in=PUBLIC,PRIVATE_TO`,
                    success: function (data) {
                        if (data.results.length > 0) {
                            let type_dest = null;
                            let dest_array = [];
                            if (zoom <= 3) {
                                type_dest = 0;
                            } else if (zoom > 3 && zoom <= 4) {
                                type_dest = 1;
                            } else if (zoom > 4 && zoom <= 6) {
                                type_dest = 3;
                            } else if (zoom > 6 && zoom <= 14) {
                                type_dest = 4;
                            } else {
                                type_dest = 5;
                            }
                            for (let i = 0; i < data.results.length; i++) {
                                if (data.results[i].data.type === type_dest) {
                                    dest_array.push(data.results[i]);
                                }
                                if (type_dest === 4 && data.results[i].data.type === 5) {
                                    dest_array.push(data.results[i]);
                                }
                                if (i + 1 === data.results.length && dest_array.length <= 0 && type_dest < 4) {
                                    i = 0;
                                    type_dest += 1;
                                }
                            }
                            global_system_change = false;
                            dispatch(DestinationListChangeOnZoom(dest_array));
                        } else {
                            dispatch(DestinationListChangeOnZoom(null));
                        }
                    },
                    error: function (error) {
                        console.log("Error: something went wrong while loading destinations");
                        console.log(error);
                    }
                });
            }
            global_system_change = false;
            dispatch({
                type: "MATERIAL_UPDATE_MAP",
                payload: {
                    center: map.getCenter(),
                    zoom: map.getZoom(),
                    origin: router.routes,
                    bounds: {
                        north: map.getBounds().getNorthEast().lat(),
                        east: map.getBounds().getNorthEast().lng(),
                        south: map.getBounds().getSouthWest().lat(),
                        west: map.getBounds().getSouthWest().lng()
                    }
                }
            });
            dispatch(ResetUnmountCard());
        }, 1000);
    };
    const applyMoved = () => {
        dispatch({
            type: "MATERIAL_APPLY_MOVED",
            payload: {
                center: map.getCenter(),
                zoom: map.getZoom(),
                bounds: {
                    north: map.getBounds().getNorthEast().lat(),
                    east: map.getBounds().getNorthEast().lng(),
                    south: map.getBounds().getSouthWest().lat(),
                    west: map.getBounds().getSouthWest().lng()
                }
            }
        });
    };
    const toggleAutoReload = () => {
        dispatch({ type: "MATERIAL_TOGGLE_AUTO_RELOAD" });
    };
    const onMouseEnter = (event, index, type) => {
        let element = "";
        if (type === 1) {
            element = document.getElementById(`hotel-item-${index}`);
        } else if (type === 2) {
            element = document.getElementById(`activity-item-${index}`);
        }
        if (element !== undefined && element !== null) {
            element.classList.add(classes.scrolled);
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
        event.currentTarget.style.zIndex = "200";
        //clearTimeout(mouse_timeout);
        //mouse_timeout = setTimeout((event, index, type) => {
        //let element = ""
        //if (type === 1){
        //element = document.getElementById(`hotel-item-${index}`);
        //}else if (type === 2){
        //element = document.getElementById(`activity-item-${index}`);
        //}
        //if (element !== undefined && element !== null) {
        //element.classList.add(classes.scrolled);
        //element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        //}
        //event.currentTarget.style.zIndex = "200";
        //}, 250, event, index, type);
    };
    const onMouseLeave = (event, index, type) => {
        let element = "";
        if (type === 1) {
            element = document.getElementById(`hotel-item-${index}`);
        } else if (type === 2) {
            element = document.getElementById(`activity-item-${index}`);
        }
        if (element !== undefined && element !== null) {
            element.classList.remove(classes.scrolled);
        }
        event.currentTarget.style.zIndex = "initial";
    };
    const initMap = () => {
        let key = "";
        const config = JSON.parse(localStorage.getItem("config"));
        for (let i = 0; i < config.keys.length; i++) {
            if (config.keys[i].identifier === "google_api") {
                key = config.keys[i].value;
            }
        }
        GoogleMapsLoader.KEY = key;
        GoogleMapsLoader.LIBRARIES = ['geometry', 'places', 'marker'];
        GoogleMapsLoader.LANGUAGE = "fr";
        GoogleMapsLoader.load(function (google) {
            if (document.getElementById('map') !== null) {
                let options = {
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    draggable: true,
                    clickableIcons: false,
                    scaleControl: true,
                    mapTypeControl: false,
                    streetViewControl: false,
                    center: destination !== null ? { lat: parseFloat(destination.data.latitude), lng: parseFloat(destination.data.longitude) } : { lat: -34.397, lng: 150.644 },
                    zoom: destination !== null ? destination.data.zoom_level < 4 ? 4 : destination.data.zoom_level : 8,
                    styles: [
                        {
                            featureType: "poi",
                            stylers: [{ visibility: "off" }]
                        }
                    ]
                };
                let map = new google.maps.Map(document.getElementById('map'), options);
                //google.maps.event.addListenerOnce(map, 'idle', () => {
                //if (bounds !== null) {
                //map.fitBounds(new google.maps.LatLngBounds({lat: bounds.south, lng: bounds.west}, {lat: bounds.north, lng: bounds.east}), 0);
                //} else if (destination !== null) {
                //const bounds = JSON.parse(destination.data.bounds.replace(/'/g,'"'));
                //map.fitBounds(new google.maps.LatLngBounds({lat: bounds.south, lng: bounds.west}, {lat: bounds.north, lng: bounds.east}), 0);
                //}
                //});
                info_window = new google.maps.InfoWindow({
                    pixelOffset: new google.maps.Size(-5, -25)
                });
                map.addListener("idle", () => {
                    updateMap(map);
                });
                //map.addListener("dragend", () => {
                //updateMap(map);
                //});
                //map.addListener("zoom_changed", () => {
                //updateMap(map);
                //});
                CustomMarker.prototype = new google.maps.OverlayView();
                CustomMarker.prototype.createDiv = function () {
                    if (this.type === 1) {
                        if (this.price === 0) {
                            this.div = ReactDOM.render(<div onMouseEnter={(event) => {
                                onMouseEnter(event, this.index, this.type);
                                $(event.currentTarget).one('mouseleave', (event1) => {
                                    onMouseLeave(event1, this.index, this.type);
                                });
                            }}><div><Fab className={ this.black ? classes.markerBlack : (this.seen ? classes.markerSeen : classes.marker) } size={ "small" }><Hotel fontSize={ "small" }/></Fab></div></div>, document.createElement("div"));
                        } else {
                            this.div = ReactDOM.render(<div onMouseEnter={(event) => {
                                onMouseEnter(event, this.index, this.type);
                                $(event.currentTarget).one('mouseleave', (event1) => {
                                    onMouseLeave(event1, this.index, this.type);
                                });
                            }}><div><Fab className={ this.black ? classes.markerBlack : (this.seen ? classes.markerSeen : classes.marker) } size={ "small" } variant={ "extended" }><Hotel className={ classes.marginRight } fontSize={ "small" }/><CurrencyFormat language={ null } fontSize={ classes.currencyFontSize } price={ Math.ceil(this.price).toString() } symbol={ this.price_symbol } with_precision={ 0 } with_bold={ 1 } with_bold_symbol={ 1 }/></Fab></div></div>, document.createElement("div"));
                        }
                    } else if (this.type === 2) {
                        this.div = ReactDOM.render(<div onMouseEnter={(event) => {
                            onMouseEnter(event, this.index, this.type);
                            $(event.currentTarget).one('mouseleave', (event1) => {
                                onMouseLeave(event1, this.index, this.type);
                            });
                        }}><div><Fab className={ this.black ? classes.markerBlack : (this.seen ? classes.markerSeen : classes.marker) } size={ "small" } variant={ "extended" }><VisibilityIcon className={ classes.marginRight } fontSize={ "small" }/>{ this.currency !== undefined && this.currency !== null ? new Intl.NumberFormat(language, { style: "currency", currency: this.currency.iso_code }).format(this.price) : (this.poi_type ? t("cart-material.exploration") : t('cart.activity')) }</Fab></div></div>, document.createElement("div"));
                    } else {
                        this.div = ReactDOM.render(<div onMouseEnter={(event) => {
                            onMouseEnter(event, this.index, this.type);
                            $(event.currentTarget).one('mouseleave', (event1) => {
                                onMouseLeave(event1, this.index, this.type);
                            });
                        }}><div><Fab className={ this.black ? classes.markerBlack : (this.seen ? classes.markerSeen : classes.marker) } size={ "small" }><Hotel fontSize={ "small" }/></Fab></div></div>, document.createElement("div"));
                    }
                    this.div.style.position = "absolute";
                    google.maps.event.addDomListener(this.div, 'click', event => {
                        google.maps.event.trigger(this, 'click');
                    });
                };
                CustomMarker.prototype.appendDivToOverlay = function () {
                    const panes = this.getPanes();
                    if (panes !== null) {
                        panes.overlayImage.appendChild(this.div);
                    }
                };
                CustomMarker.prototype.positionDiv = function () {
                    const projection = this.getProjection();
                    if (projection !== null) {
                        const point = projection.fromLatLngToDivPixel(this.latlng);
                        let offset = 25;
                        if (point) {
                            this.div.style.left = `${point.x - offset}px`;
                            this.div.style.top = `${point.y - offset}px`;
                        }
                    }
                };
                CustomMarker.prototype.draw = function () {
                    if (!this.div) {
                        this.createDiv();
                        this.appendDivToOverlay();
                    }
                    this.positionDiv();
                };
                CustomMarker.prototype.remove = function () {
                    this.setMap(null);
                    if (this.div) {
                        this.div.parentNode.removeChild(this.div);
                        this.div = null;
                    }
                };
                setLoaded(true);
                dispatch({
                    type: "MATERIAL_SET_MAP",
                    payload: {
                        map: map,
                        google: google
                    }
                });
            }
        });
    };
    useEffect(() => {
        if (map !== null && google !== null && type === 0) {
            global_system_change = true;
            let bounds = new google.maps.LatLngBounds();
            let destination_zoom_tmp = [];
            for (let i = 0; i < itinerary_list.length; i++) {
                if (itinerary_list[i].step_type === "STEP") {
                    destination_zoom_tmp.push(itinerary_list[i]);
                    bounds.extend(new google.maps.LatLng(parseFloat(itinerary_list[i].destination.data.latitude), parseFloat(itinerary_list[i].destination.data.longitude)));
                }
            }
            if (destination_zoom_tmp.length > 0) {
                if (destination_zoom_tmp.length < 2) {
                    map.setCenter({ lat: parseFloat(destination_zoom_tmp[0].destination.data.latitude), lng: parseFloat(destination_zoom_tmp[0].destination.data.longitude) });
                } else {
                    map.fitBounds(bounds, 0);
                }
            } else if (destination !== null) {
                map.setCenter({ lat: parseFloat(destination.data.latitude), lng: parseFloat(destination.data.longitude) });
                map.setZoom(destination.data.zoom_level);
            }
        }
    }, [map, itinerary_list, type]);
    useEffect(() => {
        if (map !== null && google !== null && !card_unmount_back && accommodation_destination !== null && (type === 1 || type === 2)) {
            const bounds = JSON.parse(accommodation_destination.destination.data.bounds.replace(/'/g, '"'));
            map.fitBounds(new google.maps.LatLngBounds({ lat: bounds.south, lng: bounds.west }, { lat: bounds.north, lng: bounds.east }), 0);
        }
        for (let i = 0; i < accommodation_marker.length; i++) {
            accommodation_marker[i].marker.remove();
        }
        accommodation_marker = [];
    }, [map, accommodation_destination, type]);
    useEffect(() => {
        if (map !== null && google !== null/*&& bounds === null*/ && poi_destination !== null && (type === 1 || type === 2)) {
            const bounds = JSON.parse(poi_destination.destination.data.bounds.replace(/'/g, '"'));
            map.fitBounds(new google.maps.LatLngBounds({ lat: bounds.south, lng: bounds.west }, { lat: bounds.north, lng: bounds.east }), 0);
        }
        for (let i = 0; i < visit_markers.length; i++) {
            visit_markers[i].marker.setMap(null);
        }
        visit_markers = [];
    }, [map, poi_destination, type]);
    useEffect(() => {
        if (map === null || google === null || !loaded) {
            initMap();
        } else {
            if (itinerary_loaded || itinerary_edit_input_triggered) {
                InitItineraryInput(google, itinerary_return_same, itinerary_list, currency, dispatch, t, start_date, end_date, user);
            }
            if (fit_bounds) {
                global_system_change = true;
                map.fitBounds(new google.maps.LatLngBounds({ lat: bounds.south, lng: bounds.west }, { lat: bounds.north, lng: bounds.east }), 0);
            }
            if (zoom_on_destination_list && destination_list !== null && type === 0) {
                global_system_change = true;
                if (destination_list.length === 1) {
                    map.setCenter(new google.maps.LatLng(parseFloat(destination_list[0].data.latitude), parseFloat(destination_list[0].data.longitude)));
                    map.setZoom((destination_list[0].data.zoom_level !== undefined && destination_list[0].data.zoom_level !== null ? destination_list[0].data.zoom_level : 8));
                } else {
                    let bounds = new google.maps.LatLngBounds();
                    for (let i = 0; i < destination_list.length; i++) {
                        bounds.extend(new google.maps.LatLng(parseFloat(destination_list[i].data.latitude), parseFloat(destination_list[i].data.longitude)));
                    }
                    map.fitBounds(bounds, 0);
                }
            }
            if (type === 0) {
                for (let i = 0; i < accommodation_marker.length; i++) {
                    accommodation_marker[i].marker.remove();
                }
                for (let i = 0; i < visit_markers.length; i++) {
                    visit_markers[i].info_window.close();
                    visit_markers[i].marker.setVisible(false);
                }
                for (let i = 0; i < itinerary_markers.length; i++) {
                    itinerary_markers[i].marker.setVisible(true);
                }
                for (let i = 0; i < destination_markers.length; i++) {
                    destination_markers[i].marker.setVisible(true);
                }
                if (departure_destination_marker === null && departure_destination.place_id !== null && departure_destination.lat !== null && departure_destination.lng !== null) {
                    departure_destination_marker = new google.maps.Marker({
                        position: new google.maps.LatLng(departure_destination.lat, departure_destination.lng),
                        map: map,
                        icon: {
                            url: "/Img/Map/home.png"
                        }
                    });
                }
                if (return_destination_marker === null && return_destination.place_id !== null && return_destination.lat !== null && return_destination.lng !== null) {
                    return_destination_marker = new google.maps.Marker({
                        position: new google.maps.LatLng(return_destination.lat, return_destination.lng),
                        map: map,
                        icon: {
                            url: "/Img/Map/home.png"
                        }
                    });
                }
                if (departure_destination_marker !== null) {
                    departure_destination_marker.setVisible(true);
                }
                if (return_destination_marker !== null) {
                    return_destination_marker.setVisible(true);
                }
                //Add iti markers & draw segments
                let itinerary_to_add = [];
                let itinerary_copy = [];
                for (let i = 0; i < segments_drawn.length; i++) {
                    segments_drawn[i].polyline.setMap(null);
                    /*
                    let remove = true;
                    if (segments_drawn[i].polyline.isVisible === undefined) {
                        segments_drawn[i].polyline.setMap(map);
                    }
                    for (let k = 0; k < itinerary_list.length; k++) {
                        if (itinerary_list[k].id === segments_drawn[i].id && itinerary_list[k].selected_transport === segments_drawn[i].selected_transport) {
                            segments_drawn[i].polyline.setVisible(true);
                            remove = false;
                        }
                    }
                    if (remove) {
                        segments_drawn[i].polyline.setMap(null);
                        segments_drawn.splice(i, 1);
                        i -= 1;
                    }
                     */
                }
                segments_drawn = [];
                for (let i = 0; i < itinerary_list.length; i++) {
                    if (itinerary_list[i].selected_transport !== "" && itinerary_list[i].r2r_json.segments !== undefined && itinerary_list[i].r2r_json.data !== undefined) {
                        let draw_segment = true;
                        for (let j = 0; j < segments_drawn.length; j++) {
                            if (segments_drawn[j].id === itinerary_list[i].id) {
                                draw_segment = false;
                            }
                        }
                        if (draw_segment) {
                            let round_plane = {
                                path: "M135.764,213.765c-100.494,100.493-100.493,263.976,0,364.472c100.495,100.494,263.975,100.494,364.471-0.002" +
                                    "c100.494-100.492,100.494-263.977,0-364.47C399.741,113.271,236.259,113.27,135.764,213.765z",
                                anchor: new google.maps.Point(320, 500),
                                scale: 0.05,
                                fillOpacity: 1
                            };
                            let round_other = {
                                path: "M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0" +
                                    "c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267" +
                                    "c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407" +
                                    "s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062" +
                                    "C438.533,179.485,428.732,142.795,409.133,109.203z M361.74,259.517l-29.123,29.129c-3.621,3.614-7.901,5.424-12.847,5.424" +
                                    "c-4.948,0-9.236-1.81-12.847-5.424l-87.654-87.653l-87.646,87.653c-3.616,3.614-7.898,5.424-12.847,5.424" +
                                    "c-4.95,0-9.233-1.81-12.85-5.424l-29.12-29.129c-3.617-3.607-5.426-7.898-5.426-12.847c0-4.942,1.809-9.227,5.426-12.848" +
                                    "l129.62-129.616c3.617-3.617,7.898-5.424,12.847-5.424s9.238,1.807,12.846,5.424L361.74,233.822" +
                                    "c3.613,3.621,5.424,7.905,5.424,12.848C367.164,251.618,365.357,255.909,361.74,259.517z",
                                anchor: new google.maps.Point(185, 500),
                                scale: 0.045,
                                fillOpacity: 1
                            };
                            let arrow = {
                                path: "M361.74,259.517l-29.123,29.129c-3.621,3.613-7.901,5.424-12.848,5.424" +
                                    "c-4.947,0-9.235-1.811-12.847-5.424l-87.654-87.653l-87.646,87.653c-3.616,3.613-7.897,5.424-12.847,5.424" +
                                    "c-4.95,0-9.233-1.811-12.85-5.424l-29.121-29.129c-3.617-3.607-5.426-7.898-5.426-12.848c0-4.941,1.809-9.227,5.426-12.848" +
                                    "l129.621-129.616c3.616-3.617,7.897-5.424,12.846-5.424c4.949,0,9.238,1.807,12.847,5.424L361.74,233.821" +
                                    "c3.612,3.621,5.424,7.906,5.424,12.848C367.164,251.618,365.357,255.909,361.74,259.517z",
                                anchor: new google.maps.Point(185, 500),
                                scale: 0.045,
                                fillOpacity: 1,
                                fillColor: "#FFF"
                            };
                            let plane = {
                                path: "M359.775,232.343c-0.178-19.052-5.917-36.275-15.649-45.531c-5.243-4.986-11.759-8.183-18.345-8.158" +
                                    "c-6.588,0.097-13.043,3.415-18.192,8.498c-9.56,9.436-14.976,26.764-14.8,45.815c-1.283,29.64-0.579,60.689,0.851,91.344" +
                                    "l-142.57,96.974c-1.672,1.138-2.665,3.035-2.646,5.058l0.212,22.814c0.038,4.08,4.022,6.951,7.905,5.699l137.835-51.379" +
                                    "l9.817,108.527c0.223,2.482-0.968,4.878-3.082,6.198l-29.623,18.485c-3.242,2.023-5.198,5.586-5.162,9.409l0.056,6.012" +
                                    "c0.022,2.447,2.025,4.412,4.472,4.389l116.872-1.086c2.447-0.022,4.412-2.025,4.39-4.474l-0.056-6.011" +
                                    "c-0.036-3.823-2.057-7.35-5.338-9.314l-29.959-17.929c-2.139-1.279-3.376-3.654-3.197-6.139l7.795-108.691l138.769,48.807" +
                                    "c3.904,1.179,7.835-1.767,7.797-5.846L507.713,423c-0.02-2.022-1.047-3.901-2.74-5.006l-144.348-94.307" +
                                    "C361.482,293.011,361.609,261.954,359.775,232.343z",
                                scale: 0.04,
                                strokeWeight: 1,
                                fillColor: "#fff",
                                fillOpacity: 1,
                                strokeColor: "#fff",
                                anchor: new google.maps.Point(320, 500)
                            };
                            for (let k = 0; k < itinerary_list[i].r2r_json.segments.length; k++) {
                                let route = [];
                                if (itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "plane") {
                                    const startPlace = itinerary_list[i].r2r_json.data.places[itinerary_list[i].r2r_json.segments[k].depPlace];
                                    const endPlace = itinerary_list[i].r2r_json.data.places[itinerary_list[i].r2r_json.segments[k].arrPlace];
                                    route = [
                                        new google.maps.LatLng(startPlace ? startPlace.lat : 0, startPlace ? startPlace.lng : 0),
                                        new google.maps.LatLng(endPlace ? endPlace.lat : 0, endPlace ? endPlace.lng : 0) //arrival
                                    ];
                                } else {
                                    let encoded = itinerary_list[i].r2r_json.segments[k].path;
                                    let index = 0;
                                    let len = encoded.length;
                                    let lat = 0;
                                    let lng = 0;
                                    while (index < len) {
                                        let b;
                                        let shift = 0;
                                        let result = 0;
                                        do {
                                            b = encoded.charAt(index++).charCodeAt(0) - 63;
                                            result |= (b & 0x1f) << shift;
                                            shift += 5;
                                        } while (b >= 0x20);
                                        lat += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
                                        shift = 0;
                                        result = 0;
                                        do {
                                            b = encoded.charAt(index++).charCodeAt(0) - 63;
                                            result |= (b & 0x1f) << shift;
                                            shift += 5;
                                        } while (b >= 0x20);
                                        lng += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1));
                                        route.push(new google.maps.LatLng((lat / 1E5), (lng / 1E5)));
                                    }
                                }
                                let strokeColor = "#000";
                                if (itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "plane") {
                                    strokeColor = "#06CCA9";
                                } else if (itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "train") {
                                    strokeColor = "#6B4C85";
                                } else if (itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "car") {
                                    strokeColor = "#606060";
                                } else if (itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "bus") {
                                    strokeColor = "#D97A39";
                                } else if (itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "ferry") {
                                    strokeColor = "#55B7D1";
                                }
                                let polyline = new google.maps.Polyline({
                                    map: map,
                                    path: route,
                                    icons: [
                                        {
                                            icon: (itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "plane" ? round_plane : round_other),
                                            offset: '60%'
                                        },
                                        {
                                            icon: (itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "plane" ? plane : arrow),
                                            offset: '60%'
                                        }
                                    ],
                                    strokeColor: strokeColor,
                                    strokeOpacity: 1,
                                    strokeWeight: 6,
                                    geodesic: itinerary_list[i].r2r_json.data.vehicles[itinerary_list[i].r2r_json.segments[k].vehicle].kind === "plane"
                                });
                                segments_drawn.push({
                                    id: itinerary_list[i].id,
                                    selected_transport: itinerary_list[i].selected_transport,
                                    polyline: polyline
                                });
                                polyline.setMap(map);
                            }
                        }
                    }
                    if (itinerary_list[i].step_type === "STEP") {
                        itinerary_copy.push(itinerary_list[i]);
                    }
                }
                for (let i = 0; i < itinerary_copy.length; i++) {
                    let add = true;
                    for (let j = 0; j < itinerary_to_add.length; j++) {
                        if (itinerary_to_add[j].id === itinerary_copy[i].destination.id) {
                            itinerary_to_add[j].destination.push({ destination: itinerary_copy[i], index: i + 1 });
                            add = false;
                        }
                    }
                    if (add) {
                        let destination = [];
                        destination.push({ destination: itinerary_copy[i], index: i + 1 });
                        itinerary_to_add.push({
                            id: itinerary_copy[i].destination.id,
                            destination: destination
                        });
                    }
                }
                for (let i = 0; i < itinerary_markers.length; i++) {
                    let remove = true;
                    for (let j = 0; j < itinerary_to_add.length; j++) {
                        if (itinerary_markers[i].id === itinerary_to_add[j].id) {
                            remove = false;
                        }
                    }
                    if (remove) {
                        itinerary_markers[i].marker.setMap(null);
                        itinerary_markers.splice(i, 1);
                        i -= 1;
                    }
                }
                for (let i = 0; i < itinerary_to_add.length; i++) {
                    let rendered = false;
                    for (let j = 0; j < itinerary_markers.length; j++) {
                        if (itinerary_to_add[i].id === itinerary_markers[j].id) {
                            rendered = true;
                            if (itinerary_to_add[i].destination.length !== itinerary_markers[j].destination.length) {
                                itinerary_markers[j].marker.setMap(null);
                                itinerary_markers.splice(j, 1);
                                rendered = false;
                            } else {
                                for (let k = 0; k < itinerary_to_add[i].destination.length; k++) {
                                    if (itinerary_to_add[i].destination[k].destination.start_date !== itinerary_markers[j].destination[k].start_date || itinerary_to_add[i].destination[k].destination.end_date !== itinerary_markers[j].destination[k].end_date) {
                                        itinerary_markers[j].marker.setMap(null);
                                        itinerary_markers.splice(j, 1);
                                        rendered = false;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if (!rendered) {
                        let marker_option = (itinerary_to_add[i].destination.length === 1) ? {
                            position: new google.maps.LatLng(parseFloat(itinerary_to_add[i].destination[0].destination.destination.data.latitude), parseFloat(itinerary_to_add[i].destination[0].destination.destination.data.longitude)),
                            label: {
                                text: itinerary_to_add[i].destination[0].index.toString(),
                                color: "#76B6C2",
                                fontWeight: "bold"
                            },
                            map: map,
                            icon: {
                                url: "/Img/Map/pin.png",
                                labelOrigin: new google.maps.Point(15, 15)
                            }
                        } : {
                            position: new google.maps.LatLng(parseFloat(itinerary_to_add[i].destination[0].destination.destination.data.latitude), parseFloat(itinerary_to_add[i].destination[0].destination.destination.data.longitude)),
                            map: map,
                            icon: {
                                url: "/Img/Map/repeat.png"
                            }
                        };
                        let marker = new google.maps.Marker(marker_option);
                        let moment_end_date = moment(itinerary_to_add[i].destination[0].destination.end_date);
                        let night_start_date = moment(itinerary_to_add[i].destination[0].destination.start_date);
                        let nights = 0;
                        let inc = 0;
                        while (!moment(night_start_date.add(inc, "d").format("YYYY-MM-DD")).isSame(moment(moment_end_date.format("YYYY-MM-DD")))) {
                            inc = 1;
                            nights = nights + 1;
                            if (nights === 100) {
                                break;
                            }
                        }
                        let picture = "/Img/destination_default.jpg";
                        if (itinerary_to_add[i].destination[0].destination.destination.data.pictures !== undefined && itinerary_to_add[i].destination[0].destination.destination.data.pictures !== null && itinerary_to_add[i].destination[0].destination.destination.data.pictures.length > 0) {
                            if (itinerary_to_add[i].destination[0].destination.destination.data.cover_picture !== undefined && itinerary_to_add[i].destination[0].destination.destination.data.cover_picture !== null) {
                                picture = (itinerary_to_add[i].destination[0].destination.destination.data.cover_picture.thumbnail_little !== null ? itinerary_to_add[i].destination[0].destination.destination.data.cover_picture.thumbnail_little : (itinerary_to_add[i].destination[0].destination.destination.data.cover_picture.url !== null ? itinerary_to_add[i].destination[0].destination.destination.data.cover_picture.url : "/Img/destination_default.jpg"));
                            } else {
                                picture = (itinerary_to_add[i].destination[0].destination.destination.data.pictures[0].thumbnail_little !== null ? itinerary_to_add[i].destination[0].destination.destination.data.pictures[0].thumbnail_little : (itinerary_to_add[i].destination[0].destination.destination.data.pictures[0].url !== null ? itinerary_to_add[i].destination[0].destination.destination.data.pictures[0].url : "/Img/destination_default.jpg"));
                            }
                        }
                        let info_window_content =
                            (<div id={ "iw-container" }>
                                <div className={ "iw-content" }>
                                    <img src={ picture } height={ 115 } width={ 83 } alt={ 'destination picture' }/>
                                </div>
                                <div className={ "iw-title" }>
                                    <div className={ "iw-title-date" }>
                                        { itinerary_to_add[i].destination[0].destination.destination.data.name !== undefined ? itinerary_to_add[i].destination[0].destination.destination.data.name : itinerary_to_add[i].destination[0].destination.destination.data.international_name }
                                    </div>
                                </div>
                                {
                                    itinerary_to_add[i].destination.length === 1 && (
                                        <div>
                                            <span className={ "iw-date" }>
                                                { t("global.from") + " " + moment(itinerary_to_add[i].destination[0].destination.start_date).format("DD-MM-YYYY") }
                                                <br/>
                                                { t("global.to") + " " + moment(itinerary_to_add[i].destination[0].destination.end_date).format("DD-MM-YYYY") }
                                            </span>
                                            <div className={ "iw-btn-night" }>{ nights + t("itinerary.night_letter") }</div>
                                        </div>
                                    )
                                }
                                {
                                    itinerary_to_add[i].destination.length > 1 && itinerary_to_add[i].destination.map((destination, index_destination) => {
                                        let moment_end_date = moment(destination.destination.end_date);
                                        let night_start_date = moment(destination.destination.start_date);
                                        let nights = 0;
                                        let inc = 0;
                                        while (!moment(night_start_date.add(inc, "d").format("YYYY-MM-DD")).isSame(moment(moment_end_date.format("YYYY-MM-DD")))) {
                                            inc = 1;
                                            nights = nights + 1;
                                            if (nights === 100) {
                                                break;
                                            }
                                        }
                                        return (
                                            <div key={ index_destination }>
                                                <span className={ "iw-date" }>
                                                    <div className={ "iw-step" }>{ t("itinerary.step") }<span className={ "iw-step-nb" }>{ destination.index }</span></div>
                                                    { t("global.from") + " " + moment(destination.destination.start_date).format("DD-MM-YYYY") }
                                                    <br/>
                                                    { t("global.to") + " " + moment(destination.destination.end_date).format("DD-MM-YYYY") }
                                                </span>
                                                <div className={ "iw-btn-night" }>{ nights + t("itinerary.night_letter") }</div>
                                            </div>
                                        );
                                    })
                                }
                                <div className={"iw-info-add-container"} >
                                    <div id={ itinerary_to_add[i].destination[0].destination.destination.data.name !== undefined ? itinerary_to_add[i].destination[0].destination.destination.data.name : itinerary_to_add[i].destination[0].destination.destination.data.international_name } className={ itinerary_to_add[i].destination[0].destination.destination.guide_ids.length > 0 ? "iw-add-btn more-info" : "iw-add-btn"} onClick={ () => {
                                        if ((!['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'travelmuz'].includes(quotation_code) && (data_trip.status === "BOOKED" || data_trip.status === "ONREQUEST" || data_trip.status === "AGENCY_MOD")) || (data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV")) {
                                            if (!['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'travelmuz'].includes(quotation_code)) {
                                                Notify(t("itinerary.edit_destination_trip_booked"), { status: "danger", pos: "top-center" });
                                            } else {
                                                Notify(t("header.warning_amendment"), { status: "danger", pos: "top-center" });
                                            }
                                        } else {
                                            AddDestination(itinerary_to_add[i].destination[0].destination.destination, itinerary_list, start_date, end_date, currency.iso_code, departure_destination, return_destination, dispatch, t, user, () => {});
                                        }
                                    }}>+ { t("map.add_destination") }</div>
                                    {
                                        itinerary_to_add[i].destination[0].destination.destination.guide_ids.length > 0 && (
                                            <CustomOverlay id={"tooltip-header-date"} tooltip={"+" + " " + t("itinerary.destination_more_info")} placement={"bottom"} >
                                                <i className={"icon-info iw-more-info"} onClick={ () => {
                                                    dispatch(ToggleMoreInfoModal(true, itinerary_to_add[i].destination[0].destination.destination, null)); 
                                                } } />
                                            </CustomOverlay>
                                        )
                                    }

                                </div>
                            </div>);
                        google.maps.event.addListener(info_window, 'domready', function () {
                            let iwOuter = $('.gm-style-iw');
                            let iwBackground = iwOuter.prev();
                            //iwOuter.parent().parent().css({top: '0px'});
                            iwBackground.children(':nth-child(2)').css({ display: 'none' });
                            iwBackground.children(':nth-child(4)').css({ display: 'none' });
                            iwBackground.children(':nth-child(1)').attr('style', function (i, s) {
                                return s + 'left: 140px !important;';
                            });
                            iwBackground.children(':nth-child(3)').attr('style', function (i, s) {
                                return s + 'left: 140px !important;';
                            });
                            iwBackground.children(':nth-child(3)').find('div').children().css({ 'z-index': '1' });
                            let iwCloseBtn = iwOuter.next();
                            iwCloseBtn.css({ opacity: '1', right: '47px', top: '21px' });
                        });
                        google.maps.event.addListener(marker, 'click', function () {
                            clicked = true;
                            info_window.setContent(ReactDOM.render(info_window_content, document.createElement("div")));
                            info_window.open(map, marker);
                        });
                        google.maps.event.addListener(marker, 'mouseover', function () {
                            setTimeoutConst = setTimeout(function () {
                                if (!clicked) {
                                    info_window.setContent(ReactDOM.render(info_window_content, document.createElement("div")));
                                    info_window.open(map, marker);
                                    if (document.querySelector('.gm-style .gm-style-iw') !== null) {
                                        let infoWindowElement = document.querySelector('.gm-style .gm-style-iw').parentNode;
                                        infoWindowElement.addEventListener('mouseleave', function () {
                                            info_window.close();
                                            mouse_over_info_window = false;
                                        });
                                        infoWindowElement.addEventListener('mouseenter', function () {
                                            mouse_over_info_window = true;
                                        });
                                    }
                                }
                            }, delay);
                        });
                        google.maps.event.addListener(marker, 'mouseout', function () {
                            clearTimeout(setTimeoutConst);
                            if (!clicked) {
                                setTimeout(function () {
                                    if (!mouse_over_info_window) {
                                        info_window.close();
                                    }
                                }, 400);
                            }
                        });
                        google.maps.event.addListener(info_window, 'closeclick', function () {
                            clicked = false;
                        });
                        itinerary_to_add[i].marker = marker;
                        itinerary_markers.push(itinerary_to_add[i]);
                    }
                }
                //Add desti markers
                for (let i = 0; i < destination_markers.length; i++) {
                    destination_markers[i].marker.remove();
                }
                destination_markers = [];
                if (destination_list !== null) {
                    for (let i = 0; i < destination_list.length; i++) {
                        let destination_added = false;
                        for (let j = 0; j < destination_markers.length; j++) {
                            if (destination_markers[j].id === destination_list[i].id) {
                                destination_added = true;
                                destination_markers[j].marker.setVisible(true);
                            }
                        }
                        for (let j = 0; j < itinerary_markers.length; j++) {
                            if (itinerary_markers[j].id === destination_list[i].id) {
                                destination_added = true;
                            }
                        }
                        let in_radius_pin = [];
                        let map_zoom = map.getZoom();
                        let radius_distance = 0;

                        let arr = {
                            0: 500,
                            1: 450,
                            2: 400,
                            3: 350,
                            4: 300,
                            5: 250,
                            6: 200,
                            7: 100,
                            8: 20,
                            9: 10,
                            10: 5,
                            11: 2,
                            12: 1.5,
                            13: 1,
                            14: 0.6,
                            15: 0.30,
                            16: 0.20,
                            17: 0.10,
                            18: 0.05,
                            19: 0.03,
                            20: 0.01
                        };
                        for (let key in arr) {
                            if (key == map_zoom) {
                                radius_distance = arr[key];
                            }
                        }
                        let want_to_add_marker = new google.maps.LatLng(parseFloat(destination_list[i].data.latitude), parseFloat(destination_list[i].data.longitude));
                        for (let j = 0; j < destination_list.length; j++) {
                            let other_marker = new google.maps.LatLng(parseFloat(destination_list[j].data.latitude), parseFloat(destination_list[j].data.longitude));
                            let distance = google.maps.geometry ? google.maps.geometry.spherical.computeDistanceBetween(want_to_add_marker, other_marker) / 1000 : 0;
                            if (distance < radius_distance && distance !== 0) {
                                in_radius_pin.push(destination_list[j]);
                            }
                        }
                        if (in_radius_pin.length > 0) {
                            let want_to_add_marker_notation = destination_list[i].data.note;
                            for (let k = 0; k < in_radius_pin.length; k++) {
                                let test = (destination_list[i].data.name !== undefined ? destination_list[i].data.name : destination_list[i].data.international_name).localeCompare(in_radius_pin[k].data.name !== undefined ? in_radius_pin[k].data.name : in_radius_pin[k].data.international_name);
                                if (in_radius_pin[k].data.note > want_to_add_marker_notation) {
                                    destination_added = true;
                                    break;
                                }
                                if (in_radius_pin[k].data.note === want_to_add_marker_notation) {
                                    if ((destination_list[i].data.name !== undefined ? destination_list[i].data.name : destination_list[i].data.international_name).localeCompare(in_radius_pin[k].data.name !== undefined ? in_radius_pin[k].data.name : in_radius_pin[k].data.international_name) !== -1) {
                                        destination_added = true;
                                        break;
                                    }
                                }
                            }
                        }
                        if (!destination_added) {
                            let picture = "/Img/destination_default.jpg";
                            if (destination_list[i].data.pictures !== undefined && destination_list[i].data.pictures !== null && destination_list[i].data.pictures.length > 0) {
                                if (destination_list[i].data.cover_picture !== undefined && destination_list[i].data.cover_picture !== null) {
                                    picture = (destination_list[i].data.cover_picture.thumbnail_little !== null ? destination_list[i].data.cover_picture.thumbnail_little : (destination_list[i].data.cover_picture.url !== null ? destination_list[i].data.cover_picture.url : "/Img/destination_default.jpg"));
                                } else {
                                    picture = (destination_list[i].data.pictures[0].thumbnail_little !== null ? destination_list[i].data.pictures[0].thumbnail_little : (destination_list[i].data.pictures[0].url !== null ? destination_list[i].data.pictures[0].url : "/Img/destination_default.jpg"));
                                }
                            }
                            let url = picture;
                            CustomDestinationMarker.prototype = new google.maps.OverlayView();
                            CustomDestinationMarker.prototype.draw = function () {
                                let current = this;
                                let div = this.div_;
                                if (!div) {
                                    div = this.div_ = document.createElement("div");
                                    div.className = "custom-marker";
                                    div.id = this.id_;
                                    let img = document.createElement("img");
                                    img.src = this.url_;
                                    div.appendChild(img);
                                    google.maps.event.addDomListener(div, "click", function (event) {
                                        google.maps.event.trigger(current, "click");
                                    });
                                    google.maps.event.addDomListener(div, "mouseover", function (event) {
                                        google.maps.event.trigger(current, "mouseover");
                                    });
                                    google.maps.event.addDomListener(div, "mouseout", function (event) {
                                        google.maps.event.trigger(current, "mouseout");
                                    });
                                    if (this.type_ !== 4 && this.type_ !== 5) {
                                        let name = document.createElement("p");
                                        name.innerHTML = this.title_;
                                        div.appendChild(name);
                                    }
                                    this.getPanes().overlayImage.appendChild(div);
                                }
                                let point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
                                if (point) {
                                    div.style.left = point.x + "px";
                                    div.style.top = point.y + "px";
                                }
                                div.parentElement.style.zIndex = 107;
                            };
                            CustomDestinationMarker.prototype.getPosition = function () {
                                return this.latlng_;
                            };
                            CustomDestinationMarker.prototype.remove = function () {
                                this.setMap(null);
                                if (this.div_) {
                                    this.div_.parentNode.removeChild(this.div_);
                                    this.div_ = null;
                                }
                            };
                            CustomDestinationMarker.prototype.setVisible = function (visible) {
                                if (this.div_) {
                                    visible ? (this.div_.className = "custom-marker") : (this.div_.className = "custom-marker hidden");
                                }
                            };
                            let marker = new CustomDestinationMarker(new google.maps.LatLng(parseFloat(destination_list[i].data.latitude), parseFloat(destination_list[i].data.longitude)), map, url, destination_list[i].data.name !== undefined ? destination_list[i].data.name : destination_list[i].data.international_name, destination_list[i].data.type);
                            destination_markers.push({
                                id: destination_list[i].id,
                                marker: marker
                            });
                            let content =
                                (<div id={ "iw-container"}>
                                    <div className={ "iw-content" }>
                                        <img src={ url } height={ 115 } width={ 83 }/>
                                    </div>
                                    <div className={ "title-content" }>
                                        <div className={ "iw-title" }>
                                            { destination_list[i].data.name !== undefined ? destination_list[i].data.name : destination_list[i].data.international_name }
                                        </div>
                                    </div>
                                    <div className={"iw-info-add-container"} >
                                        <div id={ destination_list[i].data.name !== undefined ? destination_list[i].data.name : destination_list[i].data.international_name } className={ destination_list[i].guide_ids.length > 0 ? "iw-add-btn more-info" : "iw-add-btn"} onClick={ () => {
                                            if ((!['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'travelmuz'].includes(quotation_code) && (data_trip.status === "BOOKED" || data_trip.status === "ONREQUEST" || data_trip.status === "AGENCY_MOD")) || (data_trip.status_contract === "CONFIRMED" && data_trip.status_modification === "FIXED_PV")) {
                                                if (!['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'touristforum', 'travelmuz'].includes(quotation_code)) {
                                                    Notify(t("itinerary.edit_destination_trip_booked"), { status: "danger", pos: "top-center" });
                                                } else {
                                                    Notify(t("header.warning_amendment"), { status: "danger", pos: "top-center" });
                                                }
                                            } else {
                                                AddDestination(destination_list[i], itinerary_list, start_date, end_date, currency.iso_code, departure_destination, return_destination, dispatch, t, user, () => {});
                                            }
                                        }}>+ { t("map.add_destination") }</div>
                                        {
                                            destination_list[i].guide_ids.length > 0 && (
                                                <CustomOverlay id={"tooltip-header-date"} tooltip={"+" + " " + t("itinerary.destination_more_info")} placement={"bottom"} >
                                                    <i className={"icon-info iw-more-info"} onClick={ () => {
                                                        dispatch(ToggleMoreInfoModal(true, destination_list[i], null)); 
                                                    } } />
                                                </CustomOverlay>
                                            )
                                        }

                                    </div>
                                </div>);
                            google.maps.event.addListener(info_window, "domready", function () {
                                let iwOuter = $(".gm-style-iw");
                                let iwBackground = iwOuter.prev();
                                iwBackground.children(":nth-child(2)").css({ display: "none" });
                                iwBackground.children(":nth-child(4)").css({ display: "none" });
                                iwBackground.children(":nth-child(1)").attr("style", function (i, s) {
                                    return s + "left: 140px !important;";
                                });
                                iwBackground.children(":nth-child(3)").attr("style", function (i, s) {
                                    return s + "left: 140px !important;";
                                });
                                iwBackground.children(":nth-child(3)").find("div").children().css({ "z-index": "1" });
                                let iwCloseBtn = iwOuter.next();
                                iwCloseBtn.css({ opacity: "1", right: "47px", top: "21px" });
                            });
                            marker.addListener("click", function (e) {
                                if (parseInt(destination_list[i].data.type) === 4 || parseInt(destination_list[i].data.type) === 5) {
                                    clicked = true;
                                    info_window.setContent(ReactDOM.render(content, document.createElement("div")));
                                    info_window.open(map, this);
                                } else {
                                    DestinationSelected(destination_list[i].id, dispatch);
                                }
                            });
                            marker.addListener('mouseover', function (e) {
                                if (!clicked) {
                                    if (parseInt(destination_list[i].data.type) === 4 || parseInt(destination_list[i].data.type) === 5) {
                                        setTimeoutConst = setTimeout(function (e) {
                                            info_window.setContent(ReactDOM.render(content, document.createElement("div")));
                                            info_window.open(map, marker);
                                            if (document.querySelector('.gm-style .gm-style-iw') !== null) {
                                                let infoWindowElement = document.querySelector('.gm-style .gm-style-iw').parentNode;
                                                infoWindowElement.addEventListener('mouseleave', function () {
                                                    info_window.close();
                                                    mouse_over_info_window = false;
                                                });
                                                infoWindowElement.addEventListener('mouseenter', function () {
                                                    mouse_over_info_window = true;
                                                });
                                            }
                                        }, delay);
                                    }
                                }
                            });
                            marker.addListener('mouseout', function () {
                                clearTimeout(setTimeoutConst);
                                if (!clicked) {
                                    setTimeout(function () {
                                        if (!mouse_over_info_window) {
                                            info_window.close();
                                        }
                                    }, 400);
                                }
                            });
                            google.maps.event.addListener(info_window, 'closeclick', function () {
                                clicked = false;
                            });
                        }
                    }
                }
                for (let i = 0; i < destination_markers.length; i++) {
                    for (let j = 0; j < itinerary_markers.length; j++) {
                        if (destination_markers[i].id === itinerary_markers[j].id) {
                            destination_markers[i].marker.remove();
                            destination_markers.splice(i, 1);
                            i -= 1;
                            break;
                        }
                    }
                }
            } else if (type === 1) {
                if (departure_destination_marker !== null) {
                    departure_destination_marker.setVisible(false);
                }
                if (return_destination_marker !== null) {
                    return_destination_marker.setVisible(false);
                }
                for (let i = 0; i < itinerary_markers.length; i++) {
                    itinerary_markers[i].marker.setVisible(false);
                }
                for (let i = 0; i < visit_markers.length; i++) {
                    visit_markers[i].marker.setVisible(false);
                }
                for (let i = 0; i < segments_drawn.length; i++) {
                    segments_drawn[i].polyline.setVisible(false);
                }
                segments_drawn = [];
                for (let i = 0; i < destination_markers.length; i++) {
                    destination_markers[i].marker.setVisible(false);
                }
                if (accommodation_list !== null && accommodation_list.length > 0) {
                    if (accommodation_hovered !== null) {
                        info_window.close();
                        accommodation_marker.map((marker) => {
                            marker.marker.remove();
                        });
                        accommodation_marker = [];
                        const lat = parseFloat(accommodation_hovered.latitude);
                        const lng = parseFloat(accommodation_hovered.longitude);
                        let currency = '';
                        if (accommodation_hovered.price_currency !== null) {
                            currency = currency_list.find((currency) => {
                                return currency.id === accommodation_hovered.price_currency;
                            });
                        }
                        let marker = new CustomMarker(new google.maps.LatLng(lat, lng), 1, true, false, null, accommodation_hovered.price, map, currency);
                        marker.addListener("click", function () {
                            if (accommodation_hovered.price !== 0) {
                                info_window.setOptions({
                                    pixelOffset: new google.maps.Size(20, -25)
                                });
                            }
                            info_window.setContent(ReactDOM.render(
                                <div>
                                    <img src={ "/Img/accommodation_default.jpg" } width={ 250 } height={ 150 }/>
                                    <Typography className={ classes.infoWindowTitle }>{ accommodation_hovered.name }</Typography>
                                </div>, document.createElement("div")));
                            info_window.setPosition(new google.maps.LatLng(lat, lng));
                            info_window.open(map);
                        });
                        accommodation_marker.push({
                            id: accommodation_hovered.id,
                            black: true,
                            marker: marker
                        });
                    } else {
                        accommodation_marker = accommodation_marker.filter((marker) => {
                            let to_remove = true;
                            accommodation_list.map((accommodation) => {
                                if (!marker.black && marker.id === accommodation.id && marker.price === accommodation.price) {
                                    to_remove = false;
                                }
                            });
                            if (to_remove) {
                                marker.marker.remove();
                                return false;
                            }
                            return true;
                        });
                        accommodation_list.map((accommodation, index_accommodation) => {
                            let already_added = false;
                            accommodation_marker.map((marker) => {
                                if (marker.id === accommodation.id) {
                                    already_added = true;
                                }
                            });
                            if (!already_added) {
                                let already_seen = false;
                                accommodation_seen.map((seen) => {
                                    if (seen === accommodation.id) {
                                        already_seen = true;
                                    }
                                });
                                const lat = parseFloat(accommodation.latitude);
                                const lng = parseFloat(accommodation.longitude);
                                let currency = '';
                                if (accommodation.price_currency !== null) {
                                    currency = currency_list.find((currency) => {
                                        return currency.id === accommodation.price_currency;
                                    });
                                }
                                let marker = new CustomMarker(new google.maps.LatLng(lat, lng), 1, false, already_seen, accommodation.id, accommodation.price, map, currency);
                                marker.addListener("click", function () {
                                    this.black = true;
                                    this.div.parentNode.removeChild(this.div);
                                    this.createDiv();
                                    this.appendDivToOverlay();
                                    this.positionDiv();
                                    if (accommodation.price !== 0) {
                                        info_window.setOptions({
                                            pixelOffset: new google.maps.Size(20, -25)
                                        });
                                    }
                                    /*
                                    let picture = "/Img/accommodation_default.jpg";
                                    if (accommodation.cover_picture !== null) {
                                        picture = GetPictureBySize(accommodation.cover_picture);
                                    } else if (accommodation.custom_pictures !== null && accommodation.custom_pictures.length > 0) {
                                        picture = GetPictureBySize(accommodation.custom_pictures[0]);
                                    } else if (accommodation.pictures !== null && accommodation.pictures.length > 0) {
                                        picture = GetPictureBySize(accommodation.pictures[0]);
                                    }
                                    */
                                    let stars = [];
                                    for (let i = 0; i < accommodation.stars; i++) {
                                        stars.push(<Star key={ i } className={ `${classes.inline} ${i === 0 ? classes.marginLeft : classes.marginLeftSmall}` } fontSize={ "small" }/>);
                                    }
                                    info_window.setContent(ReactDOM.render(
                                        <div className={ classes.clickable } onClick={() => {
                                            router.push(`/${window.url_name}/apps/accommodation-card/${accommodation.id}`); 
                                        }}>
                                            <div className={ classes.sliderContainer }>
                                                <Slider pictures={ accommodation.pictures } showChevron={ true } size={ 1 } dotSize={ 1 }/>
                                            </div>
                                            <Typography className={ classes.infoWindowTitle }>{ accommodation.name }</Typography>
                                            { stars }
                                            <br/>
                                            {
                                                accommodation.internet && (
                                                    <Wifi className={ classes.marginLeft } fontSize={ "default" }/>
                                                )
                                            }
                                            {
                                                accommodation.clim && (
                                                    <AcUnit className={ classes.marginLeft } fontSize={ "default" }/>
                                                )
                                            }
                                            {
                                                accommodation.pool && (
                                                    <Pool className={ classes.marginLeft } fontSize={ "default" }/>
                                                )
                                            }
                                            {
                                                accommodation.parking && (
                                                    <LocalParking className={ classes.marginLeft } fontSize={ "default" }/>
                                                )
                                            }
                                            <br/>
                                            {
                                                accommodation.favorite && (
                                                    <Favorite className={ classes.recommended } fontSize={ "default" }/>
                                                )
                                            }
                                            <div className={ `${classes.marginLeft} ${classes.marginBottom}` }>
                                                <CurrencyFormat language={ null } fontSize={ classes.currencyFontSize } symbol={ currency.symbol } price={ Math.ceil(accommodation.price / duration_days).toString() } with_precision={ 0 } with_bold={ 1 }/>
                                                <Typography className={ classes.perNight } variant={ "body2" }>/ { t("accommodation_map.night_for") } { room_number } { t("accommodation_map.room") }</Typography>
                                            </div>
                                        </div>, document.createElement("div")));
                                    info_window.setPosition(new google.maps.LatLng(lat, lng));
                                    info_window.open(map);
                                    google.maps.event.addListenerOnce(info_window, "position_changed", function () {
                                        if (marker.div !== null) {
                                            marker.black = false;
                                            marker.div.parentNode.removeChild(marker.div);
                                            marker.createDiv();
                                            marker.appendDivToOverlay();
                                            marker.positionDiv();
                                        }
                                    });
                                    google.maps.event.addListenerOnce(info_window, "closeclick", function () {
                                        if (marker.div !== null) {
                                            marker.black = false;
                                            marker.div.parentNode.removeChild(marker.div);
                                            marker.createDiv();
                                            marker.appendDivToOverlay();
                                            marker.positionDiv();
                                        }
                                    });
                                });
                                accommodation_marker.push({
                                    id: accommodation.id,
                                    price: accommodation.price,
                                    marker: marker
                                });
                            }
                        });
                    }
                }
            } else if (type === 2) {
                for (let i = 0; i < accommodation_marker.length; i++) {
                    accommodation_marker[i].marker.remove();
                }
                //for (let i = 0; i < visit_markers.length; i++) {
                //visit_markers[i].info_window.close();
                //visit_markers[i].marker.setVisible(true);
                //}
                if (departure_destination_marker !== null) {
                    departure_destination_marker.setVisible(false);
                }
                if (return_destination_marker !== null) {
                    return_destination_marker.setVisible(false);
                }
                for (let i = 0; i < itinerary_markers.length; i++) {
                    itinerary_markers[i].marker.setVisible(false);
                }
                for (let i = 0; i < segments_drawn.length; i++) {
                    segments_drawn[i].polyline.setVisible(false);
                }
                segments_drawn = [];
                for (let i = 0; i < destination_markers.length; i++) {
                    destination_markers[i].marker.setVisible(false);
                }
                if (poi_list !== null && poi_list.length > 0) {
                    if (poi_hovered !== null && poi_hovered.place !== undefined) {
                        info_window.close();
                        visit_markers.map((marker) => {
                            marker.marker.remove();
                        });
                        visit_markers = [];
                        const lat = parseFloat(poi_hovered.place.latitude);
                        const lng = parseFloat(poi_hovered.place.longitude);
                        let currency = null;
                        if (poi_hovered.currency !== undefined && poi_hovered.currency !== null) {
                            currency = currency_list.find((currency) => {
                                return currency.id === poi_hovered.currency;
                            });
                        } else if (poi_hovered.price !== undefined && poi_hovered.price !== null) {
                            currency = currency_list.find((currency) => {
                                return currency.id === poi_hovered.price.selling_currency;
                            });
                        }
                        let temp_price = poi_hovered.price === undefined || poi_hovered.price === null ? null : poi_hovered.price.selling_price;
                        let marker = new CustomMarker(new google.maps.LatLng(lat, lng), 2, true, false, null, temp_price, map, currency, poi_hovered.poi_type);
                        marker.addListener("click", function () {
                            if (poi_hovered.price !== undefined && poi_hovered.price !== null && poi_hovered.price.selling_price !== 0) {
                                info_window.setOptions({
                                    pixelOffset: new google.maps.Size(20, -25)
                                });
                            }
                            info_window.setContent(ReactDOM.render(
                                <div>
                                    <VisibilityIcon />
                                    <Typography className={ classes.infoWindowTitle }>{ poi_hovered.title }</Typography>
                                </div>, document.createElement("div")));
                            info_window.setPosition(new google.maps.LatLng(lat, lng));
                            info_window.open(map);
                        });
                        visit_markers.push({
                            id: poi_hovered.id,
                            black: true,
                            marker: marker
                        });
                    } else {
                        visit_markers = visit_markers.filter((marker) => {
                            let to_remove = true;
                            poi_list.map((poi) => {
                                if (!marker.black && marker.id === poi.id && marker.price === poi.price) {
                                    to_remove = false;
                                }
                            });
                            if (to_remove) {
                                marker.marker.remove();
                                return false;
                            }
                            return true;
                        });
                        poi_list.map((poi, index_poi) => {
                            let already_added = false;
                            visit_markers.map((marker) => {
                                if (marker.id === poi.id) {
                                    already_added = true;
                                }
                            });
                            if (!already_added) {
                                const lat = parseFloat(poi.place.latitude);
                                const lng = parseFloat(poi.place.longitude);
                                let currency = null;
                                if (poi.currency !== undefined && poi.currency !== null) {
                                    currency = currency_list.find((currency) => {
                                        return currency.id === poi.currency;
                                    });
                                } else if (poi.price !== undefined && poi.price !== null) {
                                    currency = currency_list.find((currency) => {
                                        return currency.id === poi.price.selling_currency;
                                    });
                                }
                                let temp_price = poi.price === undefined || poi.price === null ? null : poi.price.selling_price;
                                let marker = new CustomMarker(new google.maps.LatLng(lat, lng), 2, false, false, index_poi, temp_price, map, currency, poi.poi_type);
                                marker.addListener("click", function () {
                                    this.black = true;
                                    this.div.parentNode.removeChild(this.div);
                                    this.createDiv();
                                    this.appendDivToOverlay();
                                    this.positionDiv();
                                    if (poi.price !== undefined && poi.price !== null && poi.price.selling_price !== 0) {
                                        info_window.setOptions({
                                            pixelOffset: new google.maps.Size(20, -25)
                                        });
                                    }
                                    let stars = [];
                                    if (poi.rating !== null) {
                                        for (let i = 0; i < poi.rating; i++) {
                                            stars.push(<Star key={ i } className={ classes.stars }/>);
                                        }
                                    }
                                    info_window.setContent(ReactDOM.render(
                                        <div className={ classes.clickable } onClick={() => {
                                            router.push(`/${window.url_name}/apps/poi-card/${encodeURIComponent(poi.id)}`); 
                                        }}>
                                            <div className={ classes.sliderContainer }>
                                                <PoiSlider pictures={ poi.pictures } showChevron={ true } size={ 1 } dotSize={ 1 }/>
                                            </div>
                                            <Typography className={ classes.infoWindowTitle }>{ poi.title }</Typography>
                                            { stars }
                                            <br/>
                                            <div className={ classes.marginLeft }>
                                                {
                                                    poi.audio_guide && poi.guide_lang !== null && (
                                                        <Tooltip title={t("poi.audio_guide")}>
                                                            <GraphicEqIcon className={ classes.marginRight } fontSize={ "default" } />
                                                        </Tooltip>
                                                    )
                                                }
                                                {
                                                    poi.guide && (
                                                        <Tooltip title={t("poi.guide_included")}>
                                                            <EmojiPeopleIcon className={ classes.marginRight } fontSize={ "default" } />
                                                        </Tooltip>
                                                    )
                                                }
                                                {
                                                    user.client_full.type !== 2 && poi !== null && poi.is_custom && poi.provider !== null && (
                                                        <Typography className={ classes.grey }>{ poi.provider.provider.name }</Typography>
                                                    )
                                                }
                                                {
                                                    user.client_full.type !== 2 && poi !== null && !poi.is_custom && (
                                                        <Typography className={ classes.grey }>{ poi.provider.name }</Typography>
                                                    )
                                                }
                                                {
                                                    poi.duration >= 1440 && (
                                                        <Typography className={ classes.grey }>{ Math.floor(poi.duration / 1440) } { Math.floor(poi.duration / 1440) > 1 ? t("poi.days") : t("poi.day")} { poi.max_pax !== undefined && poi.max_pax !== null ? ', ' + poi.max_pax + " " + t("poi.max_pax") : poi.max_passengers !== undefined && poi.max_passengers !== null ? ', ' + poi.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                                    )
                                                }
                                                {
                                                    poi.duration >= 60 && poi.duration < 1440 && (
                                                        <Typography className={ classes.grey }>{ poi.duration / 60 } { poi.duration / 60 > 1 ? t("poi.hours") : t("poi.hour")} { poi.max_pax !== undefined && poi.max_pax !== null ? ', ' + poi.max_pax + " " + t("poi.max_pax") : poi.max_passengers !== undefined && poi.max_passengers !== null ? ', ' + poi.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                                    )
                                                }
                                                {
                                                    poi.duration < 60 && (
                                                        <Typography className={ classes.grey }>{ poi.duration } {t("poi.minutes")} { poi.max_pax !== undefined && poi.max_pax !== null ? ', ' + poi_destination.max_pax + " " + t("poi.max_pax") : poi.max_passengers !== undefined && poi.max_passengers !== null ? ', ' + poi.max_passengers + " " + t("poi.max_pax") : "" }</Typography>
                                                    )
                                                }
                                            </div>
                                            <Grid container alignItems="center" justify="flex-start" className={ `${classes.marginLeft} ${classes.marginBottom}` }>
                                                {
                                                    poi.price !== undefined && poi.price !== null && poi.price.length !== 0 && (
                                                        <Grid item>
                                                            <Typography className={ classes.inline } variant={ "body2" }> { t("poi.price_from") + " " }</Typography>
                                                            <CurrencyFormat language={ language } fontSize={ classes.currencyFontSizeTotal } symbol={ (currency !== null ? (currency.symbol === '$' || currency.symbol === '£' ? `${currency.symbol}${currency.iso_code}` : currency.symbol) : '') } price={ poi.price.selling_price } with_precision={ 0 } with_bold={ 0 }/>
                                                        </Grid>
                                                    )
                                                }
                                                {
                                                    poi.recommended &&
                                                    <Grid item>
                                                        <Favorite className={ classes.recommended }/>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </div>, document.createElement("div")));
                                    info_window.setPosition(new google.maps.LatLng(lat, lng));
                                    info_window.open(map);
                                    google.maps.event.addListenerOnce(info_window, "position_changed", function () {
                                        if (marker.div !== null) {
                                            marker.black = false;
                                            marker.div.parentNode.removeChild(marker.div);
                                            marker.createDiv();
                                            marker.appendDivToOverlay();
                                            marker.positionDiv();
                                        }
                                    });
                                    google.maps.event.addListenerOnce(info_window, "closeclick", function () {
                                        if (marker.div !== null) {
                                            marker.black = false;
                                            marker.div.parentNode.removeChild(marker.div);
                                            marker.createDiv();
                                            marker.appendDivToOverlay();
                                            marker.positionDiv();
                                        }
                                    });
                                });
                                visit_markers.push({
                                    id: poi.id,
                                    price: poi.price,
                                    marker: marker
                                });
                            }
                        });
                    }
                }
            }
        }
    });
    return (
        <Fragment>
            <Button className={ classes.mapButton } size={ "small" } variant={ "contained" } startIcon={ map_moved ? <Refresh/> : auto_reload ? <CheckBox/> : <CheckBoxOutlineBlank/> } onClick={() => {
                map_moved ? applyMoved(map) : toggleAutoReload(); 
            }}>{ map_moved ? t("material_map.map_moved") : t("material_map.auto_reload") }</Button>
            <div className={ classes.map } id={ "map" }/>
        </Fragment>
    );
};

export default React.memo(Map);
