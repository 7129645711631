import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SetFetchError, SetFetchedDataStatus, ResetFetchError, CountProvider } from "../../../Actions/FlightSearch";
import ProcessSearchResults from "./ProcessSearchResults";
import ProcessMatrixResults from "./ProcessMatrixResults";
import CountAgePaxType from "./CountAgePaxType";

import moment from "moment";
import axios from "axios";


// eslint-disable-next-line max-params
const SendRequest = (params, headers, group, pax_obj, mode, dispatch, relaunch, source) => {
    console.log('params:', params);
    axios({
        method: "GET",
        url: `${API_HREF}client/${window.id_owner}/flights/`,
        params: params,
        headers: headers,
        cancelToken: source !== undefined ? source.token : undefined
    }).then(function (response) {
        if (mode === "matrix") {
            if (response.data.flight !== undefined && response.data.flight.length === 0) {
                dispatch(SetFetchError("No results"));
                dispatch(SetFetchedDataStatus("No results"));
                dispatch(CountProvider());
            } else {
                ProcessMatrixResults(group, dispatch, response.data, pax_obj);
            }
        } else if (response.data.length === 0) {
            if (relaunch) {
                params.fare_type = "all";
                params.pax_type = `ADT:${pax_obj.ADT},CNN:${pax_obj.CNN},INF:${pax_obj.INF}`;
                SendRequest(params, headers, group, pax_obj, mode, dispatch, false);
            } else {
                dispatch(SetFetchError("No results"));
                dispatch(SetFetchedDataStatus("No results"));
                dispatch(CountProvider());
            }
        } else {
            ProcessSearchResults(group, dispatch, response.data, pax_obj, mode, JSON.parse(localStorage.getItem("config")));
        }
    }).catch(function (error) {
        dispatch(CountProvider());
        dispatch(SetFetchedDataStatus("Done"));
        console.log("error :", error);
    });
};

// eslint-disable-next-line max-params
export default (flight_groups, flight_providers, index_group, trip_id, trip_currency, end_date, user, use_cache, mode, dispatch, source, shouldBeForArrivalDates) => {
    dispatch(ResetFetchError());
    let { pass_check, headers } = CheckBeforeRequest();
    let group = flight_groups[index_group];

    let cabin_class_mapping = [
        "Economy",
        "PremiumEconomy",
        "Business",
        "First"
    ];

    let outbounds = [];
    let cabin_classes = [];
    let quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    group.journey.map((flight) => {
        outbounds.push(flight.origin.airport_code + ":" + flight.destination.airport_code + "," + (shouldBeForArrivalDates ? '!' : '') + moment(flight.start_date).format("YYYY-MM-DD"));
        cabin_classes.push(cabin_class_mapping[flight.class_type]);
    });

    let fare_type = "all";
    if (quotation_code === "visiteurs" && user.client_full.type === 2) {
        fare_type = "private";
    }
    if (group.private_price && !group.public_price) {
        fare_type = "private";
    } else if (group.public_price && !group.private_price) {
        fare_type = "public";
    }

    let pax_obj = CountAgePaxType(group.travelers, end_date);
    if (pass_check) {
        group.provider.map((provider_id) => {
            let params = {
                trip: trip_id,
                outbounds: outbounds.join("|"),
                //pax_type: pax_type,
                fare_type: fare_type,
                cabin_class: cabin_classes.join("|"),
                currency: trip_currency.iso_code,
                provider_id: provider_id
            };

            if (group.included_luggage) {
                params.baggage_only = true;
            }

            if (group.direct_flight) {
                params.max_stops = 0;
            } else if (group.one_stop) {
                params.max_stops = 1;
            }

            if (group.company.length > 0) {
                let airlines_codes = [];
                group.company.map((company) => airlines_codes.push(company.airline_code));
                params.airline = airlines_codes.join();
            }
            if (group.is_honeymoon) {
                params.is_honeymoon = true;
            }
            if (mode === "matrix") {
                params.departure_days_range = 3;
                params.return_days_range = 3;
            }

            if (use_cache) {
                params.use_cache = use_cache;
            }
            if (fare_type === 'all' && flight_providers.find(item => item.id === provider_id) !== undefined && ['1G'].includes(flight_providers.find(item => item.id === provider_id).code)) {
                if (group.private_price) {
                    let params1 = { ...params };
                    params1.fare_type = 'private';
                    params1.pax_type = `ITX:${pax_obj.ADT},INN:${pax_obj.CNN},INF:${pax_obj.INF}`;
                    SendRequest(params1, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                }
                if (group.public_price) {
                    let params2 = { ...params };
                    params2.fare_type = 'public';
                    params2.pax_type = `ADT:${pax_obj.ADT},CNN:${pax_obj.CNN},INF:${pax_obj.INF}`;
                    SendRequest(params2, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                }
                if (!group.private_price && !group.public_price) {
                    params.pax_type = `ADT:${pax_obj.ADT},CNN:${pax_obj.CNN},INF:${pax_obj.INF}`;
                    SendRequest(params, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                }
            } else {
                params.pax_type = `ADT:${pax_obj.ADT},CNN:${pax_obj.CNN},INF:${pax_obj.INF}`;
                if (flight_providers.find(item => item.id === provider_id) !== undefined && ['1G', 'sabre_rest'].includes(flight_providers.find(item => item.id === provider_id).code) && fare_type === "private") {
                    params.pax_type = `ITX:${pax_obj.ADT},INN:${pax_obj.CNN},INF:${pax_obj.INF}`;
                    SendRequest(params, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                } else if (flight_providers.find(item => item.id === provider_id) !== undefined && ['amadeus_rest'].includes(flight_providers.find(item => item.id === provider_id).code)) {
                    if (!group.standard_price && !group.ndc_price) {
                        SendRequest(params, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                    } else {
                        if (group.standard_price) {
                            params.search_source = 'GDS';
                            SendRequest(params, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                        }
                        if (group.ndc_price) {
                            if (!group.private_price_ndc && !group.public_price_ndc) {
                                let params1 = { ...params };
                                params1.search_source = 'NDC';
                                SendRequest(params1, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                            } else {
                                if (group.private_price_ndc) {
                                    let params1 = { ...params };
                                    params1.search_source = 'NDC';
                                    params1.fare_type = 'private';
                                    params1.pax_type = `ITX:${pax_obj.ADT},INN:${pax_obj.CNN},INF:${pax_obj.INF}`;
                                    SendRequest(params1, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                                }
                                if (group.public_price_ndc) {
                                    let params2 = { ...params };
                                    params2.search_source = 'NDC';
                                    params2.fare_type = 'public';
                                    params2.pax_type = `ADT:${pax_obj.ADT},CNN:${pax_obj.CNN},INF:${pax_obj.INF}`;
                                    SendRequest(params2, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                                }
                            }
                        }
                    }
                } else {
                    SendRequest(params, headers, group, pax_obj, mode, dispatch, quotation_code === "visiteurs" && user.client_full.type === 2, source);
                }
            }
        });
    }
};
