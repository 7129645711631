// Dependencies
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
// Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';

const CartQuotation = () => {
    const dispatch = useDispatch();
    const [quotation, setQuotation] = useState(null);
    const [targetLoaded, setTargetLoaded] = useState(false);
    const user = useSelector(state => state.user.user);
    const quotation_target = useSelector(state => state.cart.quotation_target);
    const quotation_category_price_detail = useSelector(state => state.cart.quotation_category_price_detail);
    const quotation_product_price_detail = useSelector(state => state.cart.quotation_product_price_detail);
    const quotation_practical_information = useSelector(state => state.cart.quotation_practical_information);
    const quotation_without_prices = useSelector(state => state.cart.quotation_without_prices);
    const quotation_target_language = useSelector(state => state.cart.quotation_target_language);
    const quotation_cgv = useSelector(state => state.cart.quotation_cgv);
    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;
    useEffect(() => {
        if (targetLoaded === false){
            if (user.client_full.type === 2 || ['cercledesvoyages', 'verdie', 'continentsinsolites', 'terreslointaines', 'travelmuz'].includes(quotation_code)) {
                dispatch({type: 'CART_SET_QUOTATION_TARGET', payload: "CLIENT"});
            }
            setTargetLoaded(true);
        }
    }, [user])
    useEffect(() => {
        if (targetLoaded){
            const { headers } = CheckBeforeRequest();
            headers['Accept-Language'] = quotation_target_language;
            axios({
                method: 'GET',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/quotation/?preview=y&generate_for_target=${(quotation_target === "TO" || quotation_target === 'COMMISSIONED') ? 'n' : 'y'}${quotation_target === "COMMISSIONED" ? '&generate_without_agency_margin=y' : ''}&price_by_category=${quotation_category_price_detail ? 'y' : 'n'}&price_by_product=${quotation_product_price_detail ? 'y' : 'n'}&generate_with_practical_information=${quotation_practical_information ? 'y' : 'n'}&generate_with_cgv=${quotation_cgv ? 'y' : 'n'}&generate_without_prices=${quotation_without_prices ? 'y' : 'n'}`,
            }).then((response) => {
                console.log(response.data);
                setQuotation(response.data);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [quotation_target, quotation_category_price_detail, quotation_product_price_detail, quotation_practical_information, quotation_cgv, targetLoaded, quotation_target_language, quotation_without_prices]);
    return quotation === null ? null : <div dangerouslySetInnerHTML={{__html: quotation}}/>;
};

export default CartQuotation;