import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { flatten } from "lodash";
import { ItineraryDestinationInfoCarousel } from "./itineraryDestinationInfoCarousel";
import { findDestinationGuideDescription } from "../Menu/MaterialTripList/utils/findDestinationGuideDescription";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { ToggleMoreInfoModal } from "../../Actions/Itinerary";
import { AppState } from "../../Reducers/Reducers";

export function ItineraryDestinationInfoModal(): JSX.Element {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const modal_info = useSelector((state: AppState) => state.itinerary.modal_info);
    const destination_info = useSelector((state: AppState) => state.itinerary.destination_info);
    const [rubrics, setRubrics] = useState<string[]>([]);
    const [descriptions, setDescriptions] = useState<{
        title: string,
        content: string
    }[]>([]);
    const container_ref = useRef<HTMLDivElement>(null);
    const content_ref = useRef<HTMLDivElement[]>([]);
    let nb_days: string | null = null;
    let nb_hours = null;

    if (destination_info?.data?.suggested_hours && destination_info.data.suggested_hours > 0) {
        nb_days = (destination_info.data.suggested_hours / 24).toFixed(0);
        nb_hours = (destination_info.data.suggested_hours % 24);
    }

    const name = destination_info?.data?.name ?
        destination_info.data.name ?? '' :
        destination_info?.data?.international_name ?? '';

    //@TODO: implement this
    /*const checkCurrentContent = () => {
        if (container_ref.current) {
            let current_index = 0;
            for (let i = 0; i < content_ref.current.length; i++) {
                if ((container_ref.current.getBoundingClientRect().top - (content_ref.current[i]?.getBoundingClientRect().top ?? 0)) >= 0) {
                    current_index = i;
                }
            }
            if (rubric_ref.current.length > 0) {
                rubric_ref.current[current_index].className = "active";
            }
        }
    }; */

    useEffect(() => {
        if (!modal_info) {
            setRubrics([]);
            setDescriptions([]);
        }
    }, [modal_info]);

    useEffect(() => {
        (async () => {
            if (destination_info) {
                const { pass_check, headers } = CheckBeforeRequest();
                if (pass_check) {
                    try {
                        const guides = await findDestinationGuideDescription(
                            [destination_info.id],
                            null,
                            null
                        );
                        setRubrics(
                            flatten(
                                guides.map((guide) => {
                                    return guide.rubrics.map((rubric) => {
                                        return rubric.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.title ?? ''
                                    })
                                })
                            )
                        );
                        setDescriptions(
                            flatten(
                                guides.map((guide) => {
                                    return guide.rubrics.map((rubric) => {
                                        const localization = rubric.localization.find((item) => {
                                            return item.locale === locale;
                                        });
                                        return {
                                            title: localization?.title ?? '',
                                            content: localization?.description ?? ''
                                        }
                                    })
                                })
                            )
                        );
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        })();
    }, [destination_info]);

    return (
        <Dialog
            open={modal_info}
            scroll="paper"
            onClose={() => dispatch(ToggleMoreInfoModal(!modal_info, null, null))}
            PaperProps={{
                sx: {
                    maxWidth: 800,
                    marginY: 0,
                    minHeight: '100vh',
                    borderRadius: 0
                }
            }}
            fullWidth
        >
            <DialogContent sx={{ paddingTop: 0 }}>
                <Box sx={{ position: 'relative' }}>
                    <Box
                        id={"modal-more-info-header"}
                        sx={{
                            position: 'absolute',
                            zIndex: 1,
                            bottom: 0,
                            left: '-24px',
                            width: 'calc(100% + 48px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            paddingBottom: 2,
                            paddingLeft: 1.5
                        }}
                    >
                        <h1 className={"title-more-info"} style={{ color: '#fff' }}>
                            <span>
                                {name.split(',')[0]}
                                {name.split(',').length > 1 ? ', ' : null}
                            </span>
                            <Box sx={{ fontSize: '0.6em' }} component="span">
                                {name.split(',').slice(1).join(', ')}
                            </Box>
                        </h1>
                    </Box>
                    {
                        destination_info &&
                        <ItineraryDestinationInfoCarousel pictures={destination_info.data?.pictures ?? []} />
                    }
                    <IconButton
                        size="small"
                        onClick={() => dispatch(ToggleMoreInfoModal(!modal_info, null, null))}
                        sx={(theme) => ({
                            color: '#fff',
                            position: 'absolute',
                            top: theme.spacing(2),
                            right: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.4)'
                        })}
                    >
                        <Close color="inherit" fontSize="inherit" />
                    </IconButton>
                </Box>
                <Box
                    className={"header-body-sep"}
                    sx={{ marginBottom: 3 }}
                />
                <div id={"modal-more-info"} className={"row"}>
                    <div className={"col-md-4"}>
                        <div className={"left-container"}>
                            <div>
                                <Typography variant="h3" fontWeight={700} sx={(theme) => ({ color: `${theme.palette.secondary.main} !important`, marginBottom: 1.5 })}>{t("global.informations")}</Typography>
                                <Typography variant="h4" fontWeight={500} sx={(theme) => ({ color: `${theme.palette.secondary.main} !important`, marginBottom: 1.5 })}>{t("global.name")}</Typography>
                                <Typography variant="h5" sx={(theme) => ({ color: '#000 !important', marginBottom: 1.5 })}>
                                    <Box sx={{ fontWeight: 500 }}>
                                        {name.split(',')[0]}
                                    </Box>
                                    <div>
                                        {name.split(',').slice(1).join(', ')}
                                    </div>
                                </Typography>
                                <div className={"infos-sep"} />
                                <Typography variant="h4" fontWeight={500} sx={(theme) => ({ color: `${theme.palette.secondary.main} !important`, marginBottom: 1.5 })}>{t("global.country")}</Typography>
                                <Typography variant="h5" sx={(theme) => ({ color: '#000 !important', marginBottom: 1.5 })}>
                                    {destination_info?.data?.country ? destination_info.data.country?.name : destination_info?.data?.country?.international_name}
                                </Typography>
                                {
                                    destination_info?.categories && destination_info.categories.length > 0 &&
                                    <div>
                                        <div className={"infos-sep"} />
                                        <Typography variant="h4" fontWeight={500} sx={(theme) => ({ color: `${theme.palette.secondary.main} !important`, marginBottom: 1.5 })}>{t("global.categories")}</Typography>
                                        <div className={"row ft-no-margin"}>
                                            {
                                                destination_info.categories.map((categories, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            className={"categories-infos"}
                                                            sx={(theme) => ({ backgroundColor: `${theme.palette.primary.main} !important` })}
                                                        >
                                                            {categories.name}
                                                        </Box>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                <div className={"infos-sep ft-mt10"} />
                                {
                                    (nb_days !== null || nb_hours !== null) && (
                                        <div>
                                            <Typography variant="h4" fontWeight={500} sx={(theme) => ({ color: `${theme.palette.secondary.main} !important`, marginBottom: 1.5 })}>{t("itinerary.suggested_duration")}</Typography>
                                            {
                                                nb_days !== null && parseFloat(nb_days) > 0 && parseFloat(nb_days) < 2 && (
                                                    <h5>1 {t("global.day")}</h5>
                                                )
                                            }
                                            {
                                                nb_days !== null && parseFloat(nb_days) >= 2 && (
                                                    <h5>{nb_days} {t("global.days")} </h5>
                                                )
                                            }
                                            {
                                                nb_hours !== null && nb_hours > 0 && (
                                                    <h5>{nb_hours}{t("global.abrev_hour")} </h5>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div className={"mt30"}>
                                <Typography variant="h3" fontWeight={500} sx={(theme) => ({ color: `${theme.palette.secondary.main} !important`, marginBottom: 1.5 })}>{t("global.summary")}</Typography>
                                <div id={"container-tab-info"}>
                                    {
                                        rubrics.map((rubric, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={"tab-info"}
                                                    onClick={() => content_ref.current[index]?.scrollIntoView({ block: 'start', behavior: 'smooth' })}
                                                    style={{ color: '#000 !important' }}
                                                >
                                                    {rubric}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={"col-md-8"}>
                        <div className={"right-container"}>
                            <div
                                ref={container_ref}
                                id={"guide-content"}
                                onScroll={() => {
                                    /*checkCurrentContent();  */
                                }}
                            >
                                {
                                    descriptions.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Typography
                                                    fontWeight={500}
                                                    sx={(theme) => ({
                                                        color: `${theme.palette.secondary.main} !important`,
                                                        marginBottom: 3
                                                    })}
                                                >
                                                    {item.title}
                                                </Typography>
                                                <Typography
                                                    ref={(node) => {
                                                        if (node) {
                                                            content_ref.current.push(node);
                                                        }
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: item.content }}
                                                />
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
