import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Chip,
    Collapse,
    Grid,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {
    BuildCircle,
    Close,
    CloudCircle,
    CloudOff,
    ErrorOutline,
    ExpandLess,
    ExpandMore,
    Hotel,
    ListAlt,
    MoreVert,
    ReportProblem
} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { makeStyles } from "@material-ui/core";
import { isNumber } from "lodash";
import axios from "axios";
import clsx from "clsx";
import LightTooltip from "../Menu/MaterialTripList/utils/tooltip/lightTooltip";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItem } from "./CartConstructionProductsTableItem";
import { CartConstructionProductsTableItemMenu } from "./CartConstructionProductsTableItemMenu";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from "./CartConstructionProductsTableItemProviderQuotationStatus";
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from "./CartPackageItemChangeProviderQuotationStatusButtons";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { findProductItineraryError } from "./utils/findProductItineraryError";
import { hasMoreNights } from "./utils/hasMoreNights";
import { useCartProducts } from "../Itinerary/network/cartProducts";
import { useTripDays } from "./utils/tripDays";
import { useGetPrice } from "./utils/getPrice";
import { useProductDays } from "./utils/productDays";
import { useManualProductAlerts } from "./utils/manualProductAlerts";
import { useShowError } from "../Utils/showError";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { Currency } from "../../Reducers/objects/currency";
import { TravelerGroup } from "../../Reducers/objects/travelerGroup";
import { AppState } from "../../Reducers/Reducers";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { isProductPackaged } from "./utils/isProductPackaged";
import { ProviderContext } from "./utils/providerContext";

type Props = {
    item: ReturnType<typeof useCartProducts>['accommodations'][number],
    accommodations: ReturnType<typeof useCartProducts>['accommodations'][number][]
}

export const CartConstructionProductsTableHotelItem = withRouter<Props>(
    function (props): JSX.Element {
        const { t, i18n } = useTranslation();
        const classes = useStyles();
        const dispatch = useDispatch();
        const language = useSelector((state: AppState) => state.header.tmp_language);
        const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1);
        const providers = useSelector((state: AppState) => state.trip.providers);
        const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
        const currency = useSelector((state: AppState) => state.trip.currency);
        const currency_list = useSelector((state: AppState) => state.base.currency_list);
        const traveler_groups = useSelector((state: AppState) => state.trip.traveler_groups);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const user = useSelector((state: AppState) => state.user.user);
        const [openMenu, setOpenMenu] = useState(false);
        const [expanded, setExpanded] = useState(true);
        const [openReferencesTooltip, setOpenReferencesTooltip] = useState(false);
        const [groups, setGroups] = useState<TravelerGroup[]>([]);
        const provider = useMemo(() => {
            return manual_providers.find((item) => {
                return item.provider.id === props.item.accommodation.provider;
            }) ?? providers.find((item) => {
                return item.provider.id === props.item.accommodation.provider;
            });
        }, [providers, manual_providers, props.item]);
        const menuButtonRef = useRef<HTMLButtonElement>(null);
        const showError = useShowError();
        const update = useCartProductUpdate({
            onError(error) {
                console.error(error);
                showError(error);
            }
        });
        const tripDays = useTripDays();
        const getDays = useProductDays();
        const getPrice = useGetPrice();
        const manualProductAlerts = useManualProductAlerts(
            props.item.type === 'manual' ?
                props.item.accommodation :
                null
        );
        const startDate = window.moment.utc(props.item.accommodation.start_date);
        const endDate = window.moment.utc(props.item.accommodation.end_date);
        const priceCurrencyConverter = (first_price: any, first_currency: Currency | undefined, second_currency: Currency | undefined) => {
            if (first_currency?.iso_code === second_currency?.iso_code) {
                return first_price;
            }
            return (
                first_currency?.iso_code !== 'USD' ?
                    first_price / (first_currency?.usd_factor ?? 1) :
                    first_price
            ) * (
                    second_currency?.usd_factor ?? 0
                );
        };
        const address = useMemo(() => {
            const info = props.item.type === 'normal' ?
                props.item.accommodation.hotel[0] :
                undefined;
            let address = '';
            if (info?.city_name) {
                address = info.city_name + (info.destination_name && info.destination_name !== info.city_name ? `, ${info.destination_name}` : '');
            } else if (info?.destination_name) {
                address = info.destination_name;
            } else {
                address = props.item.accommodation.start_destination?.data?.localization.find((item) => {
                    return item.locale === locale;
                })?.name ??
                    props.item.accommodation.start_destination?.data?.international_name ??
                    '';
            }
            return address;
        }, [locale, props.item]);
        const resortFees = useMemo(() => {
            if (props.item.type === 'manual') {
                return null;
            }

            let resort_fees = null;

            for (const room of (props.item.accommodation.rooms ?? [])) {
                if (!room.resort_fees_included && room.resort_fees && room.resort_fees !== '0.00') {
                    if (resort_fees === null) {
                        let resort_fees_currency = currency_list.find(currency => currency.id === room.resort_fees_currency_id);
                        resort_fees = {
                            cost: parseFloat(room.resort_fees),
                            cost_currency: room.resort_fees_currency_id ? resort_fees_currency : null,
                            converted_cost: room.resort_fees_currency_id ? priceCurrencyConverter(parseFloat(room.resort_fees), resort_fees_currency, currency) : parseFloat(room.resort_fees),
                            converted_currency: currency
                        };
                    } else {
                        resort_fees.cost += parseFloat(room.resort_fees);
                        resort_fees.converted_cost += resort_fees.cost_currency ? priceCurrencyConverter(parseFloat(room.resort_fees), resort_fees.cost_currency, currency) : parseFloat(room.resort_fees);
                    }
                }
            }

            if (resort_fees) {
                resort_fees.cost = resort_fees.cost;
                resort_fees.converted_cost = resort_fees.converted_cost.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }

            return resort_fees;
        }, [props.item, currency_list, currency]);
        const references = useMemo(() => {
            if (props.item.type === 'manual') {
                return null;
            }

            let references = '';
            let references_count = 0;

            for (const room of (props.item.accommodation.rooms ?? [])) {
                if (
                    room.booking_status &&
                    room.booking_status.item_reference &&
                    room.booking_status.item_reference !== '' && references_count <= 3
                ) {
                    if (references_count === 3) {
                        references += '...';
                        references_count++;
                    } else if (references_count < 3) {
                        if (references_count > 0) {
                            references += ', ';
                        }
                        references += room.booking_status.item_reference;
                        references_count++;
                    }
                }
            }

            return references;
        }, [props.item]);
        const { found, step, iti_error } = useMemo(() => {
            if (props.item.type === 'normal') {
                return findProductItineraryError(
                    'accommodation',
                    {
                        product: props.item.accommodation,
                        itinerary,
                        stackInfos: trip?.stack_info ?? null
                    }
                );
            }
            const result = findProductItineraryError(
                'manual',
                {
                    product: props.item.accommodation,
                    itinerary,
                    stackInfos: trip?.stack_info ?? null
                }
            );
            return result ?? { found: false, iti_error: null };
        }, [
            itinerary,
            props.item,
            trip
        ]);
        const cart = useCartProducts();
        const accommodations = useMemo(() => {
            return cart.accommodations.map((item) => {
                return {
                    ...item.accommodation,
                    is_manual: item.type === 'manual'
                };
            }).filter((item) => {
                return item.is_displayed && !item.is_optional;
            });
        }, [cart.accommodations]);
        const stepNightsDiff = useMemo(() => {
            if (step) {
                return hasMoreNights({
                    step,
                    accommodations
                });
            }
            return 0;
        }, [step, accommodations]);
        const totalCost = props.item.type === 'normal' ?
            props.item.accommodation.rooms.reduce(
                (prev, current) => {
                    const price = getPrice(current.prices);
                    return {
                        cost: prev.cost + price.cost,
                        currency: price.currency
                    };
                },
                { cost: 0, currency: undefined } as { cost: number, currency: Currency | undefined }
            ) :
            getPrice(props.item.accommodation.prices);
        const days = getDays(props.item.accommodation.start_date, props.item.accommodation.end_date);
        const providerContext = useContext(ProviderContext);
        const variantName = props.item.accommodation.variant &&
            !isNumber(props.item.accommodation.variant) ?
            props.item.accommodation.variant.localization?.find((item) => {
                return item.locale === locale;
            })?.title ?? props.item.accommodation.variant.name :
            null;

        const onSearchMissingRooms = () => {
            if (props.item.type === 'normal') {
                let current_groups = [];
                let traveler_number = 0;
                for (const group of groups) {
                    current_groups.push(group);
                    traveler_number += group.travelers.length;
                }
                dispatch({
                    type: 'ACCOMMODATION_GO_TO_CARD',
                    payload: {
                        start_date: props.item.accommodation.start_date,
                        end_date: props.item.accommodation.end_date,
                        current_groups: current_groups,
                        traveler_number: traveler_number
                    }
                });
                props.router.push(`/${window.url_name}/apps/accommodation-card/${props.item.accommodation.hotel[0]?.id}`);
            }
        };
        const confirmRemarkBookingRecalc = () => {
            const { headers } = CheckBeforeRequest();
            for (const room of (props.item.accommodation.rooms ?? [])) {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/accommodation/${room.id}/`,
                    data: {
                        moved_with_recalc_remark_booking: false
                    }
                }).then(response => {
                    dispatch({ type: 'ACCOMMODATION_EDIT_CART_BY_ID', payload: response.data });
                }).catch(error => {
                    console.log(error);
                });
            }
        };

        const onDeleteTravelerModificationAlert = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
            const version = parseInt(GetCookie('trip_id_version') ?? '-1');
            update(
                version,
                {
                    id: props.item.accommodation.id,
                    type: props.item.type === 'normal' ?
                        'accommodation' :
                        'manual-accommodation',
                    roomIds: props.item.type === 'normal' ?
                        props.item.accommodation.rooms.map((room) => {
                            return room.id;
                        }) :
                        [],
                    isCustom: props.item.accommodation.is_custom
                },
                { traveler_modification: null }
            );
        };

        const alerts = [
            resortFees &&
            <Alert className={classes.resortFees} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'warning'}>
                {t('cart-material.resort-fees-1')}{' '}
                {
                    resortFees.cost.toLocaleString(
                        language,
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )
                }{resortFees.cost_currency ? resortFees.cost_currency?.symbol : ''}{' '}
                ({t('cart-material.resort-fees-2')}{' '}
                {resortFees.converted_cost}{resortFees.converted_currency ? resortFees.converted_currency.symbol : ''}){' '}
                {t('cart-material.resort-fees-3')}
            </Alert>,
            resortFees === null && props.item.type === 'normal' && props.item.accommodation.resort_fees_included &&
            <Alert className={classes.resortFees} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'warning'}>
                {t('cart.resort_fees_text_included_1_v1')}
            </Alert>,
            props.item.type === 'normal' && !!props.item.accommodation.resort_fees_text &&
            <Alert className={classes.resortFees} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'warning'}>
                {props.item.accommodation.resort_fees_text}
            </Alert>,
            ...(
                props.item.type === 'normal' ?
                    props.item.accommodation.rooms?.map((room) => {
                        if (room.booking_status && room.booking_status.status_booking === 'WAITING' && room.booking_status.async_fetch_required) {
                            return (
                                <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'warning'}>
                                    {t('cart-material.expedia-booking-waiting')}
                                </Alert>
                            );
                        }
                    }) :
                    []
            ),
            props.item.accommodation.booking_status && props.item.accommodation.booking_status.status_booking === 'UNAVAILABLE' &&
            <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                {t('cart-material.booking-unavailable')} : {references}
            </Alert>,
            props.item.type === 'normal' &&
            props.item.accommodation.booking_status &&
            (props.item.accommodation.booking_status.status_booking === 'ERROR' || props.item.accommodation.booking_status.status_booking === 'UNAVAILABLE') &&
            props.item.accommodation.booking_errors && props.item.accommodation.booking_errors.length > 0 &&
            <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                <AlertTitle>{t('cart-material.error')} :</AlertTitle>
                {
                    props.item.accommodation.booking_errors.map((error) => (
                        <Typography key={`booking-error-${error.created_date}`}>{window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}</Typography>
                    ))
                }
            </Alert>,
            groups.length > 0 &&
            <Alert variant={'filled'} severity={'warning'} action={
                <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onSearchMissingRooms}>
                    {t('cart-material.search-missing-rooms')}
                </Button>
            }>
                {t('cart-material.search-missing-rooms-info')}
            </Alert>,
            iti_error &&
            <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                {t('cart-material.itinerary-error-1')}{' '}
                {
                    iti_error.destination?.data?.localization.find((item) => {
                        return item.locale === locale;
                    })?.name ??
                    iti_error.destination?.data?.name ??
                    iti_error.destination?.data?.international_name
                } {t('global.from')}{' '}
                {window.moment.utc(iti_error.start_date).format('DD/MM/YYYY')}{' '}
                {t('global.to')} {window.moment.utc(iti_error.end_date).format('DD/MM/YYYY')}.{' '}
                {t('cart-material.itinerary-error-2')}.
            </Alert>,
            !found &&
            <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                {t('cart-material.no-destination-found')}
            </Alert>,
            stepNightsDiff !== 0 &&
            <Alert variant="filled" severity="warning">
                {
                    t<string>(
                        'cart-material.cart-construction-step-has-more-nights',
                        {
                            count: stepNightsDiff,
                            destination: step?.destination?.data?.localization.find((item) => {
                                return item.locale === locale;
                            })?.name ??
                            step?.destination?.data?.name ??
                            step?.destination?.data?.international_name
                        }
                    )
                }
            </Alert>,
            props.item.type === 'normal' &&
            (
                props.item.accommodation.remark_booking ||
                (
                    props.item.accommodation.rooms.length !== 0 &&
                    props.item.accommodation.rooms.findIndex((el, index) => {
                        return index !== 0 && el.remark_booking;
                    }) >= 0
                )
            ) &&
            <Alert className={`${classes.remarkExpand}`} variant={'filled'} severity={'info'} action={
                <Grid container direction={'column'} alignItems={'flex-end'}>
                    <Grid item>
                        {
                            !expanded && (
                                <ExpandMore className={`${classes.deleteCustomInformation}`} onClick={() => {
                                    setExpanded(!expanded);
                                }} />
                            )
                        }
                        {
                            expanded && (
                                <ExpandLess className={`${classes.deleteCustomInformation}`} onClick={() => {
                                    setExpanded(!expanded);
                                }} />
                            )
                        }
                    </Grid>
                    {
                        props.item.accommodation.moved_with_recalc_remark_booking &&
                        <Grid item style={{ width: '100%' }}>
                            <Tooltip title={t('cart-material.recalc-product-remark-booking')}>
                                <div
                                    className={clsx(classes.traverlerModification, classes.delete)}
                                    onClick={confirmRemarkBookingRecalc}
                                    style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                >
                                    <Grid container alignItems={'center'}>
                                        <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                        <Grid item>{t('cart-material.recalc')}</Grid>
                                        <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                    </Grid>
                                </div>
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
            }>
                <AlertTitle>{t('cart-material.remark-booking')} :</AlertTitle>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {
                        props.item.accommodation.rooms.length === 1 && (
                            <span>{props.item.accommodation.remark_booking}</span>
                        )
                    }
                    {
                        props.item.accommodation.rooms.length > 1 && props.item.accommodation.rooms.map((room, room_index) => {
                            if (room.remark_booking) {
                                return (
                                    <p key={`remark_bookking-${room.id}`}>{t('global.room')} {room_index + 1} : {room.remark_booking}</ p>
                                );
                            }
                        })
                    }
                </Collapse>
            </Alert>
        ].filter((item): item is Exclude<typeof item, false> => !!item).concat(manualProductAlerts);

        useEffect(() => {
            if (props.item.type === 'normal') {
                let tmp_groups: TravelerGroup[] = [];
                let tmp_travelers: number[] = [];
                if (!((props.item.accommodation.booking_status && props.item.accommodation.booking_status.status_booking === 'CANCELLED') || props.item.accommodation.booking_process_state === 'CANCEL')) {
                    for (let i = 0; i < traveler_groups.length; i++) {
                        if (traveler_groups[i]!.default) {
                            tmp_groups.push({ ...traveler_groups[i]! });
                        }
                    }
                }
                props.item.accommodation.rooms.map(room => {
                    for (let i = 0; i < tmp_groups.length; i++) {
                        if (tmp_groups[i]!.id === room.group_passenger.id) {
                            tmp_groups.splice(i, 1);
                            break;
                        }
                    }
                    for (let i = 0; i < room.group_passenger.travelers.length; i++) {
                        if (tmp_travelers.includes(room.group_passenger.travelers[i]!)) {
                            tmp_travelers.splice(tmp_travelers.indexOf(room.group_passenger.travelers[i]!), 1);
                        }
                    }
                    if (tmp_travelers.length === 0) {
                        tmp_groups = [];
                    }
                });
                const displayedAccommodations = props.accommodations.filter((item) => {
                    return item.type === 'normal';
                }).map((item) => item.accommodation) as AccommodationCart[];
                if (displayedAccommodations && displayedAccommodations) {
                    for (const other_accommodation of displayedAccommodations) {
                        if (other_accommodation.id !== props.item.accommodation.id && !(other_accommodation as any).is_manual) {
                            if (
                                other_accommodation.is_displayed &&
                                props.item.accommodation.hotel[0]?.id === other_accommodation.hotel[0]?.id &&
                                props.item.accommodation.start_date === other_accommodation.start_date &&
                                props.item.accommodation.end_date === other_accommodation.end_date &&
                                props.item.accommodation.on_request === other_accommodation.on_request &&
                                props.item.accommodation.is_optional === other_accommodation.is_optional &&
                                props.item.accommodation.variant === other_accommodation.variant &&
                                (
                                    (props.item.accommodation.booking_status === null && other_accommodation.booking_status === null) ||
                                    (
                                        props.item.accommodation.booking_status &&
                                        other_accommodation.booking_status &&
                                        (
                                            (
                                                props.item.accommodation.booking_status.status_booking !== 'CANCELLED' &&
                                                other_accommodation.booking_status.status_booking !== 'CANCELLED' &&
                                                props.item.accommodation.booking_process_state !== 'CANCEL' &&
                                                other_accommodation.booking_process_state !== 'CANCEL'
                                            ) ||
                                            (
                                                props.item.accommodation.booking_status.status_booking === 'CANCELLED' &&
                                                other_accommodation.booking_status.status_booking === 'CANCELLED'
                                            )
                                        )
                                    )
                                )
                            ) {
                                other_accommodation.rooms.map(room => {
                                    for (let i = 0; i < tmp_groups.length; i++) {
                                        if (tmp_groups[i]!.id === room.group_passenger.id) {
                                            tmp_groups.splice(i, 1);
                                            break;
                                        }
                                    }
                                    for (let i = 0; i < room.group_passenger.travelers.length; i++) {
                                        if (tmp_travelers.includes(room.group_passenger.travelers[i]!)) {
                                            tmp_travelers.splice(tmp_travelers.indexOf(room.group_passenger.travelers[i]!), 1);
                                        }
                                    }
                                    if (tmp_travelers.length === 0) {
                                        tmp_groups = [];
                                    }
                                });
                            }
                        }
                    }
                }
                setGroups(tmp_groups);
            }
        }, [props.item.accommodation]);

        return (
            <>
                <CartConstructionProductsTableItem
                    {
                    ...(
                        props.item.type === 'normal' ?
                            {
                                type: 'accommodation',
                                item: props.item.accommodation,
                                accommodations: props.accommodations
                            } :
                            { type: 'manual-accommodation', item: props.item.accommodation }
                    )
                    }
                >
                    <LightTooltip
                        title={alerts}
                        PopperProps={{ onClick: (event) => event.stopPropagation() }}
                        placement="top"
                        arrow
                    >
                        <TableCell sx={{ verticalAlign: 'middle', paddingRight: 0 }}>
                            {
                                alerts.length > 0 &&
                                <ReportProblem color="warning" />
                            }
                        </TableCell>
                    </LightTooltip>
                    <TableCell>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            flexWrap="nowrap"
                        >
                            <LightTooltip title="Hôtel" placement="top" arrow>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    flexWrap="nowrap"
                                >
                                    <Hotel />
                                    <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                        sx={{ whiteSpace: 'nowrap' }}
                                    >
                                        {t('cart-material.cart-construction-hotel')}
                                    </Typography>
                                </Stack>
                            </LightTooltip>
                            {
                                !isProductPackaged({
                                    product: props.item.accommodation,
                                    stackInfos: trip?.stack_info ?? null,
                                    connected: true
                                }) &&
                                (
                                    user?.client_full?.type !== 2 ||
                                    props.item.accommodation.creator?.client?.type === user.client_full.type
                                ) &&
                                <>
                                    {
                                        props.item.type === 'normal' &&
                                        !props.item.accommodation.is_custom &&
                                        <Chip
                                            label={t('cart-material.cart-construction-flux')}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#2ACAEA',
                                                color: '#fff'
                                            }}
                                            icon={<CloudCircle color="inherit" />}
                                        />
                                    }
                                    {
                                        props.item.type === 'normal' &&
                                        props.item.accommodation.is_custom &&
                                        <Chip
                                            label={t('cart-material.cart-construction-offline')}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#6A329F',
                                                color: '#fff'
                                            }}
                                            icon={<CloudOff color="inherit" />}
                                        />
                                    }
                                    {
                                        props.item.type === 'manual' &&
                                        <Chip
                                            label={t('accommodation.manual')}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#6A329F',
                                                color: '#fff'
                                            }}
                                            icon={<BuildCircle color="inherit" />}
                                        />
                                    }
                                </>
                            }
                        </Stack>
                        <Typography
                            variant="caption"
                            component="div"
                            sx={{
                                position: 'absolute',
                                marginTop: 1,
                                opacity: 0.7
                            }}
                        >
                            <LightTooltip
                                title={
                                    (!days[0] || !tripDays.includes(days[0])) ?
                                        t(
                                            'cart-material.cart-construction-incorrect-product-days',
                                            { day: tripDays.join(', ') }
                                        ) :
                                        ''
                                }
                                arrow
                            >
                                <Box
                                    sx={
                                        (!days[0] || !tripDays.includes(days[0])) ?
                                            {
                                                backgroundColor: '#ff0000',
                                                color: '#fff'
                                            } :
                                            undefined
                                    }
                                    component="span"
                                >
                                    {
                                        days[0] !== days[days.length - 1] ?
                                            t(
                                                'cart-material.cart-construction-day-from-to',
                                                { from: days[0], to: days[days.length - 1] }
                                            ) :
                                            t(
                                                'cart-material.cart-construction-day',
                                                { day: days[0] }
                                            )
                                    }
                                </Box>
                            </LightTooltip>
                            {' '}-{' '}
                            {
                                (startDate.isValid() || endDate.isValid()) &&
                                t(
                                    'cart-material.cart-construction-products-table-date',
                                    {
                                        from: startDate.isValid() ? startDate.format('L') : null,
                                        to: endDate.isValid() ? endDate.format('L') : null
                                    }
                                )
                            }
                            {' '}(
                            {
                                t(
                                    'cart-material.cart-construction-hotel-nights',
                                    { count: endDate.clone().startOf('day').diff(startDate.clone().startOf('day'), 'day') }
                                )
                            }
                            )
                            <Stack
                                display="inline-flex"
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{ marginLeft: 1.5 }}
                            >
                                <CartConstructionProductsTableItemProviderQuotationStatus
                                    item={props.item.accommodation}
                                />
                            </Stack>
                            {
                                props.item.accommodation.traveler_modification &&
                                <Chip
                                    color="warning"
                                    label={
                                        props.item.accommodation.traveler_modification === 'SET_IN_TRIP' ?
                                            t('cart-material.add-to-cart-traveler') :
                                            t('cart-material.add-to-option-traveler')
                                    }
                                    size="small"
                                    icon={<ErrorOutline color="inherit" />}
                                    onDelete={onDeleteTravelerModificationAlert}
                                />
                            }
                            {
                                user?.client_full?.type !== 2 &&
                                props.item.type === 'normal' &&
                                props.item.accommodation.static_contract &&
                                <Chip
                                    label={t('accommodation.direct-contract')}
                                    size="small"
                                    icon={<ListAlt color="inherit" />}
                                />
                            }
                        </Typography>
                    </TableCell>
                    <LightTooltip title={address} placement="top" arrow>
                        <TableCell>
                            <div className="ellipsis">
                                {address}
                            </div>
                        </TableCell>
                    </LightTooltip>
                    <LightTooltip
                        title={
                            <>
                                {
                                    props.item.type === 'normal' ?
                                        props.item.accommodation.localization?.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        props.item.accommodation.hotel[0]?.name :
                                        props.item.accommodation.localization?.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        props.item.accommodation.name
                                }
                                {
                                    variantName &&
                                    ` (${variantName})`
                                }
                            </>
                        }
                        placement="top"
                        arrow
                    >
                        <TableCell>
                            <div className="ellipsis">
                                {
                                    props.item.type === 'normal' ?
                                        props.item.accommodation.localization?.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        props.item.accommodation.hotel[0]?.name :
                                        props.item.accommodation.localization?.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        props.item.accommodation.name
                                }
                                {
                                    variantName &&
                                    ` (${variantName})`
                                }
                            </div>
                        </TableCell>
                    </LightTooltip>
                    <TableCell>
                        <Box sx={{ textAlign: 'center' }}>
                            {
                                !isProductPackaged({
                                    product: props.item.accommodation,
                                    stackInfos: trip?.stack_info ?? null
                                }) &&
                                <CartProductCardProviderLogo
                                    logoUrl={
                                        provider?.provider.logo?.url ??
                                        provider?.provider.logo?.thumbnail_big ??
                                        provider?.provider.logo?.thumbnail_medium ??
                                        provider?.provider.logo?.thumbnail_little ??
                                        null
                                    }
                                    name={provider?.provider.name ?? null}
                                    width={48}
                                    height={32}
                                />
                            }
                        </Box>
                    </TableCell>
                    <LightTooltip
                        title={
                            //eslint-disable-next-line no-nested-ternary
                            !isProductPackaged({
                                product: props.item.accommodation,
                                stackInfos: trip?.stack_info ?? null,
                                connected: true
                            }) &&
                                props.item.accommodation.rooms?.length === 1 ?
                                (
                                    props.item.accommodation.booking_status?.item_reference ?
                                        t(
                                            'cart-material.cart-construction-reference',
                                            { ref: props.item.accommodation.booking_status.item_reference }
                                        ) :
                                        ''
                                ) :
                                ''
                        }
                        placement="top"
                        arrow
                    >
                        <TableCell
                            colSpan={
                                props.item.type === 'normal' &&
                                    (props.item.accommodation.rooms?.length ?? 0) > 1 ?
                                    2 :
                                    1
                            }
                        >
                            {
                                !isProductPackaged({
                                    product: props.item.accommodation,
                                    stackInfos: trip?.stack_info ?? null,
                                    connected: true
                                }) &&
                                (
                                    props.item.type === 'manual' ||
                                    props.item.accommodation.rooms?.length === 1
                                ) &&
                                <div className="ellipsis">
                                    {
                                        props.item.accommodation.booking_status?.item_reference &&
                                        t(
                                            'cart-material.cart-construction-reference',
                                            { ref: props.item.accommodation.booking_status.item_reference }
                                        )
                                    }
                                </div>
                            }
                            {
                                !isProductPackaged({
                                    product: props.item.accommodation,
                                    stackInfos: trip?.stack_info ?? null,
                                    connected: true
                                }) &&
                                props.item.type === 'normal' && (props.item.accommodation.rooms?.length ?? 0) > 1 &&
                                <>
                                    <Typography
                                        variant="body2"
                                        sx={{ textAlign: 'center' }}
                                        className="ellipsis"
                                    >
                                        {
                                            t(
                                                'cart-material.cart-construction-rooms-count',
                                                { count: props.item.accommodation.rooms.length }
                                            )
                                        }
                                    </Typography>
                                    {
                                        props.item.type === 'normal' &&
                                            props.item.accommodation.booking_status?.status_booking ?
                                            <LightTooltip
                                                open={openReferencesTooltip}
                                                title={
                                                    <Box
                                                        component="ul"
                                                        sx={{
                                                            padding: 3,
                                                            paddingTop: 1,
                                                            paddingBottom: 0,
                                                            margin: 0,
                                                            listStyle: 'none',
                                                            li: {
                                                                marginBottom: 1
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            props.item.accommodation.rooms?.map((room, index) => (
                                                                <li key={room.id}>
                                                                    <Typography>
                                                                        {
                                                                            t(
                                                                                'cart-material.cart-construction-room-ref',
                                                                                {
                                                                                    no: index + 1,
                                                                                    ref: room.booking_status?.item_reference
                                                                                }
                                                                            )
                                                                        }
                                                                    </Typography>
                                                                    <CartConstructionProductsTableItemStatus
                                                                        type="accommodation"
                                                                        item={props.item.accommodation as AccommodationCart}
                                                                        bookingProcessState={room.booking_process_state}
                                                                        bookingStatus={room.booking_status}
                                                                        agencyNeedToBook={room.agency_need_to_book}
                                                                    />
                                                                </li>
                                                            ))
                                                        }
                                                    </Box>
                                                }
                                                onClose={() => setOpenReferencesTooltip(false)}
                                                arrow
                                            >
                                                <Button
                                                    size="small"
                                                    sx={{ display: 'block', margin: 'auto' }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setOpenReferencesTooltip(true);
                                                    }}
                                                >
                                                    {t('cart-material.cart-construction-show-refs')}
                                                </Button>
                                            </LightTooltip> :
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: 1
                                                }}
                                            >
                                                <CartConstructionProductsTableItemStatus
                                                    type="accommodation"
                                                    item={props.item.accommodation as AccommodationCart}
                                                    bookingProcessState={props.item.accommodation.booking_process_state}
                                                    bookingStatus={null}
                                                    agencyNeedToBook={props.item.accommodation.agency_need_to_book}
                                                />
                                            </Box>
                                    }
                                </>
                            }
                        </TableCell>
                    </LightTooltip>
                    {
                        (
                            props.item.type === 'manual' ||
                            (props.item.accommodation.rooms?.length ?? 0) <= 1
                        ) &&
                        <TableCell sx={{ maxWidth: 'none !important', textAlign: 'center' }}>
                            {
                                !isProductPackaged({
                                    product: props.item.accommodation,
                                    stackInfos: trip?.stack_info ?? null,
                                    connected: true
                                }) &&
                                <>
                                    {
                                        props.item.type === 'normal' ?
                                            <CartConstructionProductsTableItemStatus
                                                type="accommodation"
                                                item={props.item.accommodation}
                                                bookingProcessState={props.item.accommodation.booking_process_state}
                                                bookingStatus={props.item.accommodation.booking_status}
                                                agencyNeedToBook={props.item.accommodation.agency_need_to_book}
                                            /> :
                                            <CartConstructionProductsTableItemStatus
                                                type="manual"
                                                item={props.item.accommodation}
                                                bookingProcessState={props.item.accommodation.booking_process_state}
                                                bookingStatus={props.item.accommodation.booking_status}
                                                agencyNeedToBook={props.item.accommodation.agency_need_to_book}
                                            />
                                    }
                                </>
                            }
                        </TableCell>
                    }
                    <TableCell>
                        {
                            !isProductPackaged({
                                product: props.item.accommodation,
                                stackInfos: trip?.stack_info ?? null
                            }) ?
                                <div className="ellipsis">
                                    {
                                        new Intl.NumberFormat(
                                            language,
                                            {
                                                style: 'currency',
                                                currency: totalCost.currency?.iso_code ?? 'EUR'
                                            }
                                        ).format(totalCost.cost)
                                    }
                                </div> :
                                <CartPackageItemChangeProviderQuotationStatusButtons
                                    item={{
                                        ...props.item.accommodation,
                                        stackNumber: props.item.accommodation.stack_number,
                                        providerId: props.item.accommodation.provider,
                                        type: props.item.type === 'manual' ?
                                            'manual' :
                                            'accommodations',
                                        isCustom: props.item.accommodation.is_custom
                                    }}
                                />

                        }
                    </TableCell>
                    <TableCell>
                        <IconButton
                            ref={menuButtonRef}
                            size="small"
                            onClick={(event) => {
                                event.stopPropagation();
                                setOpenMenu(true);
                            }}
                        >
                            <MoreVert />
                        </IconButton>
                        {
                            props.item.type === 'normal' &&
                            <CartConstructionProductsTableItemMenu
                                type="accommodation"
                                open={openMenu}
                                anchorEl={menuButtonRef.current}
                                rooms={props.item.accommodation.rooms?.map((room) => room.id) ?? []}
                                value={props.item.accommodation}
                                onClose={() => setOpenMenu(false)}
                            />
                        }
                        {
                            props.item.type === 'manual' &&
                            <CartConstructionProductsTableItemMenu
                                type={'manual'}
                                open={openMenu}
                                anchorEl={menuButtonRef.current}
                                value={props.item.accommodation}
                                onClose={() => setOpenMenu(false)}
                            />
                        }
                    </TableCell>
                </CartConstructionProductsTableItem>
                <TableRow className="cart-construction-table-item-margin-row">
                    <MarginWrapper item={props.item} />
                </TableRow>
            </>
        );
    }
);

type MarginWrapperProps = {
    item: ReturnType<typeof useCartProducts>['accommodations'][number],
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <TableCell
            sx={
                seeAllProductsMargins ?
                    undefined :
                    { paddingTop: 0, paddingBottom: 0 }
            }
            colSpan={20}
        >
            <Collapse
                in={
                    seeAllProductsMargins &&
                    !isProductPackaged({
                        product: props.item.accommodation,
                        stackInfos: trip?.stack_info ?? null
                    })
                }
                unmountOnExit
            >
                {
                    props.item.type === 'normal' &&
                    <CartConstructionProductsTableItemMargin
                        type="accommodation"
                        item={props.item.accommodation}
                    />
                }
                {
                    props.item.type === 'manual' &&
                    <CartConstructionProductsTableItemMargin
                        type="manual-accommodation"
                        item={props.item.accommodation}
                    />
                }
            </Collapse>
        </TableCell>
    );
}


const useStyles = makeStyles((theme) => ({
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    resortFees: {
        margin: '15px 0 0 15px'
    },
    image: {
        borderRadius: 16
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    remarkBooking: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    remarkBookingHide: {
        textDecoration: 'underline',
        marginBottom: 4,
        color: "gray",
        cursor: "not-allowed"
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMarginName: {
        marginBottom: 8
    },
    containerMargin: {
        marginBottom: 4
    },
    hotelName: {
        float: 'left',
        marginRight: 10,
        cursor: 'pointer',
        fontSize: 20,
        fontWeight: 'bold'
    },
    hotelInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    hotelInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    stars: {
        fontSize: 16,
        verticalAlign: 'middle',
        marginRight: 2
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    includedAmenities: {
        display: 'inline-block',
        marginRight: 4
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    catalogedPrice: {
        textAlign: 'right',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: 12
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    unavailable: {
        color: 'red'
    },
    priceDetail: {
        textAlign: 'right',
        textDecoration: 'underline'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '0px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0 0 16px',
        textTransform: 'uppercase'
    },
    bookingCollapsed: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '8px 0 0 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    additionalInformation: {
        backgroundColor: '#2196f3',
        color: 'white',
        margin: '16px 0 !important'
    },
    additionalInformationExpandIcon: {
        color: 'white'
    },
    additionalInformationInfoIcon: {
        marginRight: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    remarkExpand: {
        "&& .MuiAlert-action": {
            display: "block !important",
            width: '18%',
            [theme.breakpoints.down('md')]: {
                width: '21%'
            }
        }
    },
    pictureContainer: {
        overflow: "hidden",
        height: 200,
        //width: 300,
        position: "relative",
        cursor: "pointer"
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    overflowText: {
        lineHeight: '1.5em',
        width: '100%',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));
